import React from 'react';
import {
  Text,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Box,
} from '@chakra-ui/react';
import { iNotice } from '~/domain/interfaces/models';
import IconTeamUserImg from '~/presentation/base/icons/IconTeamUserImg';
import getDate from '~/utils/getDate';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  notice?: iNotice['records'][0];
}

const DetailsNotice = ({ isOpen, onClose, notice }: ownProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent p='6'>
        <ModalHeader p='0' mb='5'>
          <Flex
            flexDirection='row'
            justifyContent={'space-between'}
            alignItems='center'
          >
            <Text fontWeight='700' fontSize='18px' color={'#303950'}>
              Detalhes do Edital
            </Text>
            <ModalCloseButton
              size='md'
              position={'inherit'}
              color={'#60656D'}
            ></ModalCloseButton>
          </Flex>
        </ModalHeader>
        <ModalBody p='0'>
          <Flex direction='column' gap='5'>
            <Flex w='100%' fontSize='sm' gap='6'>
              <Box w='50%'>
                <Text fontWeight='700' color='#303950' mb='2'>
                  Identificação
                </Text>
                <Text color='#60656D'>{`${notice?.code} ${notice?.title}`}</Text>
              </Box>
              <Box w='50%'>
                <Text fontWeight='700' color='#303950' mb='2'>
                  Período de inscrições
                </Text>
                <Text color='#60656D'>
                  {`De ${getDate(notice?.activitiesStartAt)} até ${getDate(notice?.activitiesEndAt)}`}
                </Text>
              </Box>
            </Flex>
            <Flex w='100%' fontSize='sm' gap='6'>
              <Box w='50%'>
                <Text fontWeight='700' color='#303950' mb='2'>
                  Valor por projeto
                </Text>
                <Text color='#60656D'>{`R$ ${notice?.value}`}</Text>
              </Box>
              <Box w='50%'>
                <Text fontWeight='700' color='#303950' mb='2'>
                  Vagas
                </Text>
                <Text color='#60656D'>{notice?.vacancies}</Text>
              </Box>
            </Flex>
            <Box>
              <Text fontWeight='700' color='#303950' mb='2'>
                Objeto do edital
              </Text>
              <Text color='#60656D'>{notice?.description}</Text>
            </Box>
            {/* <Flex flexDirection={'column'} gap='15px'>
              <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='700'
                fontSize='sm'
                color='#303950'
              >
                Mentores associados
              </Text>
              <Flex flexDirection={'column'} gap='12px'>
                <Flex flexDirection={'row'} gap='15px'>
                  <IconTeamUserImg></IconTeamUserImg>
                  <Flex flexDirection='column'>
                    {usersMock.map((item, key) => (
                      <Box key='' color='#60656D'>
                        <Text>{item.userName}</Text>
                        <Text>{item.userEmail}</Text>
                      </Box>
                    ))}
                  </Flex>
                </Flex>
              </Flex>
            </Flex> */}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DetailsNotice;

const usersMock = [
  {
    userImg: {},
    userName: 'Murilo Miguel Henry',
    userEmail: 'loremipsum@email.com',
  },
];
