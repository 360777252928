/* eslint-disable no-console */
import {
  Flex,
  Button,
  HStack,
  Avatar,
  Stack,
  Text,
  PopoverTrigger,
  Popover,
  PopoverContent,
  Divider,
  useDisclosure,
  Img,
} from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { History } from '~/main/routes';
import ButtonRocketChat from '../ButtonRocketChat';
import { makeRemoteGetTokenRocketChatAuth } from '~/main/factories/usecases/auth/GetTokenRocketChatAuthFactory';
import EditMember from '../Modals/user/EditMember';
import { makeReduxLogoutAuth } from '~/main/factories/usecases/auth/LogoutAuthFactory';
import NotificationsPanel from '../Modals/NotificationsPanel';
import IconUser from '~/presentation/base/icons/iconUserAvatar.svg';
import { useLocation } from 'react-router';
import MenuMobile from '../Menu/MenuMobile';

const Header = (): JSX.Element => {
  const { user } = useSelector((store: iStore) => store.auth);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const renderPageName = (page?: string) => {
    switch (page) {
      case '/inicio':
        return 'Início';

      case '/startups':
        return 'Gerenciar startups';

      case '/usuarios':
        return 'Gerenciar usuários';

      case '/editais':
        return 'Editais de financiamento';

      case '/inscricoes':
        return 'Painel de inscrições';

      case '/documentos':
        return 'Documentos';

      case '/eventos':
        return 'Painel de eventos';

      case '/orcamento':
        return 'Painel de finanças';

      default:
        return '';
    }
  };

  return (
    <>
      <Flex
        px={{ base: '20px', md: '48px' }}
        py='4'
        mb={{ base: '5', md: '8' }}
        align='center'
        justify='space-between'
        bg='#FDFDFD'
        borderBottom='1px'
        borderColor='#CECECE'
        gap={'15px'}
        h='80px'
      >
        <MenuMobile />
        <Text
          fontWeight='700'
          fontSize='20px'
          display={{ base: 'none', breakpoint1024: 'block' }}
        >
          {renderPageName(useLocation().pathname)}
        </Text>
        <HStack spacing='4'>
          {/* <Button
          bg='#4B587A'
          _hover={{ background: '#788FC9' }}
          _active={{ background: '#4B587A' }}
          fontSize={12}
          color='white'
          leftIcon={<IconForum />}
        >
          Fórum
        </Button>
        <Button
          bg='#4B587A'
          _hover={{ background: '#788FC9' }}
          _active={{ background: '#4B587A' }}
          fontSize={12}
          color='white'
          leftIcon={<IconCapac />}
        >
          Capacitação
        </Button>
        <Button
          bg='#4B587A'
          _hover={{ background: '#788FC9' }}
          _active={{ background: '#4B587A' }}
          fontSize={12}
          color='white'
          leftIcon={<IconChat />}
        >
          Bate-papo
        </Button> */}
        </HStack>

        <Flex align='center' gap='12px' justify='center'>
          <NotificationsPanel />
          {user && (
            <ButtonRocketChat
              action={() => {
                window.open(
                  `${window.config.connection.mattermostUrl}`,
                  '_blank'
                );
              }}
            ></ButtonRocketChat>
          )}
          <Popover placement='bottom'>
            <PopoverTrigger>
              <HStack spacing='12px' p='4px' cursor='pointer'>
                <Img
                  src={
                    user?.avatar === null ||
                    user?.avatar ===
                      'https://api-homolog.horizontesdeinovacao.pb.gov.br/api/v1/public/'
                      ? IconUser
                      : user?.avatar
                  }
                  border='1px'
                  borderColor={user?.avatar ? '#9C9C9C' : '#CECECE'}
                  borderRadius='5px'
                  objectFit='scale-down'
                  bg='#EDEDED'
                  h='40px'
                  w='40px'
                />
                <Stack
                  spacing='3px'
                  color='#484848'
                  fontSize='14px'
                  display={{ base: 'none', md: 'block' }}
                >
                  <Text fontWeight='700'>{user?.fullname}</Text>
                  <Text>{user?.email}</Text>
                </Stack>
              </HStack>
            </PopoverTrigger>
            <PopoverContent w='200px' bg='white'>
              <Stack align='center' p='2' pt='4'>
                <Button
                  w='120px'
                  bg='#F9F8FA'
                  color='#60656D'
                  _hover={{ background: '#E9ECEE', color: '#303950' }}
                  _active={{ background: '#CECECE', color: '#303950' }}
                  fontSize={12}
                  onClick={onOpen}
                >
                  Editar
                </Button>
                <Divider />
                <Button
                  w='120px'
                  bg='#303950'
                  _hover={{ background: '#788FC9' }}
                  _active={{ background: '#303950' }}
                  fontSize={12}
                  color='white'
                  onClick={() => {
                    History.push('/');
                    makeReduxLogoutAuth().logout({});
                  }}
                >
                  Sair
                </Button>
              </Stack>
            </PopoverContent>
          </Popover>
        </Flex>
      </Flex>
      <EditMember
        isOpen={isOpen}
        onClose={onClose}
        isLoggedUser
        id={user?.id}
      />
    </>
  );
};

export default Header;
