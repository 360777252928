import React from 'react';
import { BrowserRouter, Route, Router, Switch } from 'react-router-dom';
import { History } from '.';

import { makeLoginFactory } from '~/main/factories/pages/login';
import Layout from '~/presentation/components/Layout';
import { makeAccountabilityFactory } from '../factories/pages/accountability';
import { makeAnnouncementFactory } from '../factories/pages/announcement';
import { makeRegistrationNoticeFactory } from '../factories/pages/announcement/RegistrationNoticeFactory';
import { makeConfirmUserFactory } from '../factories/pages/confirmUser';
import { makeDashboardFactory } from '../factories/pages/dashboard';
import { makeDocumentsFactory } from '../factories/pages/documents';
import { makeEventFactory } from '../factories/pages/event';
import { makeHomeFactory } from '../factories/pages/home';
import { makeRegistrationsFactory } from '../factories/pages/registrations';
import { makeEditStartupFactory } from '../factories/pages/startup/EditStartupFactory';
import { makeRegistrationStartupFactory } from '../factories/pages/startup/RegistrationStartupFactory';
import { makeStartupFactory } from '../factories/pages/startup/StartupFactory';
import { makeTeamFactory } from '../factories/pages/team';
import PrivateRoute from './PrivateRoute';

const Routes: React.FC = () => {
  const [state, setState] = React.useState({
    action: History.getHistory().action,
    location: History.getHistory().location,
  });

  return (
    <BrowserRouter>
      <Router history={History.getHistory()}>
        <Layout>
          <Switch>
            <Route exact path={'/'} component={makeLoginFactory} />
            <Route
              exact
              path={'/finalizar-cadastro'}
              component={makeConfirmUserFactory}
            />
            <PrivateRoute exact path={'/inicio'} component={makeHomeFactory} />
            <PrivateRoute
              exact
              path={'/editais'}
              component={makeAnnouncementFactory}
            />
            <PrivateRoute
              exact
              path={'/editais/cadastro'}
              component={makeRegistrationNoticeFactory}
            />
            <PrivateRoute
              exact
              path={'/inscricoes'}
              component={makeRegistrationsFactory}
              roles={['ADMIN', 'SUPERVISOR', 'LEADER']}
            />
            <PrivateRoute
              exact
              path={'/eventos'}
              component={makeEventFactory}
            />
            <PrivateRoute
              exact
              path={`/usuarios`}
              component={makeTeamFactory}
            />
            <PrivateRoute
              exact
              path={'/documentos'}
              component={makeDocumentsFactory}
            />
            <PrivateRoute
              exact
              path={'/startups'}
              component={makeStartupFactory}
              roles={['ADMIN', 'SUPERVISOR', 'MENTOR']}
            />
            <PrivateRoute
              exact
              path={'/startups/cadastro'}
              component={makeRegistrationStartupFactory}
            />
            <PrivateRoute
              exact
              path={'/startups/editar/:id'}
              component={makeEditStartupFactory}
            />
            <PrivateRoute
              exact
              path={'/orcamento'}
              component={makeAccountabilityFactory}
              roles={['ADMIN', 'SUPERVISOR', 'LEADER']}
            />
            <Route exact path={'/events'} component={makeEventFactory} />
            <Route exact path={'/dashboard'} component={makeDashboardFactory} />
          </Switch>
        </Layout>
      </Router>
    </BrowserRouter>
  );
};

export default Routes;
