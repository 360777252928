const IconLink: React.FC = () => {
  return (
    <svg
      width='12'
      height='13'
      viewBox='0 0 12 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.16934 7.50765L4.58053 7.91883L4.58055 7.91885C5.22799 8.56699 6.08973 8.92445 7.00704 8.92445C7.92435 8.92445 8.78613 8.56694 9.43358 7.91881L9.4336 7.91878L10.9457 6.40742L4.16934 7.50765ZM4.16934 7.50765L4.99166 6.68533L5.40281 7.09649C6.26143 7.95511 7.75261 7.95512 8.61124 7.09651C8.61125 7.09651 8.61125 7.0965 8.61125 7.0965L10.1235 5.58497L10.1236 5.58491C10.5482 5.15892 10.7868 4.58186 10.7868 3.98037C10.7868 3.37888 10.5483 2.80192 10.1237 2.37593L10.1235 2.3758C9.69737 1.95154 9.12029 1.71313 8.51893 1.71313C7.91759 1.71313 7.34073 1.95132 6.91456 2.37558L6.91448 2.37566L6.50319 2.78694L5.68088 1.96463L6.09193 1.55358L4.16934 7.50765ZM5.08428 10.6243L5.08436 10.6242L5.49552 10.213L6.31784 11.0353L5.90663 11.4465L5.9066 11.4466C5.58817 11.7655 5.20982 12.0184 4.79332 12.1906C4.37683 12.3628 3.93008 12.4509 3.47938 12.45H3.47919C3.02861 12.4508 2.58232 12.3626 2.16594 12.1904C1.74957 12.0182 1.37133 11.7654 1.05295 11.4466C0.410662 10.8022 0.05 9.92942 0.05 9.01959C0.05 8.10976 0.410739 7.23694 1.05303 6.59254L2.56516 5.08113L2.56518 5.08111C3.21263 4.43297 4.07436 4.07551 4.99168 4.07551C5.90899 4.07551 6.77075 4.433 7.4182 5.08114L7.41822 5.08115L7.82937 5.49231L7.00706 6.31463L6.5959 5.90347C5.73728 5.04485 4.24609 5.04486 3.38746 5.90346C3.38746 5.90346 3.38745 5.90347 3.38745 5.90347L1.87512 7.41509L1.87505 7.41515C1.45041 7.84114 1.21196 8.4181 1.21196 9.01959C1.21196 9.62109 1.45063 10.1983 1.87527 10.6242L1.87541 10.6244C2.30157 11.0486 2.87844 11.2868 3.47978 11.2868C4.08113 11.2868 4.65812 11.0485 5.08428 10.6243ZM11.9487 3.98037C11.9487 4.89021 11.588 5.76295 10.9457 6.40735L6.09197 1.55354C6.73618 0.910901 7.60898 0.55 8.51893 0.55C9.42883 0.55 10.3016 0.91086 10.9458 1.55343C11.5881 2.19783 11.9487 3.07055 11.9487 3.98037Z'
        fill='#0873F1'
        stroke='#0873F1'
        strokeWidth='0.1'
      />
    </svg>
  );
};

export default IconLink;
