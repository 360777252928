import { SetStartupAuth } from '~/domain/usecases/auth/redux/SetStartupAuth';
import {
  iActionSetStartup,
  AuthTypes,
} from '~/domain/interfaces/redux/auth/setStartup';

export const setStartupRequest = (
  payload: SetStartupAuth.Params
): iActionSetStartup => ({
  type: AuthTypes.SETSTARTUP,
  payload,
});
