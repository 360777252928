import * as yup from 'yup';

export const schemaLogin = {
  initial: {
    login: '',
    password: '',
  },
  validators: yup.object({
    login: yup
      .string()
      .required('Campo obrigatório')
      .min(3, 'Mínimo de 3 caracteres'),
    password: yup.string().required('Campo obrigatório'),
  }),
};
