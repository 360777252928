import * as yup from 'yup';

interface MainSchema {
  initial: {
    type: 'MEETING' | 'MENTORING' | 'DELIVERY';
    name: string;
    description?: string;
    link?: string;
    date: Date | null;
    startTime?: Date;
    endTime?: Date;
    time?: Date;
    responsable?: { value: number | undefined; label: string };
    notice?: { value: number | undefined; label: string };
    noticeStep?: { value: number | undefined; label: string };
    guests?: string[];
  };
  validators: yup.ObjectSchema<any>;
}

interface Variation {
  initial: {
    guests?: string[];
    responsable?: { value: number | undefined; label: string };
    notice?: { value: number | undefined; label: string };
    noticeStep?: { value: number | undefined; label: string };
    time?: Date;
    startTime?: Date;
    endTime?: Date;
  };
  validators: yup.ObjectSchema<any>;
}

const mainSchema: MainSchema = {
  initial: {
    type: 'MEETING',
    name: '',
    description: '',
    link: '',
    date: null,
    startTime: undefined,
    endTime: undefined,
    guests: [],
    responsable: { value: undefined, label: '' },
    notice: undefined,
    noticeStep: undefined,
  },
  validators: yup.object({
    type: yup.string().oneOf(['MEETING', 'MENTORING', 'DELIVERY']).required(),
    name: yup.string().required('Campo obrigatório'),
    description: yup.string(),
    link: yup.string().url('URL inválida'),
    date: yup.date().nullable().required('Campo obrigatório'),
  }),
};

const meetingVariation: Variation = {
  initial: {
    guests: [],
  },
  validators: yup.object({
    guests: yup.array().min(1, 'Campo obrigatório'),
    startTime: yup.date().nullable().required('Campo obrigatório'),
    endTime: yup.date().nullable().required('Campo obrigatório'),
  }),
};

const mentoringVariation: Variation = {
  initial: {
    responsable: { value: undefined, label: '' },
    notice: undefined,
  },
  validators: yup.object({
    responsable: yup
      .object()
      .test('is-valid-responsable', 'Campo obrigatório', (value) => {
        return value && typeof value.value === 'number';
      })
      .required('Campo obrigatório'),
    guests: yup.array().min(1, 'Campo obrigatório'),
    startTime: yup.date().nullable().required('Campo obrigatório'),
    endTime: yup.date().nullable().required('Campo obrigatório'),
  }),
};

const deliveryVariation: Variation = {
  initial: {
    time: undefined,
    notice: { value: undefined, label: '' },
    noticeStep: { value: undefined, label: '' },
  },
  validators: yup.object({
    time: yup.date().nullable().required('Campo obrigatório'),
    notice: yup
      .object()
      .test('is-valid-notice', 'Campo obrigatório', (value) => {
        return value && typeof value.value === 'number';
      })
      .required('Campo obrigatório'),
    noticeStep: yup
      .object()
      .test('is-valid-notice', 'Campo obrigatório', (value) => {
        return value && typeof value.value === 'number';
      })
      .required('Campo obrigatório'),
  }),
};

const schemaRegisterEvent: Record<number, yup.ObjectSchema<any>> = {
  0: yup.object().shape({
    ...mainSchema.validators.fields,
    ...meetingVariation.validators.fields,
  }),
  1: yup.object().shape({
    ...mainSchema.validators.fields,
    ...mentoringVariation.validators.fields,
  }),
  2: yup.object().shape({
    ...mainSchema.validators.fields,
    ...deliveryVariation.validators.fields,
  }),
};

export { mainSchema, schemaRegisterEvent };
