import React from 'react';
import { Box, Flex, Stack, VStack, useBreakpointValue } from '@chakra-ui/react';
import cepPromise from 'cep-promise';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import cidadesPB from '~/utils/cidadesPB';
import { Input } from '../../UI/NewInput';
import { Select } from '../../UI/NewSelect';
import FormItem from '../../UI/FormFormik/formItem';
import { Field } from 'formik';

export interface CepResponse {
  street: string;
  city: string;
  neighborhood: string;
  state: string;
}

const FormRegisterStartup = ({ formik }: any): JSX.Element => {
  const [cepResponse, setCepResponse] = React.useState<CepResponse | undefined>(
    undefined
  );
  const { records } = useSelector((store: iStore) => store.activity);

  const activityOptions = records.map((activity) => ({
    value: activity.id,
    label: activity.name,
  }));
  const genderOptions = [
    { value: 'MALE', label: 'Masculino' },
    { value: 'FEMALE', label: 'Feminino' },
    { value: 'OTHER', label: 'Outro' },
  ];
  const cityOptions = cidadesPB.map((city) => ({
    value: city,
    label: city,
  }));

  function isValidCEP(cepInput: string) {
    return /^[0-9]{5}-[0-9]{3}$/.test(cepInput);
  }

  const handleOnBlur = () => {
    async function fetchCEP() {
      try {
        const cepResponseData = await cepPromise(formik.values.cep);
        setCepResponse(cepResponseData);
        formik.setFieldValue(
          'address',
          `${cepResponseData.street}, ${cepResponseData.neighborhood}, ${cepResponseData.city}`
        );
      } catch {
        setCepResponse(undefined);
      }
    }

    if (isValidCEP(formik.values.cep)) {
      fetchCEP();
    } else {
      setCepResponse(undefined);
    }
  };

  const handleActivityChange = (val: { value: any; }) => {
    const selectedValue = val ? val.value : '';
    formik.setFieldValue('activity', Number(selectedValue));
  };

  return (
    <Flex
      direction={{ base: 'column', md: 'row' }} 
      justifyContent='space-between'
      width='100%'
      gap='30px'
      overflow={{ base: 'auto', md: 'initial' }}
      maxHeight={{ base: '500px', md: 'none' }} 
    >
      <VStack spacing='4' width={{ base: '100%', md: '70%' }}>
        <FormItem name='name'>
          <Field
            as={Input}
            id='name'
            name='name'
            label='Nome da Startup'
            isRequired
          />
        </FormItem>
        <Box minW={'100%'}>
          <FormItem name='activity'>
            <Field
              as={Select}
              id='activity'
              name='activity'
              label='Área de atuação'
              options={activityOptions}
              placeholder='Escolha uma opção'
              isRequired
              onChange={handleActivityChange}
            />
          </FormItem>
        </Box>
        <FormItem name='address'>
          <Field
            as={Input}
            id='address'
            name='address'
            label='Endereço'
            isOptional
            onBlurCapture={handleOnBlur}
          />
        </FormItem>
        <FormItem name='nameManager'>
          <Field
            as={Input}
            id='nameManager'
            name='nameManager'
            label='Nome do Gestor'
            isRequired
          />
        </FormItem>
        <FormItem name='emailManager'>
          <Field
            as={Input}
            id='emailManager'
            name='emailManager'
            label='E-mail'
            isRequired
          />
        </FormItem>
      </VStack>

      <VStack spacing='4' width={{ base: '100%', md: 'auto' }}>
        <FormItem name='cnpj'>
          <Field
            id='cnpj'
            name='cnpj'
            as={Input}
            label='CNPJ'
            mask={'cnpj'}
            placeholder='__.___.___/____-__'
            isOptional
          />
        </FormItem>
        <Box minW={'100%'}>
          <FormItem name='city'>
            <Field
              as={Select}
              id='city'
              name='city'
              label='Cidade'
              placeholder='Escolha uma opção'
              options={cityOptions}
              isRequired
            />
          </FormItem>
        </Box>
        <FormItem name='cep'>
          <Field
            as={Input}
            id='cep'
            name='cep'
            label='CEP'
            placeholder='_____-___'
            mask={'cep'}
            isOptional
          />
        </FormItem>
        <Box minW={'100%'}>
          <FormItem name='gender'>
            <Field
              as={Select}
              id='gender'
              name='gender'
              label='Gênero'
              options={genderOptions}
              placeholder='Escolha uma opção'
              isRequired
              value={formik?.values}
            />
          </FormItem>
        </Box>
        <FormItem name='phoneManager'>
          <Field
            as={Input}
            id='phoneManager'
            name='phoneManager'
            label='Telefone'
            mask={'phone'}
            placeholder='(__) _ ____-____'
            isOptional
          />
        </FormItem>
      </VStack>
    </Flex>
  );
};

export default FormRegisterStartup;
