import React from 'react';

const IconBullet = () => {
  return (
    <svg
      width='4'
      height='5'
      viewBox='0 0 4 5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='2' cy='2.5' r='2' fill='#484848' />
    </svg>
  );
};

export default IconBullet;
