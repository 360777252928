import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Flex, HStack, Stack, Text } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import mime from 'mime';
import styled from 'styled-components';
import {
  IconBlueTick,
  IconDelete,
  IconPDF,
  IconUploadDocs,
} from '~/presentation/base/icons';

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return 'rgba(91, 100, 107, 0.5);';
};

const showType = (type: string) => {
  switch (mime.getType(type)) {
    case 'application/pdf':
      return 'PDF';
    default:
      return 'Type not found';
  }
};

interface iDataFile {
  name: string;
  type: string;
  size: number;
}

interface ownProps {
  oldFiles?: iDataFile[];
  externalFiles?: File[];
  onChange: (files: File[]) => void;
}

const UploadDocs = ({
  oldFiles,
  onChange,
  externalFiles,
}: ownProps): JSX.Element => {
  const [files, setFiles] = useState<File[]>(externalFiles || []);
  const [data, setData] = useState<iDataFile[]>([]);

  useEffect(() => {
    onChange(files);
    const _data = files.map((file) => {
      return {
        name: file.name,
        type: file.type,
        size: file.size,
      };
    });
    if (oldFiles) setData([..._data, ...oldFiles]);
    else setData(_data);
  }, [files]);

  useEffect(() => {
    if (oldFiles) {
      setData(oldFiles);
    }
  }, [oldFiles]);

  const onDrop = useCallback((acceptedFiles: any) => {
    if (acceptedFiles.length) {
      setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    }
  }, []);

  function StyledDropzone(props: any) {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
      onDrop,
      noDrag: true,
      accept: { 'application/pdf': ['.pdf'] },
    });

    return (
      <Stack w='100%' h='400px'>
        <Flex
          align='center'
          justify='center'
          direction='column'
          gap='2'
          minH={'160px'}
          w='360px'
          bg='#EFEFEF'
          border={`1px dashed ${getColor(props)}`}
          _hover={{ bg: 'gray.200' }}
          cursor='pointer'
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <IconUploadDocs />
          <Text fontSize='sm' textAlign='center'>
            Clique aqui para fazer o<Text>upload de arquivos</Text>
          </Text>
        </Flex>
        <Flex
          direction='column'
          justify='flex-start'
          h='100%'
          marginTop={'0px'}
          overflowY='auto'
        >
          {data.map((file, idx) => {
            // eslint-disable-next-line no-console
            console.log('file', file);
            return (
              <Flex
                key={`${file.name}-${idx}`}
                justify='space-between'
                paddingLeft={'24px'}
                paddingRight={'18.5px'}
                border='1px solid #CECECE'
                alignItems={'center'}
                minH='60px'
                marginTop={'0px'}
              >
                <Flex
                  flexDir={'row'}
                  gap={'16px'}
                  justifyContent='flex-start'
                  alignItems={'center'}
                >
                  <IconPDF></IconPDF>
                  <Flex flexDir={'column'}>
                    <Stack color='#747C86'>
                      <Text fontSize='sm' fontWeight='semibold'>
                        {file.name}
                      </Text>
                      <Text fontSize='xs'>
                        {showType(file.name)} -{' '}
                        {new Intl.NumberFormat('en-IN', {
                          maximumFractionDigits: 8,
                        }).format(file.size / 1000000)}{' '}
                        MB
                      </Text>
                    </Stack>
                  </Flex>
                </Flex>
                <IconBlueTick></IconBlueTick>
                {/* <Button
                  ml={4}
                  width='40px'
                  bg='#F9F8FA'
                  color='#1C294B'
                  _hover={{ background: '#1C294B', color: 'white' }}
                  boxShadow='0px 2px 6px rgba(0, 0, 0, 0.1)'
                  padding='9px 9px 8px 9px'
                  onClick={() => {
                    const newFiles = files.filter((_, index) => index !== idx);
                    setFiles(newFiles);
                  }}
                >
                  <IconDelete />
                </Button> */}
              </Flex>
            );
          })}
        </Flex>
      </Stack>
    );
  }

  return (
    <div>
      <StyledDropzone />
    </div>
  );
};

export default UploadDocs;
