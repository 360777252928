import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ResendMailUserConfirmation as UsecaseResendMailUserConfirmation } from '~/domain/usecases/user/remote';
import { RemoteResendMailUserConfirmation } from '~/data/repository/user/RemoteResendMailUserConfirmation ';

/**
 * Send request via API with token as a query parameter.
 */
export const makeRemoteResendMailUserConfirmation =
  (): UsecaseResendMailUserConfirmation =>
    new RemoteResendMailUserConfirmation(
      makeApiUrl('/users/{user}/confirmation/resend'),
      makeHttpClient()
    );
