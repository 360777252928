import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  Avatar,
  Box,
  Flex,
  Grid,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { Fragment, useEffect, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { iStartup, iStore } from '~/domain/interfaces/models';
import { IconDelete, IconEdit, IconView } from '~/presentation/base/icons';
import IconEvaluate from '~/presentation/base/icons/iconEvaluate';
import IconMoreOptions from '~/presentation/base/icons/iconMoreOptions';
import ActionButton from '~/presentation/components/ListActions/ActionButton';
import ConflictError from '~/presentation/components/Modals/Confirmation/ConflictError';
import DeleteStartupSuccess from '~/presentation/components/Modals/Confirmation/DeleteStartupSuccess';
import EditStartupSucess from '~/presentation/components/Modals/Confirmation/EditStartupSucess';
import RegisterStartupSucess from '~/presentation/components/Modals/Confirmation/RegisterStartupSucess';
import SubmitionApplicationError from '~/presentation/components/Modals/Confirmation/SubmitionApplicationError';
import DeactivateStartup from '~/presentation/components/Modals/startup/DeactivateStartup';
import DeleteStartup from '~/presentation/components/Modals/startup/DeleteStartup';
import DetailsStartup from '~/presentation/components/Modals/startup/DetailsStartup';
import EditStartup from '~/presentation/components/Modals/startup/EditStartup';
import RegisterStartup from '~/presentation/components/Modals/startup/RegisterStartup';
import StageManager from '~/presentation/components/StageManager';
import Tab from '~/presentation/components/Tab';
import { AssociatedNotices } from './associatedNotices';
import { BadgeSituation } from './BadgeSituation';

const Startup: React.FC = (): JSX.Element => {
  const [open, setOpen] = React.useState('');
  const openRegisterStartup = () => {
    setOpen('REGISTER');
  };
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: true,
    lg: true,
    xl: false,
  });
  const isTablet = useBreakpointValue({
    base: false,
    md: true,
    lg: true,
    xl: false,
  });
  const isLargeScreen = useBreakpointValue({ base: false, xxl: true });
  const [selectedStartup, setSelectedStartup] = React.useState<
    iStartup['records'][0] | undefined
  >(undefined);
  const [notices, setNotices] = React.useState<string[]>([]);
  const [selectedNotice, setSelectedNotice] = React.useState<string>('All');
  const [selectedFilter, setSelectedFilter] = React.useState(['']);
  const [filterByNameValue, setFilterByNameValue] = React.useState<string>('');
  const { records } = useSelector((store: iStore) => store.startup);

  const hasSomeWithMoreThanOneApplication = useMemo(() => {
    const applications = records.filter((item) => {
      return item.applications.length > 1;
    });

    return applications.length > 1;
  }, [records]);

  useEffect(() => {
    const _notices: string[] = [];

    records.forEach((item: iStartup['records'][0]) => {
      const n = item.applications.find(
        (el) => el.status === 'APPROVED'
      )?.notice_;
      if (n && !_notices.includes(n.code)) {
        _notices.push(n.code);
      }
    });

    setNotices(_notices);
  }, [records]);

  return (
    <Box
      flex='1'
      my='6'
      mx={{ base: 'auto', md: '8' }}
      h='calc(100% - 136px)'
      display={'flex'}
      flexDir={'column'}
      position='relative'
    >
      <Tab
        setFilter={setSelectedFilter}
        filterByName={filterByNameValue}
        setFilterByName={setFilterByNameValue}
        setOpen={setOpen}
        buttonText={'Nova Startup'}
        resourcers={['ADMIN', 'SUPERVISOR']}
        actions={['ADMIN', 'SUPERVISOR']}
        action={() => setOpen('REGISTER')}
        searchPlaceholder='Pesquisar startups...'
      ></Tab>
      <Accordion
        allowToggle
        borderLeft='1px solid #cfcfcf'
        borderRight='1px solid #cfcfcf'
        height={'100%'}
        maxHeight={'100%'}
        overflowY='auto'
      >
        <TableContainer maxH="full" overflowY="auto">
          <Table variant='simple' colorScheme='white'>
            <Thead
              position='sticky'
              top={0}
              zIndex={5}
              height='50px'
            >
              <Tr bgColor='white.3'>
                {hasSomeWithMoreThanOneApplication && <Th></Th>}
                <Th fontSize='sm' textTransform={'capitalize'}>
                  Nome da Startup
                </Th>
                <Th fontSize='sm' textTransform={'capitalize'}>
                  Edital Associado
                </Th>
                <Th fontSize='sm' textTransform={'capitalize'}>
                  Segmento
                </Th>
                {!isMobile && (
                  <Th fontSize='sm' textTransform='capitalize'>
                    Situação
                  </Th>
                )}
                {isLargeScreen && (
                  <Th fontSize='sm' textTransform='capitalize'>
                    Criado em
                  </Th>
                )}
                <Th
                  fontSize='sm'
                  textTransform={'capitalize'}
                  w={{ lg: '0px', xl: '0px' }}
                >
                  Ações
                </Th>
              </Tr>
            </Thead>

            <Tbody overflowX='scroll' maxH={200}>
              {records
                .filter((item) => {
                  const n = item.applications.find(
                    (el) => el.status === 'APPROVED'
                  )?.notice_?.code;

                  if (
                    selectedNotice === 'All' &&
                    filterByNameValue.trim() === '' &&
                    selectedFilter.length === 1
                  ) {
                    return true;
                  } else if (filterByNameValue.trim() !== '') {
                    return (
                      item.name
                        .toLowerCase()
                        .search(filterByNameValue.toLowerCase()) !== -1
                    );
                  } else if (selectedFilter.length === 6) {
                    if (selectedFilter[1] === '') {
                      return (
                        item.applications.length > 0 &&
                        item.applications.find(
                          (el) => el.notice_.code === selectedFilter[0]
                        )
                      );
                    }
                    /**Todo Atualizar filtro  */
                    /* else {
                      return (
                        item.applications.length > 0 &&
                        item.applications.find(
                          (el) =>
                            el.notice_.code === selectedFilter[0] &&
                            el.steps?.[0]?.name === selectedFilter[1]
                        )
                      );
                    } */
                  } else {
                    return n === selectedNotice;
                  }
                })
                .map((item, index) => {
                  const hasMoreThanOneApplicationApproved =
                    item.incubations.length > 1;

                  return (
                    <AccordionItem as={Fragment} key={index}>
                      {({ isExpanded }) => (
                        <>
                          <Tr fontSize='sm' color='#747C86'>
                            {hasSomeWithMoreThanOneApplication && (
                              <Td maxW={4}>
                                {hasMoreThanOneApplicationApproved && (
                                  <AccordionButton
                                    alignItems={'center'}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    _hover={{ backgroundColor: 'transparent' }}
                                  >
                                    <AccordionIcon />
                                  </AccordionButton>
                                )}
                              </Td>
                            )}
                            <Td>
                              <HStack>
                                <Avatar
                                  name={item?.name}
                                  src={item?.logo}
                                  style={{ borderRadius: '5px' }}
                                />
                                <Box fontSize='sm'>
                                  <Text fontWeight='semibold'>
                                    {item?.name}
                                  </Text>
                                  <Text>
                                    {item?.address.city} -{' '}
                                    {item?.address.province}
                                  </Text>
                                </Box>
                              </HStack>
                            </Td>
                            <Td>
                              <Box>
                                <Text
                                  color={
                                    item.incubations.length > 0
                                      ? 'black.2'
                                      : 'inherit'
                                  }
                                >
                                  {item.incubations.length > 0
                                    ? `${item.incubations[0].notice_.code} - ${item.incubations[0].notice_.title}` 
                                    : 'Não está associado'
                                  }
                                </Text>
                                <AccordionButton
                                  as={Box}
                                  flexDir={'column'}
                                  alignItems={'start'}
                                  maxW={'fit-content'}
                                  cursor={'pointer'}
                                  p={0}
                                  fontSize={'sm'}
                                  _hover={{ backgroundColor: 'transparent' }}
                                >
                                  <Text
                                    color='black.4'
                                    userSelect={'none'}
                                    _hover={{ textDecoration: 'underline' }}
                                  >
                                    {hasMoreThanOneApplicationApproved &&
                                      `e mais ${
                                        item.incubations.slice(1).length
                                      }`}
                                  </Text>
                                </AccordionButton>
                              </Box>
                            </Td>
                            <Td>
                              <Text color="black.2">
                                {item.activity_?.name}
                              </Text>
                            </Td>
                            {!isMobile && (
                              <Td>
                                <Text color="black.2">
                                  <BadgeSituation situation={item.situation} />
                                </Text>
                              </Td>
                            )}
                            <Td>
                              {!isTablet && (
                                <HStack spacing='4'>
                                  <ActionButton
                                    tooltip='Ver detalhes'
                                    onClick={() => {
                                      setOpen('DETAILS'),
                                        setSelectedStartup(item);
                                    }}
                                  >
                                    <IconView />
                                  </ActionButton>
                                  <ActionButton
                                    tooltip='Avaliar desempenho'
                                    disabled
                                  >
                                    <IconEvaluate />
                                  </ActionButton>
                                  <ActionButton
                                    tooltip='Editar'
                                    actions={['ADMIN', 'SUPERVISOR']}
                                    resourcers={['ADMIN', 'SUPERVISOR']}
                                    onClick={() => {
                                      setOpen('EDIT'), setSelectedStartup(item);
                                    }}
                                  >
                                    <IconEdit />
                                  </ActionButton>
                                  <ActionButton
                                    tooltip='Remover'
                                    actions={['ADMIN', 'SUPERVISOR']}
                                    resourcers={['ADMIN', 'SUPERVISOR']}
                                    onClick={() => {
                                      setOpen('DELETE'),
                                        setSelectedStartup(item);
                                    }}
                                  >
                                    <IconDelete />
                                  </ActionButton>
                                </HStack>
                              )}
                              {isTablet && (
                                <Popover placement='bottom-end'>
                                  <PopoverTrigger>
                                    <Flex
                                      h='100%'
                                      px='4'
                                      py='3'
                                      gap='4'
                                      alignItems='center'
                                    >
                                      <Box
                                        w='calc(100% - 40px)'
                                        color='#60656D'
                                        fontSize='md'
                                      >
                                        <ActionButton tooltip='Mais Opções'>
                                          <IconMoreOptions />
                                        </ActionButton>
                                      </Box>
                                    </Flex>
                                  </PopoverTrigger>
                                  <PopoverContent
                                    bg='white'
                                    w='160px'
                                    fontSize='sm'
                                    lineHeight='140%'
                                  >
                                    <PopoverBody
                                      p='0'
                                      border='1px solid #CECECE'
                                      borderRadius='5px'
                                    >
                                      <Grid>
                                        <ActionButton
                                          w={'160px'}
                                          borderRadius={'0px'}
                                          borderTopRadius={'5px'}
                                          p={'15px'}
                                          justifyContent='flex-start'
                                          onClick={() => {
                                            setOpen('DETAILS'),
                                              setSelectedStartup(item);
                                          }}
                                        >
                                          <IconView />{' '}
                                          <Text
                                            ml='10px'
                                            color='#222222'
                                            fontWeight={'500'}
                                          >
                                            Ver detalhes
                                          </Text>
                                        </ActionButton>
                                        <ActionButton
                                          w={'160px'}
                                          borderRadius={'0px'}
                                          justifyContent='flex-start'
                                          p={'15px'}
                                          disabled
                                        >
                                          <IconEvaluate />{' '}
                                          <Text
                                            ml='10px'
                                            color='#222222'
                                            fontWeight={'500'}
                                          >
                                            Avaliar
                                          </Text>
                                        </ActionButton>
                                        <ActionButton
                                          w={'160px'}
                                          borderRadius={'0px'}
                                          justifyContent='flex-start'
                                          p={'15px'}
                                          actions={['ADMIN', 'SUPERVISOR']}
                                          resourcers={['ADMIN', 'SUPERVISOR']}
                                          onClick={() => {
                                            setOpen('EDIT'),
                                              setSelectedStartup(item);
                                          }}
                                        >
                                          <IconEdit />{' '}
                                          <Text
                                            ml='10px'
                                            color='#222222'
                                            fontWeight={'500'}
                                          >
                                            Editar
                                          </Text>
                                        </ActionButton>
                                        <ActionButton
                                          w={'160px'}
                                          borderRadius={'0px'}
                                          borderBottomRadius={'5px'}
                                          justifyContent='flex-start'
                                          p={'15px'}
                                          actions={['ADMIN', 'SUPERVISOR']}
                                          resourcers={['ADMIN', 'SUPERVISOR']}
                                          onClick={() => {
                                            setOpen('DELETE'),
                                              setSelectedStartup(item);
                                          }}
                                        >
                                          <IconDelete />{' '}
                                          <Text
                                            ml='10px'
                                            color='#C23422'
                                            fontWeight={'500'}
                                          >
                                            Excluir
                                          </Text>
                                        </ActionButton>
                                      </Grid>
                                    </PopoverBody>
                                  </PopoverContent>
                                </Popover>
                              )}
                            </Td>
                          </Tr>
                          {isExpanded && (
                            <Tr>
                              <Td />
                              <Td colSpan={5}>
                                <AssociatedNotices startupId={item.id} />
                              </Td>
                            </Tr>
                          )}
                        </>
                      )}
                    </AccordionItem>
                  );
                })}
            </Tbody>
          </Table>
        </TableContainer>
      </Accordion>

      <RegisterStartup
        isOpen={open === 'REGISTER'}
        onClose={() => setOpen('')}
      />
      {/* <StageManager
        isOpen={open === 'STAGE'}
        onClose={() => setOpen('')}
        startup={selectedStartup}
      ></StageManager> */}

      <DeleteStartup
        isOpen={open === 'DELETE'}
        onClose={() => setOpen('')}
        startup={selectedStartup}
        id={selectedStartup?.id}
      ></DeleteStartup>

      <DeactivateStartup
        isOpen={open === 'DEACTIVATE'}
        onClose={() => setOpen('')}
      ></DeactivateStartup>

      <EditStartup
        isOpen={open === 'EDIT'}
        onClose={() => setOpen('')}
        startup={selectedStartup}
      ></EditStartup>

      <DetailsStartup
        isOpen={open === 'DETAILS'}
        onClose={() => setOpen('')}
        startup={selectedStartup}
      ></DetailsStartup>

      <RegisterStartupSucess onOpenRegister={openRegisterStartup} />
      <EditStartupSucess></EditStartupSucess>
      <SubmitionApplicationError></SubmitionApplicationError>
      <DeleteStartupSuccess startup={selectedStartup}></DeleteStartupSuccess>
      <ConflictError customText='Já existe uma startup associada a este e-mail'></ConflictError>
      {/* <RegisterNoticeSucess />  Usando para teste de editais e outros.*/}
    </Box>
  );
};

export default Startup;
