import { Avatar, Flex, Box, Divider } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { iStartup } from '~/domain/interfaces/models';
import GenericModalLayout from '~/presentation/components/Modals/GenericModalLayout';
import StringMask from '~/utils/getStringMask';
import { Label } from '~/presentation/components/UI/Label';
import { useHistory } from 'react-router-dom';
import { Link } from '~/presentation/components/UI/Link';
import { Typography } from '~/presentation/components/UI/Typography';
import { TagStatus } from '~/presentation/components/UI/TagStatus';
import { getRoleByName } from '~/utils/getRoleByName';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  startup?: iStartup['records'][0];
}

const DetailsStartup = ({ isOpen, onClose, startup }: ownProps) => {
  //eslint-disable-next-line no-console
  console.log('genero', startup?.members[0].user_.gender);

  const history = useHistory();

  const handleLinkClick = (e: { preventDefault: () => void }) => {
    e.preventDefault(); // Prevent the default link behavior

    history.push('/documentos', {
      path: [
        { name: 'STARTUPS', id: 0 },
        { name: startup?.name, id: startup?.folder },
      ],
    });
  };

  const getMainLeader = useCallback(() => {
    if (startup?.members) {
      for (const member of startup.members) {
        /**Todo mudar para 'LEADER' quando léo corrigir*/
        if (member.role === 'LEADER' && member.major) {
          return member;
        }
      }
    }
    return undefined;
  }, [startup]);

  const userGender: { [key: string]: string } = {
    MALE: 'Masculino',
    FEMALE: 'Feminino',
    OTHER: 'Outro',
  };

  return (
    <GenericModalLayout
      isOpen={isOpen}
      onClose={() => onClose()}
      headerTitle='Detalhes da Startup'
      modalSize='large'
      isPartitioned={true}
      centerContent={
        <Flex flexDirection={'row'} w={'100%'} justifyContent={'space-between'}>
          <Flex w={'100%'}>
            <Divider
              orientation='vertical'
              borderColor='#CECECE'
              borderWidth='1px'
              height='100%'
              mr='32px'
            />
            <Flex flexDirection={'column'} gap='20px' flex='2'>
              <Flex key='' flexDirection={'row'} gap='60px'>
                <Flex flexDirection={'column'} gap='8px' flex='1' w={'290px'}>
                  <Label>Nome da Startup</Label>
                  <Typography
                    variant='b1_Inter_Regular_14px'
                    whiteSpace='normal'
                  >
                    {startup?.name}
                  </Typography>
                  <Label>Área de atuação</Label>
                  <Typography
                    variant='b1_Inter_Regular_14px'
                    whiteSpace='normal'
                  >
                    {startup?.activity_.name}
                  </Typography>
                  <Label>Endereço</Label>
                  <Typography
                    variant='b1_Inter_Regular_14px'
                    color={startup?.address.street ? '#303950' : '#9C9C9C'}
                    whiteSpace='normal'
                  >
                    {startup?.address.street
                      ? startup?.address.street
                      : 'Não informado'}
                  </Typography>
                  <Label>Responsável</Label>
                  <Typography
                    variant='b1_Inter_Regular_14px'
                    whiteSpace='normal'
                  >
                    {getMainLeader()?.user_.fullname}
                  </Typography>
                  <Label>E-mail</Label>
                  <Typography
                    variant='b1_Inter_Regular_14px'
                    whiteSpace='normal'
                  >
                    {getMainLeader()?.user_.email}
                  </Typography>
                  <Label>Edital Associado</Label>
                  <Typography
                    variant='b1_Inter_Regular_14px'
                    color={
                      startup?.applications.find(
                        (el) => el.status === 'APPROVED'
                      )?.notice_?.code
                        ? '#303950'
                        : '#9C9C9C'
                    }
                    whiteSpace='normal'
                  >
                    {startup?.applications.find(
                      (el) => el.status === 'APPROVED'
                    )?.notice_?.code || 'Não associado'}
                  </Typography>
                  <Label>Documentos</Label>
                  <Typography
                    variant='b1_Inter_Regular_14px'
                    whiteSpace='normal'
                  >
                    <Link
                      label='Ver documentos da startup'
                      to='/documentos'
                      onClick={handleLinkClick}
                    />
                  </Typography>
                </Flex>
                <Flex
                  flexDirection={'column'}
                  gap='8px'
                  flex='1'
                  minW={'max-content'}
                >
                  <Label>CNPJ</Label>
                  <Typography
                    variant='b1_Inter_Regular_14px'
                    color={startup?.cnpj ? '#303950' : '#9C9C9C'}
                  >
                    {startup?.cnpj
                      ? new StringMask('00.000.000/0000-00').apply(
                          startup?.cnpj
                        )
                      : 'Não informado'}
                  </Typography>
                  <Label>Cidade</Label>
                  <Typography variant='b1_Inter_Regular_14px'>
                    {startup?.address.city
                      ? startup?.address.city
                      : 'Não informado'}
                  </Typography>
                  <Label>CEP</Label>
                  <Typography
                    variant='b1_Inter_Regular_14px'
                    color={startup?.address.zipcode ? '#303950' : '#9C9C9C'}
                  >
                    {startup?.address.zipcode
                      ? startup?.address.zipcode
                      : 'Não informado'}
                  </Typography>
                  <Label>Gênero</Label>
                  <Typography
                    variant='b1_Inter_Regular_14px'
                    color={
                      getMainLeader()?.user_.gender ? '#303950' : '#9C9C9C'
                    }
                  >
                    {
                      userGender[
                        getMainLeader()?.user_.gender ?? 'Não informado'
                      ]
                    }
                  </Typography>
                  <Label>Telefone</Label>
                  <Typography
                    variant='b1_Inter_Regular_14px'
                    color={getMainLeader()?.user_.phone ? '#303950' : '#9C9C9C'}
                  >
                    {getMainLeader()?.user_.phone
                      ? new StringMask('(00) 00000-0000').apply(
                          getMainLeader()?.user_.phone
                        )
                      : 'Não informado'}
                  </Typography>
                  <Label>Etapa Atual</Label>
                  {/**Todo Solicitar para adequar com o novo back*/
                  /* <Typography
                    variant='b1_Inter_Regular_14px'
                    color={
                      startup?.applications.find(
                        (el) => el.status === 'APPROVED'
                      )?.steps[0]?.name
                        ? '#303950'
                        : '#9C9C9C'
                    }
                  >
                    {startup?.applications.find(
                      (el) => el.status === 'APPROVED'
                    )?.steps[0]?.name || 'Não foi incubado'}
                  </Typography> */}
                  <Label>Situação</Label>
                  <Typography variant='b1_Inter_Regular_14px' color='#9C9C9C'>
                    {'Não informado'}
                  </Typography>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            flexDirection={'column'}
            gap='20px'
            flex='1'
            maxW={'fit-content'}
            minW={'max-content'}
            maxH={'377px'}
            overflow={'auto'}
          >
            <Label>Equipe</Label>
            {startup?.members
              .slice()
              .sort((a, b) => {
                if (getRoleByName(a.role) === 'Gestor') return -1;
                if (getRoleByName(b.role) === 'Gestor') return 1;
                return 0;
              })
              .map((member) => (
                <Flex
                  key={member.user_.id}
                  flexDirection={'row'}
                  gap='60px'
                  maxWidth={'min-content'}
                  minW={'100%'}
                  justifyContent={'space-between'}
                >
                  <Flex gap='15px'>
                    <Avatar
                      borderRadius={'0px'}
                      size='md'
                      name={member.user_.fullname}
                      src={member.user_.avatar}
                    />
                    <Box maxW={'174px'}>
                      <Typography variant='b1_Inter_Regular_14px'>
                        {member.user_.fullname} ({getRoleByName(member.role)})
                      </Typography>
                      <Typography variant='b1_Inter_Regular_14px'>
                        {member.user_.email}
                      </Typography>
                    </Box>
                  </Flex>
                  <TagStatus
                    status={member.user_.active ? 'success' : 'default'}
                  >
                    {member.user_.active ? 'Regular' : 'Pendente'}
                  </TagStatus>
                </Flex>
              ))}
          </Flex>
        </Flex>
      }
      startupImg={<Avatar size='lg' name={startup?.name} src={startup?.logo} />}
    ></GenericModalLayout>
  );
};

export default DetailsStartup;
