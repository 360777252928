import { Button, Tooltip } from '@chakra-ui/react';
import IconRocketChat from '~/presentation/base/icons/iconRocketChat';
import { withAccess } from '~/presentation/hooks/acess';

interface ownProps {
  action: () => void;
}

const ButtonRocketChat = ({ action }: ownProps): JSX.Element => {
  return (
    <Tooltip
      hasArrow
      label='Chat'
      bg='#222222'
      p='8.5px 12px'
      borderRadius='5px'
    >
      <Button
        onClick={action}
        bg='#FDFDFD'
        borderWidth='1px'
        borderColor='#CECECE'
        p='0px'
        w='fit-content'
        aria-label='Chat'
        _hover={{ bg: '#EDEDED', borderColor: '#777777' }}
        css={{
          ':active, :focus': {
            boxShadow: '0px 0px 15px 0px #1997F380',
            borderWidth: '2px',
            borderColor: '#1997F3',
          },
        }}
      >
        <IconRocketChat />
      </Button>
    </Tooltip>
  );
};

export default withAccess(ButtonRocketChat);
