import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetUserConfirmation as UsecaseGetUserConfirmation } from '~/domain/usecases/user/remote';
import { RemoteGetUserConfirmation } from '~/data/repository/user';

/**
 * Send request via API with token as a query parameter.
 */
export const makeRemoteGetUserConfirmation = (): UsecaseGetUserConfirmation =>
  new RemoteGetUserConfirmation(
    makeApiUrl(`/users/confirmation`),
    makeHttpClient()
  );
