import { Avatar, Box, Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { Typography } from '~/presentation/components/UI/Typography';

type StartupsInvitedProps = {
  eventId: number;
};

export const ResponsibleStartups: React.FC<StartupsInvitedProps> = ({
  eventId,
}: StartupsInvitedProps) => {
  const { records: events } = useSelector((store: iStore) => store.event);
  const event = events.find((event) => event.id === eventId);

  return (
    <Stack spacing={3}>
      <Typography variant='h7_Inter_Medium_14px'>Encarregados</Typography>
      <Box display={'flex'} flexWrap={'wrap'} gap={2}>
        {event?.startups
          .map((startup) => (
            <Box
              key={startup.id}
              display={'flex'}
              gap={2.5}
              alignItems={'center'}
              justifyContent={'space-between'}
              p={1}
              pr={3}
              bgColor={'white.1'}
              borderWidth={1}
              borderColor='black.5'
              borderRadius="full"
              w='fit-content'
            >
              <Avatar 
                src={startup.logo} 
                size="xs" 
                rounded="full" 
                border="black.5"
              />
              <Typography
                variant='b1_Inter_Regular_14px'
              >
                {startup.fullname}
              </Typography>
            </Box>
          ))}
      </Box>
    </Stack>
  );
};
