/* eslint-disable no-console */
import {
  Avatar,
  Box,
  HStack,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { iUser } from '~/domain/interfaces/models/User';
import {
  IconDelete,
  IconDisconnect,
  IconEdit,
  IconLinkUser,
  IconView,
} from '~/presentation/base/icons';
import ActionButton from '~/presentation/components/ListActions/ActionButton';
import ConflictError from '~/presentation/components/Modals/Confirmation/ConflictError';
import DeleteUserSuccess from '~/presentation/components/Modals/Confirmation/DeleteUserSuccess';
import EditUserSuccess from '~/presentation/components/Modals/Confirmation/EditUserSuccess';
import RegisterUserSuccess from '~/presentation/components/Modals/Confirmation/RegisterUserSuccess ';
import SubmitionApplicationError from '~/presentation/components/Modals/Confirmation/SubmitionApplicationError';
import DeactivateUser from '~/presentation/components/Modals/user/DeactivateUser';
import DeleteUser from '~/presentation/components/Modals/user/DeleteUser';
import DetailsUser from '~/presentation/components/Modals/user/DetailsUser';
import EditMember from '~/presentation/components/Modals/user/EditMember';
import LinkUser from '~/presentation/components/Modals/user/LinkUser';
import RegisterUser from '~/presentation/components/Modals/user/RegisterUser';
import Tab from '~/presentation/components/Tab';
import { Select } from '~/presentation/components/UI/NewSelect';
import { Form, Formik } from 'formik';
import { getRoleByName } from '~/utils/getRoleByName';
import axios from 'axios';

const Team = (): JSX.Element => {
  const [active, setActive] = React.useState('STARTUPS');
  const [open, setOpen] = React.useState('');
  const openRegisterUser = () => {
    setOpen('REGISTER');
  };
  const [selectedUser, setSelectedUser] = React.useState<
    iUser['records'][0] | undefined
  >(undefined);
  const { records } = useSelector((store: iStore) => store.user);
  const userLogged = useSelector((store: iStore) => store.auth);
  const [filterByNameValue, setFilterByNameValue] = React.useState<string>('');
  const [selectedRole, setSelectedRole] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const {
    startups: workspaceStartups,
    selectedStartup: workspaceSelectedStartup,
  } = useSelector((store: iStore) => store.auth);

  const isMajor: boolean = useMemo(() => {
    if (userLogged.user?.role_.name === 'ADMIN') return false;
    else if (
      userLogged.user?.role_.name === 'LEADER' &&
      records
        .find((user) => user?.id === userLogged.user?.id)
        ?.members.find((member) => member.startup === workspaceSelectedStartup)
        ?.major === true
    ) {
      return false;
    } else {
      return true;
    }
  }, [
    userLogged.user?.role_.name,
    userLogged.user?.id,
    workspaceSelectedStartup,
  ]);

  const renderTag = (role: string) => {
    const roleData = () => {
      switch (role) {
        case 'ADMIN':
          return { role: 'Admin', bg: '#FFEFEA', color: '#C23422' };
        case 'SUPERVISOR':
          return { role: 'Supervisor', bg: '#F3F9E7', color: '#5B7A17' };
        case 'MENTOR':
          return { role: 'Mentor', bg: '#F6E1FA', color: '#AC38A8' };
        case 'LEADER':
          return { role: 'Gestor', bg: '#E4F0F6', color: '#1863A8' };
        case 'MEMBER':
          return { role: 'Membro', bg: '#FFF5E0', color: '#BD5114' };
      }
    };

    return (
      <Tag p='2' color={roleData()?.color} bg={roleData()?.bg}>
        {roleData()?.role}
      </Tag>
    );
  };

  const roleOptionsByUserRole = React.useMemo(() => {
    switch (userLogged.user?.role_.name) {
      case 'ADMIN':
        return [
          { value: 'SUPERVISOR', label: 'Supervisor' },
          { value: 'LEADER', label: 'Gestor' },
          { value: 'MEMBER', label: 'Membro' },
        ];
      case 'SUPERVISOR':
        return [
          { value: 'LEADER', label: 'Gestor' },
          { value: 'MEMBER', label: 'Membro' },
        ];
      case 'LEADER':
        return [
          { value: 'LEADER', label: 'Gestor' },
          { value: 'MEMBER', label: 'Membro' },
        ];
      default:
        return [{ value: '', label: '' }];
    }
  }, [userLogged.user?.role_.name]);

  useEffect(() => {
    console.log('userLogged Data', userLogged);
  }, []);

  const handleOnChageRole = React.useCallback(
    (callback: (name: string, value: string) => void, value: string) => {
      callback('role', value);
    },
    []
  );
  const handleRoleChange = (
    selectedOption: { value: string; label: string },
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    setFieldValue('role', selectedOption.value);
    console.log('selectedOption', selectedOption);
  };

  return (
    <Box flex='1' my='6' mx='8' h='calc(100% - 136px)' position='relative'>
      <Tab
        active={active}
        setActive={setActive}
        tabs={[
          { title: 'INCUBADORA', resources: ['ADMIN', 'SUPERVISOR'] },
          { title: 'MENTORES', resources: ['ADMIN', 'SUPERVISOR'] },
          { title: 'STARTUPS' },
        ]}
        filterByName={filterByNameValue}
        setFilterByName={setFilterByNameValue}
        buttonText='Novo Integrante'
        searchPlaceholder='Pesquisar usuários...'
        actions={['ADMIN', 'SUPERVISOR', 'LEADER']}
        resourcers={['ADMIN', 'SUPERVISOR', 'LEADER']}
        action={() => setOpen('REGISTER')}
      />
      <TableContainer
        borderLeft='1px solid #cfcfcf'
        borderRight='1px solid #cfcfcf'
        maxHeight='calc(100% - 190px)'
        overflowY='auto'
      >
        <Table variant='simple' colorScheme='white'>
          <Thead
            position='sticky'
            top={0}
            zIndex={5}
            backgroundColor='white-3'
            height='50px'
          >
            <Tr>
              <Th fontSize='sm'>ID</Th>
              <Th fontSize='sm'>Nome do Usuário</Th>
              {active === 'STARTUPS' && (
                <Th fontSize='sm'>Startup Associada</Th>
              )}
              {active !== 'STARTUPS' && (
                <Th fontSize='sm'>
                  {active === 'MENTOR' ? 'Especialidade' : 'Cargo'}
                </Th>
              )}
              <Th fontSize='sm'>Permissão</Th>
              <Th fontSize='sm'>Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {records
              .filter((item) => {
                if (item.id === userLogged.user?.id) return false;

                switch (active) {
                  case 'STARTUPS':
                    if (
                      userLogged.startups &&
                      (userLogged.user?.role_.name === 'LEADER' ||
                        userLogged.user?.role_.name === 'MEMBER')
                    ) {
                      return (
                        (item.role_.name === 'LEADER' ||
                          item.role_.name === 'MEMBER') &&
                        item.situation === 'ACTIVE' /* &&
                        item.startup?.id === userLogged.startups[0].id */
                      );
                    } else
                      return (
                        (item.role_.name === 'LEADER' ||
                          item.role_.name === 'MEMBER') &&
                        item.situation === 'ACTIVE'
                      );
                  // TODO: Verificar se a condição acima está correta

                  case 'MENTORES':
                    return (
                      item.role_.name === 'MENTOR' &&
                      item.situation === 'ACTIVE'
                    );

                  case 'INCUBADORA':
                    return (
                      (item.role_.name === 'SUPERVISOR' ||
                        item.role_.name === 'ADMIN') &&
                      item.situation === 'ACTIVE'
                    );
                }
              })
              .filter((startup) => {
                if (
                  userLogged?.user?.role_?.name === 'MEMBER' ||
                  userLogged?.user?.role_?.name === 'LEADER'
                ) {
                  return startup.startup?.id === workspaceSelectedStartup;
                } else {
                  return true;
                }
              })
              .filter((item) => {
                if (filterByNameValue.trim() === '') {
                  return true;
                } else if (filterByNameValue.trim() !== '') {
                  return (
                    item.fullname
                      .toLowerCase()
                      .search(filterByNameValue.toLowerCase()) !== -1
                  );
                }
              })
              .map((item, index) => (
                <Tr fontSize={14} fontWeight={400} color='#747C86' key={index}>
                  <Td>{item.id}</Td>
                  <Td>
                    <HStack spacing='4'>
                      <Avatar name={item.fullname} src={item?.avatar} />
                      <Box fontSize='sm'>
                        <Text fontWeight='semibold'>{item.fullname}</Text>
                        <Text fontSize='xs'>{item.email}</Text>
                      </Box>
                    </HStack>
                  </Td>
                  {active === 'STARTUPS' && (
                    <Td>
                      <HStack spacing='4'>
                        <Avatar name={item.startup?.logo} />
                        <Box fontSize='sm'>
                          <Text fontWeight='semibold'>
                            {item.startup?.name}
                          </Text>
                          {/* Atualizar formato de endereço */}
                          {/* <Text fontSize='xs'>{'João Pessoa - PB'}</Text> */}
                        </Box>
                      </HStack>
                    </Td>
                  )}
                  {active !== 'STARTUPS' && <Td>{'Waiting update'}</Td>}
                  <Td>
                    <Formik
                      initialValues={{
                        role: {
                          value: item.role_.name,
                          label: getRoleByName(item.role_.name),
                        },
                      }}
                      onSubmit={async (values) => {
                        await axios.patch(
                          `/api/startups/${workspaceSelectedStartup}/${item.id}`,
                          {
                            role: values.role.value,
                          }
                        );
                        console.log('handleSubmit', values);
                      }}
                    >
                      {({ values, setFieldValue, handleSubmit }) => {
                        const handleRoleChange = (
                          selectedOption: any,
                          actionMeta: any
                        ) => {
                          setFieldValue('role', selectedOption.value);

                          handleSubmit();
                        };

                        return (
                          <Form name='role'>
                            <Select
                              name='role'
                              value={values.role}
                              options={roleOptionsByUserRole}
                              onChange={(newValue: any, actionMeta: any) =>
                                handleRoleChange(newValue, 'select-option')
                              }
                              isDisabled={true} //isMajor
                            />
                          </Form>
                        );
                      }}
                    </Formik>
                  </Td>
                  <Td>
                    <HStack spacing='4'>
                      <ActionButton
                        onClick={() => {
                          setOpen('DETAILS'), setSelectedUser(item);
                        }}
                      >
                        <IconView />
                      </ActionButton>
                      {active === 'MENTORES' && (
                        <ActionButton
                          onClick={() => {
                            setOpen('LINK'), setSelectedUser(item);
                          }}
                        >
                          <IconLinkUser />
                        </ActionButton>
                      )}
                      <ActionButton
                        actions={[
                          'ADMIN',
                          'SUPERVISOR',
                          ...[item.role_?.name === 'MEMBER' ? 'LEADER' : ''],
                        ]}
                        resourcers={[
                          'ADMIN',
                          'SUPERVISOR',
                          ...[item.role_?.name === 'MEMBER' ? 'LEADER' : ''],
                        ]}
                        onClick={() => {
                          setOpen('EDIT'), setSelectedUser(item);
                        }}
                      >
                        <IconEdit />
                      </ActionButton>
                      <ActionButton
                        actions={['ADMIN', 'SUPERVISOR']}
                        resourcers={['ADMIN', 'SUPERVISOR']}
                        onClick={() => setOpen('DEACTIVATE')}
                        disabled
                      >
                        <IconDisconnect />
                      </ActionButton>
                      <ActionButton
                        actions={['ADMIN']}
                        resourcers={[
                          'ADMIN',
                          ...[item.role_?.name === 'MEMBER' ? 'LEADER' : ''],
                        ]}
                        onClick={() => {
                          setOpen('DELETE'), setSelectedUser(item);
                        }}
                      >
                        <IconDelete />
                      </ActionButton>
                    </HStack>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>

      <RegisterUser isOpen={open === 'REGISTER'} onClose={() => setOpen('')} />

      <EditMember
        id={selectedUser?.id}
        isOpen={open === 'EDIT'}
        onClose={() => {
          setSelectedUser(undefined);
          setOpen('');
        }}
      />

      <DetailsUser
        isOpen={open === 'DETAILS'}
        onClose={() => setOpen('')}
        user={selectedUser}
      ></DetailsUser>

      <LinkUser
        isOpen={open === 'LINK'}
        onClose={() => setOpen('')}
        user={selectedUser}
      ></LinkUser>

      <DeactivateUser
        isOpen={open === 'DEACTIVATE'}
        onClose={() => setOpen('')}
      />

      <DeleteUser
        isOpen={open === 'DELETE'}
        onClose={() => setOpen('')}
        user={selectedUser}
        id={selectedUser?.id}
      />
      <RegisterUserSuccess onOpenRegister={openRegisterUser} />
      <EditUserSuccess />
      <SubmitionApplicationError></SubmitionApplicationError>
      <DeleteUserSuccess user={selectedUser}></DeleteUserSuccess>
      <ConflictError customText='Já existe uma conta associada a este e-mail'></ConflictError>
    </Box>
  );
};

export default Team;
