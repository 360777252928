import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
  Button,
} from '@chakra-ui/react';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import * as datefns from 'date-fns';
import { iNotice, iStep, iStore } from '~/domain/interfaces/models';
import { makeApiUrl } from '~/main/factories/http';
import { makeReduxListNotice } from '~/main/factories/usecases/notice/ListNoticeFactory';
import { makeRemoteUpdateNotice } from '~/main/factories/usecases/notice/UpdateNoticeFactory';
import FormRegisterNotice from '~/presentation/components/Form/RegisterNotice/FormRegisterNotice';
import Stage from '~/presentation/components/Stage';
import { schemaRegisterNotice } from '~/validators/RegisterNotice/RegisterNoticeValidator';
import { useSelector } from 'react-redux';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import { Conflict } from '~/domain/errors/Conflict';

export interface iOldFile {
  id: number;
  name: string;
  type: string;
  size: number;
}

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  notice?: iNotice['records'][0];
}

const EditNotice = ({ isOpen, onClose, notice }: ownProps) => {
  const [step, setStep] = useState(1);
  const [files, setFiles] = useState<File[]>([]);
  const [oldFiles, setOldFiles] = useState<iOldFile[]>([]);
  const [stages, setStages] = useState<iStep[]>([]);
  const { accessToken } = useSelector((store: iStore) => store.auth);
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const addStage = (stage: iStep) => {
    setStages((prevState) => [...prevState, stage]);
  };

  const updateStage = (index: number, stage: iStep) => {
    setStages((prevState) =>
      prevState.map((el, i) => (i === index ? stage : el))
    );
  };

  const removeStage = (index: number) => {
    setStages((prevState) => prevState.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (notice) {
      setStages(notice.steps);
      formik.setValues({
        code: notice.code,
        title: notice.title,
        description: notice.description,
        vacancies: notice.vacancies.toString(),
        value: notice.value.toString(),
        start: new Date(notice.activitiesStartAt),
        end: new Date(notice.activitiesEndAt),
      });
      const _oldFiles = notice.subscriptionFolder.files?.map((el) => ({
        id: el.id,
        name: el.filename,
        type: el.mimetype,
        size: el.size,
      }));
      setOldFiles(_oldFiles);
    }
  }, [notice, isOpen]);

  const onCloseModal = () => {
    onClose();
    setStep(1);
    setStages([]);
    setFiles([]);
    formik.resetForm();
  };

  const { initial, validators } = schemaRegisterNotice;

  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,
    isInitialValid: false,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);

      const formData = new FormData();
      files.forEach((file) => {
        formData.append('files', file);
      });

      let _files = oldFiles?.map((el) => el.id);

      try {
        if (files.length > 0) {
          const response = await axios.post(
            makeApiUrl('/files/upload'),
            formData,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          _files = [
            ...oldFiles.map((el) => el.id),
            ...response.data.map((el: any) => el.id),
          ];
        }
      } catch (error) {
        showConfirmModal('APPLICATION_ERROR');
        onCloseModal();
        setIsLoading(false);
      }

      notice &&
        makeRemoteUpdateNotice()
          .update({
            id: notice?.id,
            body: {
              ...values,
              vacancies: Number(values.vacancies),
              value: Number(values.value),
              applicationStart: values.start.toISOString(),
              applicationEnd: values.end.toISOString(),
              start: datefns.format(new Date(values.start), 'yyyy-MM-dd'),
              end: datefns.format(new Date(values.end), 'yyyy-MM-dd'),
              status: notice.status,
              steps: stages,
              files: _files,
            },
          })
          .then(() => {
            makeReduxListNotice().list({});
            showConfirmModal('EDIT_NOTICE');
          })
          .catch((error) => {
            if (error instanceof Conflict) {
              showConfirmModal('CONFLICT_ERROR');
            } else {
              showConfirmModal('APPLICATION_ERROR');
            }
          })
          .finally(() => {
            onCloseModal();
            setIsLoading(false);
          });
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onCloseModal();
      }}
      size='4xl'
      isCentered
    >
      <ModalOverlay />
      <ModalContent bg='#F9F8FA'>
        <ModalHeader>Editar Edital</ModalHeader>
        <ModalCloseButton />
        <ModalBody minH='416px'>
          {step === 1 && (
            <FormRegisterNotice
              formik={formik}
              setFiles={setFiles}
              oldFiles={oldFiles}
              externalFiles={files}
            />
          )}
          {step === 2 && (
            <Stage
              stages={stages}
              addStage={addStage}
              removeStage={removeStage}
              updateStage={updateStage}
              isEditable
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Flex justify='space-between' w='100%'>
            <Button
              fontWeight='medium'
              variant='outline'
              color='#303950'
              borderColor='#303950'
              onClick={() => {
                step === 1
                  ? (onClose(), setFiles([]), formik.resetForm())
                  : setStep(step - 1);
              }}
            >
              {step === 1 ? 'Cancelar' : 'Voltar'}
            </Button>
            {
              <Button
                id='submit'
                bg='#303950'
                _hover={{ background: '#788FC9' }}
                _active={{ background: '#303950' }}
                color='white'
                isLoading={isLoading}
                disabled={!(formik.isValid && formik.dirty) || isLoading}
                loadingText='Concluindo cadastro'
                fontWeight='500'
                fontSize='14px'
                onClick={() =>
                  step === 1 ? setStep(step + 1) : formik.handleSubmit()
                }
              >
                {step === 1 ? 'Avançar' : 'Concluir Edição'}
              </Button>
            }
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditNotice;
