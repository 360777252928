import { Box, Stack } from '@chakra-ui/react';
import { useContext } from 'react';
import { makeRemoteResendMailUserConfirmation } from '~/main/factories/usecases/user/ResendMailUserConfirmationFactory ';
import { IconErrorExclamation } from '~/presentation/base/icons';
import { Typography } from '~/presentation/components/UI/Typography';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import Confirmation from '..';

interface ownProps {
  userId: number;
  token: string;
}

const CompleteUserInfoLinkError = ({ userId, token }: ownProps) => {
  const { active, showConfirmModal } = useContext(ConfirmModalContext);

  return (
    <Confirmation
      isOpen={active === 'COMPLETE_USER_INFO_LINK_ERROR'}
      showTopButton={true}
      onClose={() => {
        // eslint-disable-next-line no-console
        console.log('close link error');
      }}
      icon={<IconErrorExclamation />}
      title='Link de confirmação inválido!'
      body={
        <Box>
          <Stack spacing='5'>
            <Typography textAlign={'center'} variant='b1_Inter_Regular_14px'>
              O link que você tentou abrir já foi usado, expirou ou está
              incorreto. Clique nos botões abaixo para solicitar um novo ou
              voltar à tela inicial.
            </Typography>
          </Stack>
        </Box>
      }
      onOpenRegister={async () => {
        try {
          await makeRemoteResendMailUserConfirmation().resend({
            id: userId,
            token: token,
          });
          showConfirmModal('RESEND_LINK_MAIL');
        } catch (error) {
          showConfirmModal('APPLICATION_ERROR');
        }
      }}
      topButtonText='Reenviar e-mail'
      buttonText='Voltar ao início'
      returnPage='/'
    />
  );
};

export default CompleteUserInfoLinkError;
