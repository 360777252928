import React from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputProps,
  Input as ChakraInput,
  InputGroup,
  InputRightElement,
  InputLeftElement,
} from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';

interface ownProps extends InputProps {
  id: string;
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  value?: string;
  label?: string;
  required?: boolean;
  optional?: boolean;
  mask?: (string | RegExp)[];
  guide?: boolean;
  rightIcon?: React.ReactNode;
  leftIcon?: React.ReactNode;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik?: any;
}

const Input = ({
  id,
  name,
  onChange,
  onBlur,
  value,
  label,
  required,
  optional,
  mask,
  guide = true,
  formik,
  rightIcon,
  leftIcon,
  disabled = false,
  ...rest
}: ownProps): JSX.Element => {
  return (
    <FormControl
      isRequired={required}
      isInvalid={formik?.errors[name] && formik?.touched[name]}
      style={{ marginLeft: '0px' }}
    >
      {label && (
        <FormLabel fontWeight='bold' fontSize='sm' mb='2' color={'#222222'}>
          {label}
          {optional && <span style={{ marginLeft: '4px', fontWeight: 'normal' }}>(opcional)</span>}
        </FormLabel>
      )}
      <InputGroup>
        {leftIcon && <InputLeftElement pointerEvents="none">{leftIcon}</InputLeftElement>} {/* Render left icon */}
        <ChakraInput
          id={id}
          px='2.5'
          fontSize='sm'
          name={name}
          onChange={formik ? formik?.handleChange : onChange}
          onBlur={formik ? formik?.handleBlur : onBlur}
          value={formik ? formik?.values[name] : value}
          as={mask ? MaskedInput : undefined}
          bg='#F9F8FA'
          disabled={disabled}
          {...(mask ? { /* placeholderChar: '_',  */ mask, guide } : {})}
          {...rest}
        />
        {rightIcon && <InputRightElement>{rightIcon}</InputRightElement>}
      </InputGroup>

      <FormErrorMessage>{formik?.errors[name]}</FormErrorMessage>
    </FormControl>
  );
};

export default Input;
