import React from 'react';

const IconProfile = () => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.13953 7.86047C8.13953 3.5175 11.657 0 16 0C20.343 0 23.8605 3.5175 23.8605 7.86047C23.8605 12.2034 20.343 15.7209 16 15.7209C11.657 15.7209 8.13953 12.2034 8.13953 7.86047ZM17.7421 3.65466C17.1898 3.42589 16.5978 3.30814 16 3.30814C14.7926 3.30814 13.6347 3.78776 12.781 4.64148C11.9273 5.49521 11.4477 6.65311 11.4477 7.86047C11.4477 9.06782 11.9273 10.2257 12.781 11.0794C13.6347 11.9332 14.7926 12.4128 16 12.4128C16.5978 12.4128 17.1898 12.295 17.7421 12.0663C18.2944 11.8375 18.7963 11.5022 19.219 11.0794C19.6417 10.6567 19.977 10.1549 20.2058 9.60256C20.4346 9.05025 20.5523 8.45828 20.5523 7.86047C20.5523 7.26264 20.4346 6.67068 20.2058 6.11837C19.977 5.56605 19.6417 5.06421 19.219 4.64148C18.7963 4.21876 18.2944 3.88344 17.7421 3.65466Z'
        fill='#9C9C9C'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 26.1744C0 24.9237 0.62431 23.8075 1.68977 22.8242C2.75713 21.8391 4.24853 21.0062 5.92682 20.3339C9.28383 18.9891 13.3183 18.314 16 18.314C18.6817 18.314 22.7162 18.9891 26.0732 20.3339C27.7515 21.0062 29.2429 21.8391 30.3102 22.8242C31.3757 23.8075 32 24.9237 32 26.1744V32H0V26.1744ZM3.30814 28.6919H28.6919V26.1744C28.6919 25.9053 28.5335 25.6503 28.3344 25.4298C28.1272 25.2004 27.8342 24.9629 27.4781 24.7252C26.7643 24.2487 25.7593 23.7465 24.5732 23.289C22.2007 22.374 19.0632 21.6221 16 21.6221C12.9368 21.6221 9.79932 22.374 7.42683 23.289C6.24065 23.7465 5.23569 24.2487 4.52188 24.7252C4.16579 24.9629 3.87281 25.2004 3.6656 25.4298C3.46647 25.6503 3.30814 25.9053 3.30814 26.1744V28.6919Z'
        fill='#9C9C9C'
      />
    </svg>
  );
};

export default IconProfile;
