import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { CompleteUserInfo as UsecaseRemoteCompleteUserInfo } from '~/domain/usecases/user/remote';
// import { ConfirmUser as UsecaseReduxConfirmUser } from '~/domain/usecases/user/redux';

import { RemoteCompleteUserInfo } from '~/data/repository/user';
// import { ReduxConfirmUser } from '~/data/store/reducer/user/usecases';

/**
 * send request via API.
 */
export const makeRemoteCompleteUserInfo = (
  user: number
): UsecaseRemoteCompleteUserInfo =>
  new RemoteCompleteUserInfo(
    makeApiUrl(`/users/${user}/complete`),
    makeHttpClient()
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxConfirmUser =
  (): UsecaseReduxConfirmUser =>
    new ReduxConfirmUser(); */
