import { theme as baseTheme, extendTheme } from '@chakra-ui/react';
import { CheckboxStyle } from '../../components/UI';

export const theme = extendTheme(
  {
    breakpoints: {
      breakpoint360: '360px',
      breakpoint768: '768px',
      breakpoint1024: '1024px',
      breakpoint1366: '1366px',
      breakpoint1440: '1440px',
      breakpoint1920: '1920px',
    },
    fonts: {
      heading: 'Inter',
      body: 'Inter',
    },
    components: {
      Checkbox: CheckboxStyle,
    },
    shadows: {
      focus: '0 0 15px 0 rgba(25, 151, 243, 0.5)',
    },
    colors: {
      black: {
        1: '#222222',
        2: '#484848',
        3: '#5C5C5C',
        4: '#777777',
        5: '#9C9C9C',
        6: '#E2E2E2',
      },
      white: {
        1: '#FDFDFD',
        2: '#EDEDED',
        3: '#EBEBEB',
        4: '#CECECE',
        5: '#E9ECEE',
        6: '#EFEFEF',
        7: '#F8F8F8',
        8: '#F6F6F6',
      },
      blue: {
        1: '#1997F3',
        2: '#0873F1',
        3: '#6BC9F7',
        4: '#0152B0',
        5: '#E4F6FF',
        6: '#D2EEFB',
      },
      red: {
        1: '#DE4377',
        2: '#FB6FA1',
        3: '#C23422',
        4: '#9D2A1C',
        5: '#D53520',
        6: '#FF4C34',
        7: '#F9E0D9',
        8: '#FFEFEA',
        9: '#FFF2EE',
      },
      purple: {
        1: '#7D2B7B',
        2: '#AC38A8',
        3: '#E981D8',
        4: '#ECCAF2',
        5: '#EAC6F1',
      },
      yellow: {
        1: '#BD5114',
        2: '#DE6D04',
        3: '#FCBD5F',
        4: '#F49404',
        5: '#FBEDD1',
        6: '#FFF5E0',
      },
      green: {
        1: '#5B7A17',
        2: '#8BC314',
        3: '#DCEBBE',
        4: '#EBF3D9',
        5: '#F3F9E7',
      },
    },
  },
  baseTheme
);
