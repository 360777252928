import React from 'react';
import { Typography } from '../Typography';
import { LabelProps } from './types';

export const Label = React.forwardRef<HTMLLabelElement, LabelProps>(
  ({ children, ...props }, ref) => {
    return (
      <label ref={ref} {...props}>
        <Typography variant='h6_Inter_SemiBold_14px'>{children}</Typography>
      </label>
    );
  }
);
Label.displayName = 'Label';
