import React, { useEffect, useState, useCallback } from 'react';
import {
  Stack,
  ListItem,
  ListIcon,
  List,
  HStack,
} from '@chakra-ui/react';
import { IconTicked, IconBullet } from '~/presentation/base/icons';
import { PasswordStrengthStatus } from '../SetPassword';
import { Typography } from '~/presentation/components/UI/Typography';

interface OwnProps {
  password: string;
  setPasswordStatus: (status: PasswordStrengthStatus) => void;
  confirmPassword: string;
  setConfirmPasswordStatus: (status: PasswordStrengthStatus) => void;
}

export interface PasswordRule {
  rule: string;
  isValid: boolean;
}

const passwordRules = [
  { rule: 'Pelo menos uma letra maiúscula (A-Z).', pattern: /[A-Z]/ },
  { rule: 'Pelo menos uma letra minúscula (a-z).', pattern: /[a-z]/ },
  { rule: 'Pelo menos um número (0-9).', pattern: /[0-9]/ },
  { rule: 'Pelo menos um símbolo especial (#$%&).', pattern: /[#$%&]/ },
  { rule: 'Pelo menos 8 caracteres.', pattern: /.{8,}/ },
];

const PasswordStrength = ({
  password,
  setPasswordStatus,
  confirmPassword,
  setConfirmPasswordStatus,
}: OwnProps): JSX.Element => {
  const [rules, setRules] = useState<PasswordRule[]>(
    passwordRules.map((config) => ({
      rule: config.rule,
      isValid: false,
    }))
  );

  const checkPasswordRules = (pwd: string) => {
    return passwordRules.map(({ rule, pattern }) => ({
      rule,
      isValid: pattern.test(pwd),
    }));
  };

  const updateRulesAndStatus = useCallback(
    (pwd: string, setStatus: (status: PasswordStrengthStatus) => void) => {
      const newRules = checkPasswordRules(pwd);
      const validCount = newRules.filter((rule) => rule.isValid).length;

      setStatus(
        validCount >= 4
          ? 'strong'
          : validCount === 3
          ? 'medium'
          : validCount === 2
          ? 'weak'
          : validCount === 1
          ? 'veryWeak'
          : 'default'
      );

      return newRules;
    },
    []
  );

  useEffect(() => {
    setRules(updateRulesAndStatus(password, setPasswordStatus));
    updateRulesAndStatus(confirmPassword, setConfirmPasswordStatus);
  }, [password, confirmPassword, updateRulesAndStatus]);

  return (
    <Stack style={{ marginTop: '0px' }}>
      <Typography variant='h6_Inter_SemiBold_14px'>
        Lembre-se, a senha deve conter:
      </Typography>
      <List spacing='2px'>
        {rules.map((rule, index) => (
          <ListItem key={index}>
            <HStack>
              <ListIcon as={rule.isValid ? IconTicked : IconBullet} />
              {rule.isValid ? (
                <Typography color='#5B7A17' variant='h7_Inter_Medium_14px'>
                  {rule.rule}
                </Typography>
              ) : (
                <Typography color='#484848' variant='h7_Inter_Medium_14px'>
                  {rule.rule}
                </Typography>
              )}
            </HStack>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};

export default PasswordStrength;
