import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ManageMentorNotice as UsecaseRemoteManageMentorNotice } from '~/domain/usecases/user/remote';
// import { CreateUser as UsecaseReduxCreateUser } from '~/domain/usecases/user/redux';

import { RemoteManageMentorNotice } from '~/data/repository/user';
// import { ReduxCreateUser } from '~/data/store/reducer/user/usecases';

/**
 * send request via API.
 */
export const makeRemoteManageMentorNotice = (): UsecaseRemoteManageMentorNotice =>
  new RemoteManageMentorNotice(makeApiUrl('/users/{user}/mentor/notices'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxCreateUser =
  (): UsecaseReduxCreateUser =>
    new ReduxCreateUser(); */
