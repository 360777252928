import React from 'react';

type iActive =
  | 'CONFLICT_ERROR'
  | 'DELETE_EVENT'
  | 'DELETE_STARTUP'
  | 'DELETE_NOTICE'
  | 'DELETE_USER'
  | 'SUBMMIT_STARTUP'
  | 'SUBMMIT_NOTICE'
  | 'SUBMMIT_USER'
  | 'SUBMMIT_APPLICATION'
  | 'SUBMMIT_EVENT'
  | 'SUBMMIT_MEETING_EVENT'
  | 'SUBMMIT_MENTORING_EVENT'
  | 'SUBMMIT_DELIVERY_EVENT'
  | 'SUBMMIT_INFORM'
  | 'APPLICATION_APPROVED'
  | 'APPLICATION_REJECTED'
  | 'APPLICATION_ERROR'
  | 'INFORM_APPROVED'
  | 'INFORM_REJECTED'
  | 'EDIT_STARTUP'
  | 'EDIT_USER'
  | 'EDIT_NOTICE'
  | 'EDIT_EVENT'
  | 'EDIT_INFORM'
  | 'FORGOT_PASSWORD'
  | 'SENDED_EMAIL'
  | 'SIGN_UP_USER_CONFIRMATION'
  | 'COMPLETE_USER_INFO_LINK_ERROR'
  | 'RESEND_LINK_MAIL'
  | '';

interface ConfirmModalContextType {
  active: iActive;
  showConfirmModal: (type: iActive, data?: any) => void;
  buttonText?: string;
  closeModal: () => void;
  data: any;
  updateData: (newData: any) => void;
}

export const ConfirmModalContext = React.createContext<ConfirmModalContextType>(
  {
    active: '',
  } as ConfirmModalContextType
);

interface ownProps {
  children: React.ReactNode;
}

export const ConfirmModalProvider = ({ children }: ownProps) => {
  const [active, setActive] = React.useState<iActive>('');
  const [data, setData] = React.useState<any>({});
  const [buttonText, setButtonText] = React.useState<string>('Entendi');

  const showConfirmModal = (type: iActive, data?: any) => {
    // eslint-disable-next-line no-console
    console.log('...showConfirmModal', type);
    setActive(type);

    if (data) {
      setData(data);
    }
  };

  const closeModal = () => {
    setActive('');
  };

  const updateData = (newData: any) => {
    setData((prevData: any) => ({ ...prevData, ...newData }));
  };

  return (
    <ConfirmModalContext.Provider
      value={{
        active,
        showConfirmModal,
        buttonText,
        closeModal,
        data,
        updateData,
      }}
    >
      {children}
    </ConfirmModalContext.Provider>
  );
};
