import React from 'react';

const IconCamera = () => {
  return (
    <svg
      width='18'
      height='16'
      viewBox='0 0 18 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.77778 16C1.28889 16 0.870518 15.8261 0.522667 15.4782C0.174815 15.1304 0.000592593 14.7117 0 14.2222V3.55556C0 3.06667 0.174222 2.6483 0.522667 2.30044C0.871111 1.95259 1.28948 1.77837 1.77778 1.77778H4.57778L6.22222 0H11.5556L13.2 1.77778H16C16.4889 1.77778 16.9076 1.952 17.256 2.30044C17.6044 2.64889 17.7784 3.06726 17.7778 3.55556V14.2222C17.7778 14.7111 17.6039 15.1298 17.256 15.4782C16.9081 15.8267 16.4895 16.0006 16 16H1.77778ZM1.77778 14.2222H16V3.55556H12.4L10.7778 1.77778H7L5.37778 3.55556H1.77778V14.2222Z'
        fill='#222222'
      />
      <path
        d='M8.88867 12.7998C9.99978 12.7998 10.9444 12.4111 11.7224 11.6336C12.5005 10.8561 12.8893 9.91151 12.8887 8.7998C12.8887 7.68869 12.4999 6.7444 11.7224 5.96692C10.945 5.18943 10.0004 4.8004 8.88867 4.7998C7.77756 4.7998 6.83326 5.18884 6.05578 5.96692C5.2783 6.74499 4.88926 7.68929 4.88867 8.7998C4.88867 9.91092 5.27771 10.8555 6.05578 11.6336C6.83386 12.4117 7.77815 12.8004 8.88867 12.7998ZM8.88867 11.022C8.26645 11.022 7.74052 10.8072 7.31089 10.3776C6.88126 9.94795 6.66645 9.42203 6.66645 8.7998C6.66645 8.17758 6.88126 7.65166 7.31089 7.22203C7.74052 6.7924 8.26645 6.57758 8.88867 6.57758C9.51089 6.57758 10.0368 6.7924 10.4664 7.22203C10.8961 7.65166 11.1109 8.17758 11.1109 8.7998C11.1109 9.42203 10.8961 9.94795 10.4664 10.3776C10.0368 10.8072 9.51089 11.022 8.88867 11.022Z'
        fill='#222222'
      />
    </svg>
  );
};

export default IconCamera;
