import {
  Box,
  Button,
  Checkbox,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import ButtonFilter from '~/presentation/components/ButtonFilter';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';

interface ButtonFilterProps {
  showDownloadReportButton?: boolean;
  setFilter?: (value: string[]) => void;
}

const FilterStartups = ({
  showDownloadReportButton,
  setFilter,
}: ButtonFilterProps): JSX.Element => {
  const [firstDropdownValue, setFirstDropdownValue] = React.useState('');
  const [secondDropdownValue, setSecondDropdownValue] = React.useState('');
  const [checkedItems, setCheckedItems] = React.useState([
    false,
    false,
    false,
    false,
  ]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;

  const { records } = useSelector((store: iStore) => store.notice);

  return (
    <>
      <ButtonFilter
        action={onOpen}
        showDownloadReportButton={showDownloadReportButton}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          maxW={{ base: '100%', breakpoint1024: '408px' }}
          minH={{ base: '574px', breakpoint1024: '522px' }}
          margin={{ base: '0px', breakpoint1024: 'auto' }}
          borderRadius={{
            base: '10px 10px 0px 0px',
            breakpoint1024: '10px 10px 10px 10px',
          }}
          containerProps={{ flexDirection: 'column', justifyContent: 'end' }}
        >
          <ModalHeader fontFamily='Inter' fontSize='18px' fontWeight='700'>
            Filtrar consulta
          </ModalHeader>
          <ModalCloseButton
            css={{
              ':active, :focus': {
                boxShadow: '0px 0px 15px 0px #1997F380',
                borderWidth: '2px',
                borderColor: '#1997F3',
              },
            }}
          />
          <ModalBody fontFamily='Inter' fontWeight='600' fontSize='14px'>
            <Text mb='11px'>Edital associado</Text>

            <Select
              placeholder='Todos'
              fontSize='14px'
              value={firstDropdownValue}
              onChange={(e) => setFirstDropdownValue(e.target.value)}
              css={{
                ':active, :focus': {
                  boxShadow: '0px 0px 15px 0px #1997F380',
                  borderWidth: '2px',
                  borderColor: '#1997F3',
                },
              }}
            >
              {records.map((item, index) => {
                return (
                  <option
                    value={item.code}
                    key={index}
                    style={{
                      color: '#222222',
                    }}
                  >
                    {item.code}
                  </option>
                );
              })}
            </Select>

            <Text mt='20px' mb='11px'>
              Etapa atual
            </Text>

            <Select
              placeholder='Todas'
              fontSize='14px'
              value={secondDropdownValue}
              onChange={(e) => setSecondDropdownValue(e.target.value)}
              css={{
                ':active, :focus': {
                  boxShadow: '0px 0px 15px 0px #1997F380',
                  borderWidth: '2px',
                  borderColor: '#1997F3',
                },
              }}
            >
              {firstDropdownValue != '' &&
                records
                  .filter((item) => {
                    return item.code === firstDropdownValue;
                  })[0]
                  .steps.map((item, index) => {
                    return (
                      <option
                        value={item.name}
                        key={index}
                        style={{
                          color: '#222222',
                        }}
                      >
                        {item.name}
                      </option>
                    );
                  })}
            </Select>

            <Text mt='20px' mb='12px'>
              Situação
            </Text>

            <Box fontWeight='400' color='#484848'>
              <Checkbox
                isChecked={allChecked}
                isIndeterminate={isIndeterminate}
                onChange={(e) =>
                  setCheckedItems([
                    e.target.checked,
                    e.target.checked,
                    e.target.checked,
                    e.target.checked,
                  ])
                }
              >
                Todas
              </Checkbox>
              <Stack pl='24px' mt={1} spacing={1}>
                <Checkbox
                  isChecked={checkedItems[0]}
                  onChange={(e) =>
                    setCheckedItems([
                      e.target.checked,
                      checkedItems[1],
                      checkedItems[2],
                      checkedItems[3],
                    ])
                  }
                >
                  Pendente
                </Checkbox>
                <Checkbox
                  isChecked={checkedItems[1]}
                  onChange={(e) =>
                    setCheckedItems([
                      checkedItems[0],
                      e.target.checked,
                      checkedItems[2],
                      checkedItems[3],
                    ])
                  }
                >
                  Regular
                </Checkbox>
                <Checkbox
                  isChecked={checkedItems[2]}
                  onChange={(e) =>
                    setCheckedItems([
                      checkedItems[0],
                      checkedItems[1],
                      e.target.checked,
                      checkedItems[3],
                    ])
                  }
                >
                  Irregular
                </Checkbox>
                <Checkbox
                  isChecked={checkedItems[3]}
                  onChange={(e) =>
                    setCheckedItems([
                      checkedItems[0],
                      checkedItems[1],
                      checkedItems[2],
                      e.target.checked,
                    ])
                  }
                >
                  Suspensa
                </Checkbox>
              </Stack>
            </Box>
          </ModalBody>
          <Divider borderColor='#CECECE' />
          <ModalFooter
            gap='12px'
            justifyContent='space-between'
            flexDirection={{ base: 'column-reverse', breakpoint1024: 'row' }}
          >
            <Button
              maxW='172px'
              minW={{ base: '100%', breakpoint1024: '0px' }}
              variant='outline'
              fontFamily='Inter'
              fontSize='14px'
              fontWeight='500'
              borderColor='#9C9C9C'
              borderRadius='5px'
              color='#484848'
              px='24px'
              py='11.5px'
              _hover={{ bg: '#EDEDED' }}
              css={{
                ':active, :focus': {
                  background: '#FDFDFD',
                  boxShadow: '0px 0px 15px 0px #1997F380',
                  borderWidth: '2px',
                  borderColor: '#1997F3',
                },
              }}
              onClick={() => {
                setFilter && setFilter(['']);
                setFirstDropdownValue('');
                setSecondDropdownValue('');
                setCheckedItems([false, false, false, false]);
              }}
            >
              Restaurar padrões
            </Button>
            <Button
              maxW='137px'
              minW={{ base: '100%', breakpoint1024: '0px' }}
              variant='solid'
              fontFamily='Inter'
              fontSize='14px'
              fontWeight='500'
              color='#FDFDFD'
              bg='#222222'
              px='24px'
              py='11.5px'
              borderRadius='5px'
              _hover={{ bg: '#484848' }}
              css={{
                ':active, :focus': {
                  background: '#222222',
                  boxShadow: '0px 0px 15px 0px #1997F380',
                  borderWidth: '2px',
                  borderColor: '#1997F3',
                },
              }}
              onClick={() => {
                if (
                  !(
                    firstDropdownValue === '' &&
                    secondDropdownValue === '' &&
                    !checkedItems[0] &&
                    !checkedItems[1] &&
                    !checkedItems[2] &&
                    !checkedItems[3]
                  )
                ) {
                  setFilter &&
                    setFilter([
                      firstDropdownValue,
                      secondDropdownValue,
                      checkedItems[0] ? '1' : '0',
                      checkedItems[1] ? '1' : '0',
                      checkedItems[2] ? '1' : '0',
                      checkedItems[3] ? '1' : '0',
                    ]);
                }
              }}
            >
              Aplicar filtros
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FilterStartups;
