import React from 'react';
import WarningModal from '~/presentation/components/Modals/WarningModal';
import { iStartup } from '~/domain/interfaces/models';
import { Text } from '@chakra-ui/react';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  onCloseDiscard: () => void;
}

const DiscardStartupEdition = ({
  isOpen,
  onClose,
  onCloseDiscard,
}: ownProps): JSX.Element => {
  return (
    <WarningModal
      isOpen={isOpen}
      onClose={onCloseDiscard}
      title='Descartar alterações?'
      question={
        'Há alterações não salvas nos editais vinculados a este mentor. Tem certeza de que quer fechar esta janela e descartá-las?'
      }
      action={() => {
        onClose();
        onCloseDiscard();
      }}
      actionText='Fechar e descartar'
      cancelActionText='Continuar editando'
      long
    />
  );
};

export default DiscardStartupEdition;
