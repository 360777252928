import { CloseIcon } from '@chakra-ui/icons';
import { Box, Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { Typography } from '~/presentation/components/UI/Typography';

type AssociatedNoticesProps = {
  startupId: number;
};

export const AssociatedNotices: React.FC<AssociatedNoticesProps> = ({
  startupId,
}: AssociatedNoticesProps) => {
  const { records: startups } = useSelector((store: iStore) => store.startup);
  const startup = startups.find((startup) => startup.id === startupId);

  return (
    <Stack spacing={3}>
      <Typography variant='h7_Inter_Medium_14px'>Editais associados</Typography>
      <Box display={'flex'} flexWrap={'wrap'} gap={2}>
        {startup?.incubations
          .map((notice) => (
            <Box
              key={notice.id}
              display={'flex'}
              gap={2.5}
              alignItems={'center'}
              justifyContent={'space-between'}
              px={4}
              py={2.5}
              bgColor={'white.1'}
              borderWidth={1}
              borderColor='black.5'
              borderRadius={5}
              w='fit-content'
            >
              <Typography
                variant='b2_Inter_Regular_14px'
                textTransform={'uppercase'}
              >
                {notice.notice_.code}
                {' - '}
                {notice.notice_.title}
              </Typography>
              <button>
                <CloseIcon w={2.5} />
              </button>
            </Box>
          ))}
      </Box>
    </Stack>
  );
};
