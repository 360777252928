import React from 'react';

interface ownProps {
  isHilighted?: boolean;
}

const IconMessage = ({ isHilighted }: ownProps) => {
  return (
    <svg
      width='80'
      height='80'
      viewBox='0 0 80 80'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='40' cy='40' r='40' fill='#E4F6FF' />
      <path
        d='M21.7812 24.7428H21.7242V24.7998V55.1998V55.2568H21.7812H58.9368H58.9938V55.1998V24.7998V24.7428H58.9368H21.7812ZM40.3333 42.7867L40.359 42.7997L40.3847 42.7867L55.502 35.144V51.765H25.216V35.144L40.3333 42.7867ZM40.359 38.8888L25.216 31.2332V28.2346H55.502V31.2332L40.359 38.8888Z'
        fill='#1997F3'
        stroke='#1997F3'
        strokeWidth='0.114'
      />
    </svg>
  );
};

export default IconMessage;
