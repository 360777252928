import { Box } from '@chakra-ui/react';
import React from 'react';
import { Typography } from '../Typography';
import {
  TagStatusProps,
  TagStatusVariant,
  TagStatus as iTagStatus,
} from './types';

type TagStyle = {
  color: string;
  bgColor: string;
  circle?: string;
};

const statusVariants: Record<TagStatusVariant, TagStyle> = {
  filled: {
    color: 'white.1',
    bgColor: 'black.1',
  },
  minimal: {
    color: 'black.1',
    bgColor: 'transparent',
  },
};

const statusColors: Record<iTagStatus, TagStyle> = {
  success: {
    color: 'green.1',
    bgColor: 'green.4',
    circle: 'green.2',
  },
  warning: {
    color: 'yellow.1',
    bgColor: 'yellow.6',
    circle: 'yellow.3',
  },
  error: {
    color: 'red.3',
    bgColor: 'red.8',
    circle: 'red.3',
  },
  default: {
    color: 'black.2',
    bgColor: 'white.5',
    circle: 'black.5',
  },
};

export const TagStatus: React.FC<TagStatusProps> = ({
  status = 'default',
  variant = 'filled',
  children,
  ...props
}) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      px={3}
      py={1.5}
      borderRadius='3px'
      maxHeight='30px'
      bgColor={
        variant === 'minimal' ? 'transparent' : statusColors[status].bgColor
      }
      color={variant === 'minimal' ? 'black.2' : statusColors[status].color}
      {...props}
    >
      {variant === 'minimal' && (
        <Box
          width={1.5}
          height={1.5}
          borderRadius='50%'
          bgColor={statusColors[status].circle}
          mr={1.5}
        />
      )}
      <Typography variant='h7_Inter_Medium_14px'>{children}</Typography>
    </Box>
  );
};
