import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Flex,
  Stack,
  HStack,
  Divider,
} from '@chakra-ui/react';
import { Button } from '~/presentation/components/UI/Button';

interface ConfirmChangesModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmChangesModal = ({
  isOpen,
  onClose,
  onConfirm,
}: ConfirmChangesModalProps): JSX.Element => {
  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCancel} isCentered>
      <ModalOverlay />
      <ModalContent maxW='500px' bg='#F8F8F8'>
        <ModalHeader fontSize='18px' py='3'>
          Confirmar alterações
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize='sm' color='#303950'>
            Você está prestes a desvincular este mentor de um ou mais editais em
            execução. Tem certeza de que quer continuar?
          </Text>
        </ModalBody>
        <ModalFooter pt='20px'>
          <Stack w='100%'>
            <Divider
              orientation='horizontal'
              display='inline-flex'
              width='100%'
              position='relative'
              mb='12px'
            />
            <HStack justifyContent='space-between' w='100%' spacing='4'>
              <Flex
                justifyContent='space-between'
                alignItems='center'
                width='100%'
              >
                <Button
                  w='100px'
                  variant='secondary'
                  onClick={handleCancel}
                  fontWeight='500'
                  size='md'
                >
                  Cancelar
                </Button>
                <Button
                  id='confirm'
                  variant='primary'
                  onClick={onConfirm}
                  fontWeight='500'
                  size='md'
                >
                  Confirmar
                </Button>
              </Flex>
            </HStack>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmChangesModal;
