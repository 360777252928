import React, { useContext, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import { schemaSetPassword } from '~/validators/SetPassword/SetPasswordValidator';
import Form from '~/presentation/components/UI/FormFormik';
import { makeRemoteConfirmUser } from '~/main/factories/usecases/user/ConfirmUserFactory';
import jwtDecode from 'jwt-decode';
import { Button } from '~/presentation/components/UI';
import { Typography } from '~/presentation/components/UI/Typography';
import FormConfirmPassword from '~/presentation/components/Form/FormConfirmPassword/FormConfirmPassword';

interface ownProps {
  isOpen: boolean;
  onClose?: () => void;
  setConfirmUserStep: (step: number) => void;
  token: string;
  setFinalPassword: (password: string) => void;
}

export type PasswordStrengthStatus =
  | 'default'
  | 'veryWeak'
  | 'weak'
  | 'medium'
  | 'strong';

const SetPassword = ({
  isOpen,
  onClose,
  setConfirmUserStep,
  token,
  setFinalPassword,
}: ownProps): JSX.Element => {
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [, setUserID] = React.useState(0);
  const [passwordStrength, setPasswordStrength] =
    React.useState<PasswordStrengthStatus>('default');
  const [confirmPasswordStrength, setConfirmPasswordStrength] =
    React.useState<PasswordStrengthStatus>('default');
  const { initial, validators } = schemaSetPassword;

  useEffect(() => {
    const user: any = jwtDecode(token);
    if (user) {
      setUserID(user?.id);
    }
  }, []);

  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,
    isInitialValid: false,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        await makeRemoteConfirmUser().confirm({
          body: {
            password: values.password,
            token: token,
          },
        });
        setConfirmUserStep(1);
      } catch (error) {
        showConfirmModal('APPLICATION_ERROR');
      } finally {
        setIsLoading(false);
      }
    },
  });

  const history = useHistory();

  const onCloseModal = () => {
    if (onClose) {
      formik.resetForm({ values: initial });
      onClose();
      history.push('/');
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onCloseModal} isCentered={true}>
        <ModalOverlay />
        <ModalContent
          bg='#FDFDFD'
          w={{ base: '100%', md: '400px' }}
          position={{ base: 'absolute', md: 'inherit' }}
          bottom={{ base: '0', md: 'inherit' }}
          top={{ base: '5px', md: 'auto' }}
          margin={{ base: '100px 0px 0px 0px ', md: 'auto' }}
          minHeight={{ base: 'fit-content', md: '290px' }}
          borderTopRadius='10px'
          borderBottomRadius={{ base: '0px', md: '10px' }}
        >
          <ModalHeader
            paddingTop={{ base: '27px', md: '20px' }}
            paddingX={{ base: '20px', md: '20px' }}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography variant='h3_Inter_Bold_18px'>
              Definir uma senha
            </Typography>
          </ModalHeader>
          <ModalBody
            display={'flex'}
            flexDirection={'column'}
            gap={'20px'}
            paddingX={{ base: '20px' }}
            paddingTop={{ base: '0px' }}
            paddingBottom={{ base: '20px' }}
          >
            <Typography color='#484848' variant='b1_Inter_Regular_14px'>
              Use os campos abaixo para definir sua senha.
              <br />
              Com ela, você poderá ter acesso ao sistema.
            </Typography>
            <Form value={formik}>
              <FormConfirmPassword
                formik={formik}
                passwordStrength={passwordStrength}
                setPasswordStrength={setPasswordStrength}
                confirmPasswordStrength={confirmPasswordStrength}
                setConfirmPasswordStrength={setConfirmPasswordStrength}
              />
            </Form>
          </ModalBody>
          <ModalFooter
            padding='20px'
            display={'flex'}
            flexDirection={'column'}
            borderTop={'1px solid #CECECE'}
          >
            <Button
              w={'100%'}
              disabled={
                !formik.isValid ||
                isLoading ||
                (passwordStrength !== 'strong' &&
                  confirmPasswordStrength !== 'strong')
              }
              isLoading={isLoading}
              loadingText='Confirmando...'
              onClick={() => {
                setFinalPassword(formik.values.password);
                setConfirmUserStep(1);
              }}
            >
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SetPassword;
