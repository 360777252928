import React, { useState, useMemo, useEffect, useContext } from 'react';
import * as yup from 'yup';
import {
  Button,
  Box,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Stack,
  Text,
  ModalFooter,
  HStack,
  UnorderedList,
  ListItem,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  FormLabel,
} from '@chakra-ui/react';
import FormRegisterMember from '../../../Form/RegisterMember/FormRegisterMember';
import { schemaRegisterMember } from '~/validators/RegisterMember/RegisterMemberValidator';
import { useFormik } from 'formik';
import TagSelect from './TagSelect';
import { makeRemoteCreateUser } from '~/main/factories/usecases/user/CreateUserFactory';
import Select from '../../../UI/select';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { iStore } from '~/domain/interfaces/models/Store';
import getRoleByTabId from '~/utils/getRoleByTabID';
import { makeReduxListUser } from '~/main/factories/usecases/user/ListUserFactory';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import { Conflict } from '~/domain/errors/Conflict';
import { withAccess } from '~/presentation/hooks/acess';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
}

interface tabRoleProps {
  bg: string;
  borderColor: string;
}

const TabRole = withAccess(({ bg, borderColor }: tabRoleProps) => {
  return (
    <Tab
      w='83px'
      bg='#F9F8FA'
      border='1px solid rgba(91, 100, 107, 0.5)'
      borderRadius='5px'
      fontWeight='500'
      _selected={{
        bg: { bg },
        border: `1px solid ${borderColor}`,
      }}
    >
      Admin
    </Tab>
  );
});

const RegisterUser = ({ isOpen, onClose }: ownProps): JSX.Element => {
  const { initial, validators } = schemaRegisterMember;
  const [selectedTab, setSelectedTab] = useState(0);
  const { records } = useSelector((store: iStore) => store.startup);
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const { user, startups } = useSelector((store: iStore) => store.auth);
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const isLeader = user?.role_.name === 'LEADER';

  const validation = useMemo(() => {
    let validators = schemaRegisterMember.validators;

    if (selectedTab === 2) {
      validators = validators.concat(
        yup.object({
          notice: yup
            .array()
            .required('Campo obrigatório')
            .min(1, 'Campo obrigatório'),
        })
      );
    } else if (selectedTab >= 3) {
      validators = validators.concat(
        yup.object({ startup: yup.string().required('Campo obrigatório') })
      );
    }

    return validators;
  }, [selectedTab]);

  const {
    startups: workspaceStartups,
    selectedStartup: workspaceSelectedStartup,
  } = useSelector((store: iStore) => store.auth);

  const formik = useFormik({
    initialValues: initial,
    validationSchema: validation,
    isInitialValid: false,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      makeRemoteCreateUser()
        .create({
          body: {
            role: getRoleByTabId(selectedTab, isLeader),
            fullname: values.name,
            email: values.email,
            job: values.job,
            workload: values.workload,
            ...(values.startup ? { startup: Number(values.startup) } : {}),
            ...(selectedTags.length > 0
              ? { mentor: { notices: selectedTags, specialty: '' } }
              : {}),
          },
        })
        .then(() => {
          makeReduxListUser().list({ query: { limit: 999 } });
          showConfirmModal('SUBMMIT_USER');
        })
        .catch((error) => {
          if (error instanceof Conflict) {
            showConfirmModal('CONFLICT_ERROR');
          } else {
            showConfirmModal('APPLICATION_ERROR');
          }
        })
        .finally(() => {
          setIsLoading(false);
          onCloseModal();
        });
    },
  });

  const onCloseModal = () => {
    formik.resetForm({ values: initial });
    onClose();
  };

  useEffect(() => {
    formik.setFieldValue('startup', '');
    formik.setTouched({ startup: false });
  }, [selectedTab]);

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} isCentered>
      <ModalOverlay />
      <ModalContent maxW='800px' bg='#F8F8F8'>
        <ModalHeader fontSize='18px' py='3'>
          Pré-cadastrar usuário
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justify='space-between' gap={'32px'}>
            <FormRegisterMember formik={formik} />
            <Box w='50%'>
              <Text fontWeight='bold' fontSize='sm' mb='2'>
                Papel do usuário<span style={{ color: 'red' }}>*</span>
              </Text>
              <Tabs
                variant='unstyled'
                onChange={(index) => setSelectedTab(index)}
              >
                <Flex direction='column' rowGap='12px'>
                  {!isLeader && (
                    <TabList gap={3} h='40px' color='#303950'>
                      <Tab
                        w='83px'
                        bg='#F9F8FA'
                        border='1px solid rgba(91, 100, 107, 0.5)'
                        borderRadius='5px'
                        fontWeight='500'
                        _selected={{
                          bg: '#FFF2EE',
                          border: '1px solid #E74D39',
                        }}
                      >
                        Admin
                      </Tab>
                      <Tab
                        w='113px'
                        bg='#F9F8FA'
                        border='1px solid rgba(91, 100, 107, 0.5)'
                        borderRadius='5px'
                        fontWeight='500'
                        _selected={{
                          bg: '#F3F9E7',
                          border: '1px solid #5B7A17',
                        }}
                      >
                        Supervisor
                      </Tab>
                      <Tab
                        w='89px'
                        bg='#F9F8FA'
                        border='1px solid rgba(91, 100, 107, 0.5)'
                        borderRadius='5px'
                        fontWeight='500'
                        _selected={{
                          bg: '#F6E1FA',
                          border: '1px solid #AC38A8',
                        }}
                      >
                        Mentor
                      </Tab>
                    </TabList>
                  )}
                  <Flex
                    justifyContent='flex-start'
                    flexWrap='wrap'
                    columnGap='12px'
                  >
                    <Tab
                      w='86px'
                      bg='#F9F8FA'
                      border='1px solid rgba(91, 100, 107, 0.5)'
                      borderRadius='5px'
                      fontWeight='500'
                      _selected={{
                        bg: '#E4F0F7',
                        border: '1px solid #1863A8',
                      }}
                    >
                      Gestor
                    </Tab>
                    <Tab
                      w='96px'
                      bg='#F9F8FA'
                      border='1px solid rgba(91, 100, 107, 0.5)'
                      borderRadius='5px'
                      fontWeight='500'
                      _selected={{
                        bg: '#FFF5E0',
                        border: '1px solid #BD5114',
                      }}
                    >
                      Membro
                    </Tab>
                  </Flex>
                </Flex>
                <TabPanels>
                  {!isLeader && (
                    <TabPanel px={0}>
                      <Stack>
                        <Text fontWeight='semibold' fontSize='sm' mb='1'>
                          Administradores podem:
                        </Text>
                        <UnorderedList
                          pl='4'
                          color='#747C86'
                          fontSize='sm'
                          spacing={15}
                        >
                          <ListItem>
                            Pré-cadastrar e excluir startups ou outros usuários;
                          </ListItem>
                          <ListItem>
                            Cadastrar, editar e excluir editais de
                            financiamento;
                          </ListItem>
                          <ListItem>
                            Avaliar inscrições nos editais de financiamento;
                          </ListItem>
                          <ListItem>
                            Consultar documentos cadastrados pela startups;
                          </ListItem>
                          <ListItem>
                            Agendar reuniões, mentorias e entregas;
                          </ListItem>
                          <ListItem>
                            Avaliar informes para prestação de contas;
                          </ListItem>
                        </UnorderedList>
                      </Stack>
                    </TabPanel>
                  )}
                  {!isLeader && (
                    <TabPanel px={0}>
                      <Stack>
                        <Text fontWeight='semibold' fontSize='sm' mb='1'>
                          Supervisores podem:
                        </Text>
                        <UnorderedList
                          pl='4'
                          color='#747C86'
                          fontSize='sm'
                          spacing={15}
                        >
                          <ListItem>
                            Cadastrar, editar e excluir editais de
                            financiamento;
                          </ListItem>
                          <ListItem>
                            Avaliar inscrições nos editais de financiamento;
                          </ListItem>
                          <ListItem>
                            Consultar documentos cadastrados pela startups;
                          </ListItem>
                          <ListItem>
                            Agendar reuniões, mentorias e entregas;
                          </ListItem>
                          <ListItem>
                            Avaliar informes para prestação de contas;
                          </ListItem>
                        </UnorderedList>
                      </Stack>
                    </TabPanel>
                  )}
                  {!isLeader && (
                    <TabPanel px={0}>
                      <TagSelect
                        onChange={(tags: number[]) => {
                          setSelectedTags(tags);
                          formik.setFieldValue('notice', tags, true);
                        }}
                        formik={formik}
                      />
                    </TabPanel>
                  )}
                  <TabPanel px={0}>
                    <Box w='360px'>
                      <FormLabel fontWeight={'700'} fontSize='14px' mb='0px'>
                        Startup associada<span style={{ color: 'red' }}>*</span>
                      </FormLabel>
                      <Select
                        id='startup'
                        name='startup'
                        label=''
                        placeholder='Selecione uma startup'
                        value={formik.values.startup}
                        w='360px'
                        bg='#F9F8FA'
                        onChange={formik.handleChange}
                        formik={formik}
                      >
                        {isLeader
                          ? workspaceStartups && (
                              <option
                                key={workspaceSelectedStartup}
                                value={workspaceSelectedStartup}
                              >
                                {
                                  workspaceStartups?.find(
                                    (startups) =>
                                      startups.id === workspaceSelectedStartup
                                  )?.name
                                }
                              </option>
                            )
                          : records?.map((startup) => (
                              <option key={startup.id} value={startup.id}>
                                {startup.name}
                              </option>
                            ))}
                      </Select>
                    </Box>
                    <Stack>
                      <Text fontWeight='semibold' fontSize='sm' mb='1'>
                        Gestores podem:
                      </Text>
                      <UnorderedList
                        pl='4'
                        color='#747C86'
                        fontSize='sm'
                        spacing={15}
                      >
                        <ListItem>
                          Inscrever-se em editais de financiamento;
                        </ListItem>
                        <ListItem>
                          Enviar documentos de entrega ou da sua startup;
                        </ListItem>
                        <ListItem>
                          Cadastrar informes para prestação de contas;
                        </ListItem>
                      </UnorderedList>
                    </Stack>
                  </TabPanel>
                  <TabPanel px={0}>
                    <Box w='360px'>
                      <FormLabel fontWeight={'700'} fontSize='14px' mb='0px'>
                        Startup associada<span style={{ color: 'red' }}>*</span>
                      </FormLabel>
                      <Select
                        id='startup'
                        name='startup'
                        label=''
                        placeholder='Selecione uma startup'
                        value={formik.values.startup}
                        w='360px'
                        bg='#F9F8FA'
                        onChange={formik.handleChange}
                        formik={formik}
                      >
                        {isLeader ? (
                          <option
                            key={workspaceSelectedStartup}
                            value={workspaceSelectedStartup}
                          >
                            {
                              workspaceStartups?.find(
                                (startups) =>
                                  startups.id === workspaceSelectedStartup
                              )?.name
                            }
                          </option>
                        ) : (
                          records?.map((startup) => (
                            <option key={startup.id} value={startup.id}>
                              {startup.name}
                            </option>
                          ))
                        )}
                      </Select>
                    </Box>
                    <Stack>
                      <Text fontWeight='semibold' fontSize='sm' mb='1'>
                        Membros podem:
                      </Text>
                      <UnorderedList
                        pl='4'
                        color='#747C86'
                        fontSize='sm'
                        spacing={15}
                      >
                        <ListItem>
                          Inscrever-se em editais de financiamento;
                        </ListItem>
                        <ListItem>
                          Enviar documentos de entrega ou da sua startup;
                        </ListItem>
                        <ListItem>
                          Cadastrar informes para prestação de contas;
                        </ListItem>
                      </UnorderedList>
                    </Stack>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Flex>
        </ModalBody>
        <ModalFooter pt='8'>
          <HStack justifyContent='space-between' w='100%' spacing='4'>
            <Button
              w='100px'
              variant='outline'
              color='#60656D'
              borderColor='#CECECE'
              onClick={onCloseModal}
              fontWeight='500'
              fontSize='14px'
            >
              Cancelar
            </Button>
            {
              <Button
                id='submit'
                bg='#303950'
                _hover={{ background: '#788FC9' }}
                _active={{ background: '#303950' }}
                color='white'
                isLoading={isLoading}
                loadingText='Concluindo cadastro'
                fontWeight='500'
                fontSize='14px'
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                Concluir Cadastro
              </Button>
            }
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RegisterUser;
