import {
  Box,
  Button,
  Flex,
  HStack,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { iEvent } from '~/domain/interfaces/models/Event';
import { UpdateEvent } from '~/domain/usecases/event/remote/UpdateEvent';
import { makeReduxListEvent } from '~/main/factories/usecases/event/ListEventFactory';
import { makeRemoteUpdateEvent } from '~/main/factories/usecases/event/UpdateEventFactory';
import TypeEvent from '~/presentation/components/Modals/event/RegisterEvent/TypeEvent';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import { withAccess } from '~/presentation/hooks/acess';
import getTypeEvent from '~/utils/getTypeEvent';
import {
  mainSchema,
  schemaRegisterEvent,
} from '~/validators/RegisterEvent/RegisterEventValidator';
import FormEditEventDetails from '../../../Form/FormEditEventDetails/FormEditEventDetails';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  event?: iEvent['records'][0];
}

const InfoEvent = withAccess(() => {
  return (
    <Stack maxW='322px'>
      <Text fontWeight='semibold' fontSize='sm' mb='1'>
        É importante lembrar que:
      </Text>
      <UnorderedList pl='4' color='#747C86' fontSize='sm' spacing={15}>
        <ListItem>
          Não há como convidar admins, mentores ou supervisores específicos. O
          convite sempre se estenderá a todos;
        </ListItem>
        <ListItem>
          Se a opção “Startups” for selecionada, todos os gestores e membros
          serão convidados;
        </ListItem>
      </UnorderedList>
    </Stack>
  );
});

const EditEvent = ({ isOpen, onClose, event }: ownProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [disableTabs, setDisableTabs] = useState(false);
  const { user } = useSelector((store: iStore) => store.auth);
  const userStartup = useSelector((store: iStore) =>
    store.startup.records.find(
      (el) => el.fullname === store.auth.user?.fullname
    )
  );

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const notices = useSelector((store: iStore) => store.notice.records);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const handleSelect =
    (fieldName: string) => (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedOption = event.target.value;
      formik.setFieldValue(fieldName, selectedOption);
    };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const option = event.target.value;
    const isChecked = event.target.checked;

    const currentGuests = formik.values.guests || [];
    const updatedGuests = isChecked
      ? [...currentGuests, option]
      : currentGuests.filter((o) => o !== option);

    formik.setFieldValue('guests', updatedGuests);
  };

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('...event', event);

    if (event) {
      setDisableTabs(true);

      if (event.type === 'MEETING') {
        setSelectedTab(0);
      } else if (event.type === 'MENTORING') {
        setSelectedTab(1);
      }

      if (event.type === 'DELIVERY') {
        setSelectedTab(2);
      }

      if (event.guests) {
        // eslint-disable-next-line no-console
        console.log('opções selecionadas: ', selectedOptions);
      }

      const time = new Date(event.date);
      const hours = time.getUTCHours();
      const mins = time.getUTCMinutes();
      const adjustedTime = new Date();
      adjustedTime.setUTCHours(hours, mins);

      formik.setValues({
        ...formik.values,
        name: event.name,
        date: new Date(event.date.replace('.000Z', '')),
        time: adjustedTime,
        ...(event.link && { link: event.link }),
        ...(event.description && { description: event.description }),
        ...(event.responsable
          ? { responsable: { value: Number(event.responsable), label: '' } }
          : {}),
        ...(event.notice && {
          notice: { value: Number(event.notice), label: '' },
        }),
        ...(event.step?.id && {
          noticeStep: { value: Number(event.step?.id), label: '' },
        }),
        guests: event.guests || [],
      });
    }
  }, [event, isOpen]);

  const setSchema = useCallback(
    (tab: number) => {
      if (user?.role_.name === 'LEADER' || user?.role_.name === 'MEMBER') {
        return mainSchema.validators;
      } else return schemaRegisterEvent[tab];
    },
    [user]
  );

  const formik = useFormik({
    initialValues: mainSchema.initial,
    validationSchema: setSchema(selectedTab),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      const date = values.date ? new Date(values.date) : new Date();
      const time = values.time ? new Date(values.time) : new Date();
      date.setHours(time.getHours());
      date.setMinutes(time.getMinutes());

      const updateRequestBody: UpdateEvent.Params = {
        id: event?.id || 0,
        body: {
          date: date.toString(),
          name: values.name,
          type: getTypeEvent(selectedTab),
          guests: values.guests,
          ...(event?.startup && { startup: event.startup }),
          ...(values.responsable && {
            responsable: Number(values.responsable),
          }),
          ...(values.link && { link: values.link }),
          ...(values.description && { description: values.description }),
          ...(values.notice && { notice: Number(values.notice) }),
          ...(values.noticeStep && { noticeStep: Number(values.noticeStep) }),
        },
      };

      event &&
        makeRemoteUpdateEvent()
          .update(updateRequestBody)
          .then(() => {
            makeReduxListEvent().list({});
            showConfirmModal('EDIT_EVENT');
          })
          .catch(() => {
            showConfirmModal('APPLICATION_ERROR');
          })
          .finally(() => {
            resetForm({ values: mainSchema.initial });
            setIsLoading(false);
            onClose();
            setSelectedOptions([]);
          });
    },
  });

  return (
    <Box>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          formik.resetForm();
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxW='800px' bg='#F8F8F8'>
          <ModalHeader fontSize='18px' py='3'>
            Editar evento
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex justify='space-between' gap={'32px'}>
              <FormEditEventDetails formik={formik} />
              <TypeEvent
                formik={formik}
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
                setSelectedOptions={setSelectedOptions}
                selectedOptions={selectedOptions}
                disableTabs={disableTabs}
                actions={['ADMIN', 'SUPERVISOR', 'MENTOR']}
                resourcers={['ADMIN', 'SUPERVISOR', 'MENTOR']}
              />
              <InfoEvent
                actions={['LEADER', 'MEMBER']}
                resourcers={['LEADER', 'MEMBER']}
              />
            </Flex>
          </ModalBody>
          <ModalFooter pt='8'>
            <HStack justifyContent='space-between' w='100%' spacing='4'>
              <Button
                w='100px'
                variant='outline'
                color='#60656D'
                borderColor='#CECECE'
                onClick={() => {
                  onClose();
                  formik.resetForm();
                }}
                fontWeight='500'
                fontSize='14px'
              >
                Cancelar
              </Button>
              {
                <Button
                  id='submit'
                  bg='#303950'
                  _hover={{ background: '#788FC9' }}
                  _active={{ background: '#303950' }}
                  color='white'
                  isLoading={isLoading}
                  loadingText=' Salvando alterações'
                  fontWeight='500'
                  fontSize='14px'
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  Salvar alterações
                </Button>
              }
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default EditEvent;
