import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tooltip,
} from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import IconBellNotifications from '~/presentation/base/icons/iconBellNotitications';
import Notification from './Notification';

/* 
const notificationsMock = [
  <Notification
    key={0}
    type='NOTICE_NEW'
    title='Um novo edital foi cadastrado'
    image={IconNotificationNotices}
    message='O edital nº 23/2023-SECTIES-FAPESQ-PB acaba de ser cadastrado no sistema. Serão ofertadas 20 vagas e R$ 150.000,00 por projeto selecionado.'
    createdAt='2024-03-03T10:45:00.000-03:00'
    color='#AC38A8'
    tag='Editais'
  />,

  <Notification
    key={0}
    type='NOTICE_REGISTRATION_FINISHING'
    title='As inscrições estão se encerrando!'
    image={IconNotificationNotices}
    message='O prazo de inscrições para o edital nº 23/2023 - SECTIES-FAPESQ-PB está prestes a acabar. Não perca essa oportunidade!'
    createdAt='2024-03-03T10:45:00.000-03:00'
    color='#DE6D04'
    tag='Inscrições'
    link='inicio'
  />,

  <Notification
    key={1}
    type='APPLICATION_SENDED'
    title='Inscrição realizada com sucesso!'
    image={IconNotificationNotices}
    message='Sua inscrição no edital nº 23/2023 foi realizada com sucesso. Fique atento, pois logo você receberá um alerta com o resultado desta avaliação.'
    createdAt='2023-12-29T20:20:00.000-03:00'
    color='#DE6D04'
    tag='Inscrições'
  />,

  <Notification
    key={1}
    type='APPLICATION_APPROVED'
    title='Resultado da avaliação - Inscrição deferida'
    image={IconNotificationNotices}
    message='Parabéns! Sua startup foi aprovada na seleção para o edital nº 23/2022 - SECTIES/FAPESQ/PB e terá direito a participar das atividades da Incubadora.'
    createdAt='2023-12-29T20:20:00.000-03:00'
    color='#DE6D04'
    tag='Inscrições'
  />,

  <Notification
    key={1}
    type='APPLICATION_REJECTED'
    title='Resultado da avaliação - Inscrição indeferida'
    image={IconNotificationNotices}
    message='Infelizmente, sua startup foi reprovada no processo seletivo do edital nº 23/2022 - SECTIES/FAPESQ/PB, em razão de não atender aos requisitos do edital.'
    createdAt='2023-12-29T20:20:00.000-03:00'
    color='#DE6D04'
    tag='Inscrições'
    link='inicio'
  />,

  <Notification
    key={1}
    type='EVENT_MEETING_NEW'
    title='Você foi convidado para uma reunião'
    image={IconNotificationNotices}
    message='O evento “Reunião de Boas-vindas” acontecerá no dia 05/11/2024, às 14:00.'
    createdAt='2023-12-29T20:20:00.000-03:00'
    color='#AC38A8'
    tag='Eventos'
    link='https://meet.google.com/aaa-aaa-aaa'
  />,

  <Notification
    key={1}
    type='EVENT_MEETING_REMINDER'
    title='Você tem uma reunião agendada para hoje'
    image={IconNotificationNotices}
    message='Lembre-se, o evento “Reunião de Boas-vindas” está agendado para hoje (5), às 14:00.'
    createdAt='2024-03-01T17:00:00.000-03:00'
    color='#AC38A8'
    tag='Eventos'
    link='https://meet.google.com/aaa-aaa-aaa'
  />,

  <Notification
    key={1}
    type='EVENT_MENTORING_NEW'
    title='Você foi convidado para uma mentoria'
    image={IconNotificationNotices}
    message='A mentoria com Rejane Ferreira de Souza acontecerá no dia 05/11/2024, às 14:00.'
    createdAt='2024-03-01T17:00:00.000-03:00'
    color='#AC38A8'
    tag='Eventos'
    link='https://meet.google.com/aaa-aaa-aaa'
  />,

  <Notification
    key={1}
    type='EVENT_MENTORING_REMINDER'
    title='Você tem uma mentoria agendada para hoje'
    image={IconNotificationNotices}
    message='Lembre-se, a mentoria com Rejane Ferreira de Souza está agendada para hoje (5), às 14:00.'
    createdAt='2024-03-01T17:00:00.000-03:00'
    color='#AC38A8'
    tag='Eventos'
    link='https://meet.google.com/aaa-aaa-aaa'
  />,

  <Notification
    key={1}
    type='EVENT_DELIVERY_NEW'
    title='Uma nova entrega foi cadastrada'
    image={IconNotificationNotices}
    message='A entrega “Relatório de Acompanhamento” acaba de ser cadastrada. Envie o material até as 19:00 do dia 05/11/2024 para cumprir a etapa atual do edital nº 23/2023 - SECTIES/FAPESQ/PB.'
    createdAt='2024-03-01T17:00:00.000-03:00'
    color='#AC38A8'
    tag='Eventos'
    link='Inicio'
  />,

  <Notification
    key={1}
    type='EVENT_DELIVERY_REMINDER'
    title='O prazo de uma entrega acaba hoje'
    image={IconNotificationNotices}
    message='Lembre-se! Você deve enviar o material solicitado na entrega “Relatório de Acompanhamento” até as 19:00 do dia de hoje.'
    createdAt='2024-03-01T17:00:00.000-03:00'
    color='#AC38A8'
    tag='Eventos'
    link='Inicio'
  />,

  <Notification
    key={1}
    type=''
    title='Um documento importante foi enviado para você'
    image={IconNotificationNotices}
    message='O mentor Rejane Ferreira de Souza acaba de anexar o arquivo “Orientações.pdf” em sua pasta. Clique no botão abaixo para acessá-lo.'
    createdAt='2024-03-01T17:00:00.000-03:00'
    color='#0873F1'
    tag='Documentos'
    link='Inicio'
  />,

  <Notification
    key={1}
    type='ACCOUNTABILITY_APPROVED'
    title='Resultado da avaliação - Informe aprovado'
    image={IconNotificationNotices}
    message='Parabéns! O informe de despesa referente ao período de junho de 2023 a dezembro de 2023 foi avaliado e aprovado por nossa equipe.'
    createdAt='2024-03-01T17:00:00.000-03:00'
    color='#5B7A17'
    tag='Finanças'
  />,

  <Notification
    key={1}
    type='ACCOUNTABILITY_REJECTED'
    title='Resultado da avaliação - Informe reprovado'
    image={IconNotificationNotices}
    message='O informe de despesa referente ao período de junho de 2023 a dezembro de 2023 foi reprovado. Revise o material enviado e cadastre um novo informe para regularizar sua situação.'
    createdAt='2024-03-01T17:00:00.000-03:00'
    color='#5B7A17'
    tag='Finanças'
    link='inicio'
  />,

  <Notification
    key={1}
    type='APPLICATION_STEP_APPROVED'
    title='Resultado da avaliação - Etapa concluída'
    image={IconNotificationNotices}
    message='Sua startup foi aprovada na etapa de “Ideação” do edital nº 23/2023 - SECTIES/FAPESQ/PB. Com isso, ela passa para a fase de “Elaboração do MVP”.'
    createdAt='2024-03-01T17:00:00.000-03:00'
    color='#0873F1'
    tag='Startups'
  />,

  <Notification
    key={1}
    type='APPLICATION_STEP_REJECTED'
    title='Resultado da avaliação - Etapa não concluída'
    image={IconNotificationNotices}
    message='Sua startup não foi aprovada na etapa de “Ideação” do edital nº 23/2023 - SECTIES/FAPESQ/PB. Faça as alterações solicitadas e regularize sua situação.'
    createdAt='2024-03-01T17:00:00.000-03:00'
    color='#0873F1'
    tag='Startups'
    link='inicio'
  />,

  <Notification
    key={1}
    type='APPLICATION_STEP_CONCLUDED'
    title='Resultado da avaliação - Programa concluído'
    image={IconNotificationNotices}
    message='Parabéns! Sua startup cumpriu todos os requisitos e foi aprovada na última etapa do edital nº 23/2023 - SECTIES/FAPESQ/PB.'
    createdAt='2024-03-01T17:00:00.000-03:00'
    color='#0873F1'
    tag='Startups'
  />,

  <Notification
    key={1}
    type=''
    title='Aviso de desligamento'
    image={IconNotificationNotices}
    message='Atenção! Informamos que a sua Startup foi desligada do edital nº 23/2023 - SECTIES/FAPESQ/PB e não poderá mais receber recursos provenientes dele.'
    createdAt='2024-03-01T17:00:00.000-03:00'
    color='#0873F1'
    tag='Startups'
    link='inicio'
  />,
  <Notification
    key={1}
    type='EVENT_MENTORING_RESPONSIBLE_NEW'
    title='Sua mentoria foi agendada com sucesso'
    image={IconNotificationNotices}
    message='Você tem uma mentoria agendada para {{date}} ás {{hour}}. Ela é referente ao Edital Nº {{code}}-{{title}}.'
    createdAt='2024-03-01T17:00:00.000-03:00'
    color='#AC38A8'
    tag='Eventos'
    link='https://meet.google.com/aaa-aaa-aaa'
  />,
  <Notification
    key={1}
    type='EVENT_MENTORING_RESPONSIBLE_REMINDER'
    title='Você tem uma mentoria agendada para hoje'
    image={IconNotificationNotices}
    message='Lembre-se, há uma mentoria agendada para hoje ({{day}}) às {{hour}}. Ela é referente ao Edital Nº {{code}}-{{title}}.'
    createdAt='2024-03-01T17:00:00.000-03:00'
    color='#AC38A8'
    tag='Eventos'
    link='https://meet.google.com/aaa-aaa-aaa'
  />,
]; */

const NotificationsPanel: React.FC = (): JSX.Element => {
  const { records } = useSelector((store: iStore) => store.notification);
  const notifications = [];

  records.sort((a, b) =>
    a.createdAt > b.createdAt ? 1 : b.createdAt > a.createdAt ? -1 : 0
  );

  for (let i = 0; i < records.length; i++) {
    notifications.unshift(
      <Notification key={records[i].id} item={records[i]} />
    );
  }

  return (
    <Popover placement='bottom-end'>
      <Tooltip
        hasArrow
        label='Notificações'
        bg='#222222'
        p='8.5px 12px'
        borderRadius='5px'
      >
        <Box>
          <PopoverTrigger>
            <Button
              bg='#FDFDFD'
              borderWidth='1px'
              borderColor='#CECECE'
              p='0px'
              w='fit-content'
              aria-label='Notificações'
              _hover={{ bg: '#EDEDED' }}
              css={{
                ':active, :focus': {
                  boxShadow: '0px 0px 15px 0px #1997F380',
                  borderWidth: '2px',
                  borderColor: '#1997F3',
                },
              }}
            >
              <IconBellNotifications />
            </Button>
          </PopoverTrigger>
        </Box>
      </Tooltip>
      <PopoverContent h='480px' bg='white' minW='402px'>
        <PopoverArrow bg='white' />

        <PopoverHeader fontWeight={700}>Notificações</PopoverHeader>

        <PopoverBody
          p='0px'
          overflowY='scroll'
          css={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {notifications}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default NotificationsPanel;
