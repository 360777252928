import { validateCNPJ } from 'validations-br';
import * as yup from 'yup';

export const schemaEditStartup = {
  initial: {
    name: '',
    fullname: '',
    description: '',
    cnpj: '',
    supervisor: 0,
    activity: {
      value: 0,
      label: '',
    },
    address: '',
    city: {
      value: '',
      label: '',
    },
    cep: '',
  },
  validators: yup.object({
    name: yup.string().required('Campo obrigatório'),
    cnpj: yup
      .string()
      .required('Campo obrigatório')
      .matches(
        /^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}-[0-9]{2}$/,
        'CNPJ inválido'
      )
      .test('is-cnpj', 'CNPJ inválido', (cnpj) =>
        cnpj ? validateCNPJ(cnpj) : true
      ),
    activity: yup
      .object()
      .shape({
        value: yup.number().required('Campo obrigatório'),
        label: yup.string().optional(),
      })
      .required('Campo obrigatório'),
    address: yup.string(),
    city: yup
      .object()
      .shape({
        value: yup.string().required('Campo obrigatório'),
        label: yup.string().optional(),
      })
      .required('Campo obrigatório'),
    cep: yup.string().matches(/^(58\d{3})-\d{3}$/, 'CEP inválido'),
  }),
};
