import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Box,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { schemaEditStartup } from '~/validators/EditStartup/EditStartupValidator';
import { iStartup, iStore } from '~/domain/interfaces/models';
import FormEditStartup from '~/presentation/components/Form/FormEditStartup/FormEditStartup';
import { makeRemoteUpdateStartup } from '~/main/factories/usecases/startup/UpdateStartupFactory';
import { makeReduxListStartup } from '~/main/factories/usecases/startup/ListStartupFactory';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { makeApiUrl } from '~/main/factories/http';
import DiscardStartupEdition from './DiscardStartupEdition';
import StringMask from '~/utils/getStringMask';
import Form from '~/presentation/components/UI/FormFormik';
import { Button } from '~/presentation/components/UI/Button';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  startup?: iStartup['records'][0];
}

const EditStartup = ({ isOpen, onClose, startup }: ownProps): JSX.Element => {
  const { initial, validators } = schemaEditStartup;
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const [image, setImage] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenDiscard, setIsOpenDiscard] = useState<boolean>(false);
  const { accessToken } = useSelector((store: iStore) => store.auth);
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    if (startup) {
      const updatedValues = {
        name: startup.name || '',
        fullname: startup.fullname || '',
        description: startup.description || '',
        city: {
          value: startup.address?.city || '',
          label: startup.address?.city || '',
        },
        cnpj: new StringMask('00.000.000/0000-00').apply(startup.cnpj),
        activity: {
          value: startup.activity ?? 0,
          label: startup.activity_.name || 'Unknown',
        },
        supervisor: startup.supervisor ?? 0,
        cep: startup.address?.zipcode || '',
        address: startup.address?.street || '',
      };
      formik.setValues(updatedValues);
    }
  }, [startup, isOpen]);

  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,
    enableReinitialize: true,
    isInitialValid: false,
    onSubmit: async (values) => {
      setIsLoading(true);
      if (startup) {
        try {
          await makeRemoteUpdateStartup().update({
            id: startup.id,
            body: {
              name: values.name,
              fullname: values.fullname,
              description: 'string',
              cnpj: values.cnpj.replace(/[./-]/g, ''),
              activity: Number(values.activity.value),
              supervisor: startup.supervisor,
              address: {
                type: 'APT',
                zipcode: values.cep,
                province: 'PB',
                city: values.city.value,
                country: 'BRA',
                neighborhood: 'string',
                street: values.address,
                number: '0',
                complement: 'string',
              },
            },
          });

          if (image !== null) {
            const formData = new FormData();
            formData.append('file', image);
            await axios.post(
              makeApiUrl(`/startups/${startup.id}/logo`),
              formData,
              {
                headers: { Authorization: `Bearer ${accessToken}` },
              }
            );
          }

          makeReduxListStartup().list({});
          showConfirmModal('EDIT_STARTUP');
        } catch (error) {
          showConfirmModal('APPLICATION_ERROR');
        } finally {
          setIsLoading(false);
          onClose();
        }
      }
    },
  });

  const handleOnCloseModal = useCallback(() => {
    if (formik.dirty) {
      setIsOpenDiscard(true);
    } else {
      onClose();
    }
  }, [formik.dirty, onClose]);

  return (
    <Box>
      <Modal
        isOpen={isOpen}
        onClose={handleOnCloseModal}
        isCentered={true}
        size='full'
        motionPreset='slideInBottom'
      >
        <ModalOverlay />
        <ModalContent
          bg='#FDFDFD'
          maxW={{ base: '100%', md: '720px' }}
          maxH={{ base: '90vh', md: 'auto' }}
          overflowY={{ base: 'auto', md: 'initial' }}
          minH={{ base: 'auto', md: 'auto' }}
          position='fixed'
          bottom={{ base: '0', md: 'auto' }}
          mt={{ base: '5vh', md: 'auto' }}
          borderTopRadius={{ base: '10px', md: '10px' }}
          borderBottomRadius={{ base: 'none', md: '10px' }}
        >
          <ModalHeader>Editar Startup</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box maxH={{ base: '625px', md: 'auto' }}>
              <Form value={formik}>
                <FormEditStartup formik={formik} onChange={setImage} />
              </Form>
            </Box>
          </ModalBody>
          <ModalFooter>
            <HStack justifyContent='space-between' w='100%' spacing='4'>
              <Button variant={'secondary'} onClick={handleOnCloseModal}>
                Cancelar
              </Button>
              <Button
                isLoading={isLoading}
                loadingText='Salvando alterações'
                onClick={() => formik.handleSubmit()}
                variant={'primary'}
              >
                Salvar Alterações
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <DiscardStartupEdition
        isOpen={isOpenDiscard}
        onClose={onClose}
        onCloseDiscard={() => setIsOpenDiscard(false)}
        startup={startup}
      />
    </Box>
  );
};

export default EditStartup;
