/* eslint-disable react/react-in-jsx-scope */
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import enUS from 'date-fns/locale/en-CA';
import es from 'date-fns/locale/es';
import ptBR from 'date-fns/locale/pt';
import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import store from '~/data/store';
import { Routes } from '~/main/routes';
import translations from './infra/i18n/locales';
import GlobalStyle from './presentation/base/styles/globals';
import AccessProvider from './presentation/hooks/acess';

import './App.css';
import { theme } from './presentation/base/themes/theme';
import { ConfirmModalProvider } from './presentation/context/confirmModal';

const persistor = persistStore(store);

export interface iSupportedLanguages {
  language: 'pt-BR' | 'pt-br';
}

const oldTheme = extendTheme({
  colors: {
    white: {
      100: '#CFCFCF',
    },
    'white-1': '#FF00FF',
    'white-2': '#CFCFCF',
    'white-3': '#EFEFEF',
  },
  fonts: {
    heading: 'Poppins',
    body: 'Poppins',
  },
  breakpoints: {
    breakpoint360: '360px',
    breakpoint768: '768px',
    breakpoint1024: '1024px',
    breakpoint1366: '1366px',
    breakpoint1440: '1440px',
    breakpoint1920: '1920px',
  },
});

function App() {
  const [selectedLocale, setSelectedLocale] =
    useState<iSupportedLanguages['language']>('pt-BR');
  const [dateLocale, setDateLocale] = useState(ptBR);

  useEffect(() => {
    let userLanguage = localStorage.getItem('i18nextLng');

    if (!userLanguage) {
      userLanguage = navigator.language;
      localStorage.setItem('i18nextLng', userLanguage);
    }

    setSelectedLocale(userLanguage as iSupportedLanguages['language']);
    if (userLanguage.includes('pt')) setDateLocale(ptBR);
    if (userLanguage.includes('en')) setDateLocale(enUS);
    if (userLanguage.includes('es')) setDateLocale(es);
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <IntlProvider
        messages={translations[selectedLocale]}
        locale={String(selectedLocale)}
        defaultLocale='pt-BR'
      >
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <AccessProvider>
              <ConfirmModalProvider>
                <GlobalStyle />
                <Routes />
              </ConfirmModalProvider>
            </AccessProvider>
          </PersistGate>
        </Provider>
      </IntlProvider>
    </ChakraProvider>
  );
}

export default App;
