import { forwardRef } from 'react';
import { SelectProps } from './types';

import { Box } from '@chakra-ui/react';
import { Typography } from '../Typography';

import { DropdownIndicator } from './dropdownIndicator';
import { Option } from './option';

import ReactSelect, { StylesConfig } from 'react-select';
import { theme } from '../../../base/themes/theme';
import { useField, useFormikContext } from 'formik';

const customStyles: StylesConfig = {
  container(base, props) {
    return {
      ...base,
      flex: 1,
      width: '100%',
      ...(props.isFocused && {
        borderRadius: '4px',
        outlineStyle: 'solid',
        outlineWidth: '2px',
        outlineColor: theme.colors.blue[1],
        boxShadow: theme.shadows.focus,
      }),
      ...(props.isDisabled && {
        borderColor: theme.colors.black[4],
        backgroundColor: theme.colors.white[2],
      }),
    };
  },
  placeholder(base, props) {
    return {
      ...base,
      color: theme.colors.black[4],
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    };
  },
  menu(base, props) {
    return {
      ...base,
      backgroundColor: theme.colors.white[1],
      color: theme.colors.white[5],
      border: '1px solid',
      borderColor: theme.colors.black[5],
      borderRadius: '4px',
      boxShadow: 'none',
    };
  },
  menuPortal: (base, props) => ({
    ...base,
    zIndex: '9999 !important',
  }),
  control(base, props) {
    return {
      ...base,
      width: '100%',
      border: '1px solid',
      backgroundColor: theme.colors.white[1],
      borderColor: `${theme.colors.black[5]} !important`,
      borderRadius: '4px',
      padding: '0',
      minHeight: '40px',
      boxShadow: 'none',
      ...(props.isFocused && {
        outlineStyle: 'solid',
        outlineWidth: '2px',
        outlineColor: theme.colors.blue[5],
      }),
      '&:hover': {
        borderColor: theme.colors.black[4],
        backgroundColor: theme.colors.white[2],
        cursor: 'pointer',
      },
      ...(props.isDisabled && {
        pointerEvents: 'none',
        borderColor: `${theme.colors.black[4]} !important`,
        backgroundColor: theme.colors.white[2],
      }),
    };
  },
};

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ label, name, isRequired, isOptional, ...props }, ref) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(name || '');

    return (
      <Box gap={3} display='flex' flexDirection='column' width='auto'>
        {label && (
          <Box display='flex' alignItems='center'>
            <Typography variant='h6_Inter_SemiBold_14px'>{label}</Typography>
            {isRequired && (
              <Typography variant='h6_Inter_SemiBold_14px' color='#C23422'>
                *
              </Typography>
            )}
            {isOptional && (
              <Typography variant='b2_Inter_Regular_14px'>
                &nbsp;
                {'(opcional)'}
              </Typography>
            )}
          </Box>
        )}
        <ReactSelect
          menuPortalTarget={document.body}
          isClearable={false}
          noOptionsMessage={() => 'Não há opções'}
          isSearchable={false}
          styles={customStyles}
          components={{
            DropdownIndicator,
            IndicatorSeparator: () => null,
            Option,
          }}
          {...props}
          {...field}
          onChange={(val) => {
            setFieldValue(field.name, val);
          }}
        />
      </Box>
    );
  }
);
Select.displayName = 'Select';
