import { Button, ButtonProps, Tooltip } from '@chakra-ui/react';
import { withAccess } from '~/presentation/hooks/acess';

interface ActionButtonProps extends ButtonProps {
  tooltip?: string;
}

const ActionButton = ({
  tooltip,
  padding = '2px',
  children,
  ...rest
}: ActionButtonProps): JSX.Element => {
  return (
    <Tooltip hasArrow label={tooltip} bg={'#222222'}>
      <Button
        w='40px'
        background='#F9F8FA'
        border='1px solid #CECECE'
        _hover={{ background: '#E9ECEE' }}
        padding={padding}
        pointerEvents={rest.disabled ? 'none' : 'auto'}
        {...rest}
      >
        {children}
      </Button>
    </Tooltip>
  );
};

export default withAccess(ActionButton);
