import React, { useContext } from 'react';
import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  Divider,
  useBreakpointValue,
  Box,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import FormRegisterStartup from '~/presentation/components/Form/RegisterStartup/FormRegisterStartup';
import { schemaRegisterStartup } from '~/validators/RegisterStartup/RegisterStartupValidator';
import { makeRemoteCreateStartup } from '~/main/factories/usecases/startup/CreateStartupFactory';
import { makeReduxListStartup } from '~/main/factories/usecases/startup/ListStartupFactory';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import { makeApiUrl } from '~/main/factories/http';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { Conflict } from '~/domain/errors/Conflict';
import { Button } from '~/presentation/components/UI/Button';
import Form from '~/presentation/components/UI/FormFormik';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
}

const RegisterStartup = ({ isOpen, onClose }: ownProps): JSX.Element => {
  const { initial, validators } = schemaRegisterStartup;
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const [image, setImage] = React.useState<File | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { accessToken, user } = useSelector((store: iStore) => store.auth);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,
    isInitialValid: false,
    onSubmit: async (values, { resetForm }) => {
      if (!user) return;

      setIsLoading(true);
      makeRemoteCreateStartup()
        .create({
          body: {
            name: values.name,
            supervisor: user.id, //1
            cnpj: values.cnpj.replace(/[./-]/g, ''),
            fullname: values.nameManager,
            description: 'mock',
            activity: Number(values.activity.value),
            city: values.city.value,
            address: {
              type: 'APT',
              zipcode: values?.cep,
              province: 'PB',
              city: values.city.value,
              country: 'BRA',
              neighborhood: 'Mangabeira',
              street: values?.address,
              number: '120',
              complement: 'CI',
            },
            gender: values.gender.value,
            manager: {
              fullname: values.nameManager,
              email: values.emailManager,
              phone: '83988888888',
              cpf: '62522172042',
              job: 'mock',
              workload: 'mock',
            },
          },
        })
        .then(async ({ id }) => {
          if (image !== null) {
            const formData = new FormData();
            formData.append('file', image);

            await axios.post(makeApiUrl(`/startups/${id}/logo`), formData, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });
          }

          makeReduxListStartup().list({});
          showConfirmModal('SUBMMIT_STARTUP');
        })
        .catch((error) => {
          if (error instanceof Conflict) {
            showConfirmModal('CONFLICT_ERROR');
          } else {
            showConfirmModal('APPLICATION_ERROR');
          }
        })
        .finally(() => {
          onCloseModal();
          setIsLoading(false);
        });
    },
  });

  const onCloseModal = () => {
    formik.resetForm({ values: initial });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} size='xl' isCentered>
      <ModalOverlay />
      <ModalContent
        maxW={{ base: 'fit-content', md: '528px' }}
        minH={{ base: '700px', md: 'auto' }}
        position={{ base: 'fixed', md: 'inherit' }}
        top={{ base: '5px', md: 'auto' }}
      >
        <ModalHeader pb={0}>Nova startup</ModalHeader>
        <ModalCloseButton />
        <ModalBody paddingBottom={'4px'}>
          <Box
            maxH={{ base: '600px', md: 'auto' }}
            overflowY={{ base: 'scroll', md: 'initial' }}
          >
            <Form value={formik}>
              <FormRegisterStartup />
            </Form>
            <Divider
              orientation='horizontal'
              display='inline-flex'
              width={'100%'}
              position='relative'
              marginTop={'0px'}
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent='space-between' w='100%' spacing='4'>
            {!isMobile && (
              <Button
                variant='secondary'
                onClick={() => {
                  formik.resetForm();
                  setIsLoading(false);
                  onClose();
                }}
              >
                Cancelar
              </Button>
            )}
            {
              <Button
                id='submit'
                w={{ base: '100%', md: 'auto' }}
                isLoading={isLoading}
                loadingText='Concluindo cadastro'
                onClick={() => {
                  {
                    formik.handleSubmit();
                  }
                }}
              >
                Concluir pré-cadastro
              </Button>
            }
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RegisterStartup;
