import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Center,
  Divider,
  HStack,
  VStack,
  Stack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Input } from '../../UI/NewInput';
import { Select } from '../../UI/NewSelect';
import cepPromise from 'cep-promise';
import UploadImage from '../../UploadImage';
import { CepResponse } from '../RegisterStartup/FormRegisterStartup';
import cidadesPB from '~/utils/cidadesPB';
import FormItem from '../../UI/FormFormik/formItem';
import { Field } from 'formik';
import { iStore } from '~/domain/interfaces/models';

interface ownProps {
  formik: any;
  onChange?: (files: File) => void;
  oldData?: { name?: string; url?: string };
}

const FormEditStartup = ({
  formik,
  onChange,
  oldData,
}: ownProps): JSX.Element => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { records } = useSelector((store: iStore) => store.activity);
  const [cepResponse, setCepResponse] = useState<CepResponse | undefined>(
    undefined
  );

  const activityOptions = records.map((activity) => ({
    value: activity.id,
    label: activity.name,
  }));

  const cityOptions = cidadesPB.map((city) => ({
    value: city,
    label: city,
  }));

  function isValidCEP(cepInput: string) {
    return /^[0-9]{5}-[0-9]{3}$/.test(cepInput);
  }

  const handleOnBlur = () => {
    async function fetchCEP() {
      try {
        const cepResponseData = await cepPromise(formik.values.cep);
        setCepResponse(cepResponseData);
        formik.setFieldValue(
          'address',
          `${cepResponseData.street}, ${cepResponseData.neighborhood}, ${cepResponseData.city}`
        );
      } catch {
        setCepResponse(undefined);
      }
    }

    if (isValidCEP(formik.values.cep)) {
      fetchCEP();
    } else {
      setCepResponse(undefined);
    }
  };

  const Container = isMobile ? VStack : HStack;

  return (
    <Container
      w={'100%'}
      maxH={isMobile ? '625px' : 'auto'}
      overflowY={isMobile ? 'scroll' : 'initial'}
      padding={isMobile ? '16px' : '0'}
    >
      <Container
        justifyContent='space-between'
        alignItems={isMobile ? 'center' : 'flex-start'}
        gap={'32px'}
      >
        <UploadImage onChange={onChange} oldData={oldData} />
        {!isMobile ? (
          <Center height='260px'>
            <Divider
              orientation={'vertical'}
              borderColor='#CECECE'
            />
          </Center>
        ) : null}
        <Stack gap='5' ml={0}>
          <Container gap='5' alignItems='flex-start'>
            <FormItem name='name'>
              <Field
                as={Input}
                w={{ base: '100%', md: '280px' }} 
                id='name'
                name='name'
                label='Nome da Startup'
                isRequired
              />
            </FormItem>
            <FormItem name='cnpj'>
              <Field
                id='cnpj'
                name='cnpj'
                as={Input}
                w={{ base: '100%', md: '187px' }}
                label='CNPJ'
                mask={'cnpj'}
                placeholder='00.000.000/0001-00'
                isRequired
              />
            </FormItem>
          </Container>
          <Container gap='5' alignItems='flex-start'>
            <Box minW={{ base: '100%', md: '280px' }}>
              <FormItem name='activity'>
                <Field
                  as={Select}
                  id='activity'
                  name='activity'
                  label='Área de atuação'
                  placeholder='Escolha uma opção'
                  options={activityOptions}
                  value={formik.values.activity}
                  isRequired
                />
              </FormItem>
            </Box>
            <Box minW={{ base: '100%', md: '187px' }}>
              <FormItem name='city'>
                <Field
                  as={Select}
                  id='city'
                  name='city'
                  label='Cidade'
                  placeholder='Escolha uma opção'
                  options={cityOptions}
                  value={formik.values.city}
                  isRequired
                />
              </FormItem>
            </Box>
          </Container>
          <Container gap='5' alignItems='flex-start'>
            <FormItem name='address'>
              <Field
                as={Input}
                w={{ base: '100%', md: '280px' }}
                id='address'
                name='address'
                label='Endereço da Startup'
                isOptional
              />
            </FormItem>
            <FormItem name='cep'>
              <Field
                id='cep'
                name='cep'
                as={Input}
                w={{ base: '100%', md: '187px' }}
                label='CEP'
                placeholder='00000-000'
                mask={'cep'}
                isOptional
                onBlurCapture={handleOnBlur}
              />
            </FormItem>
          </Container>
        </Stack>
      </Container>
    </Container>
  );
};

export default FormEditStartup;
