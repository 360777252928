import React from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  SelectProps,
  Select as ChakraSelect,
  Text,
  HStack,
} from '@chakra-ui/react';

interface ownProps extends SelectProps {
  id: string;
  name: string;
  label: string;
  required?: boolean;
  optional?: boolean;
  formik?: any;
}

const Select = ({
  id,
  name,
  label,
  required,
  optional,
  formik,
  children,
  ...rest
}: ownProps): JSX.Element => {
  return (
    <FormControl
      isRequired={required}
      isInvalid={formik?.errors[id] && formik?.touched[name]}
      style={{ marginLeft: '0px' }}
    >
      <FormLabel fontWeight='bold' fontSize='sm' mb='2'>
        {optional ? (
          <HStack spacing={1}>
            <Text>{label}</Text>
            <Text fontSize={'sm'} fontWeight={'400'}>
              (optional)
            </Text>
          </HStack>
        ) : (
          label
        )}
      </FormLabel>
      <ChakraSelect
        id={id}
        name={name}
        onBlur={() => formik?.setFieldTouched(name)}
        {...rest}
      >
        {children}
      </ChakraSelect>
      <FormErrorMessage>{formik?.errors[name]}</FormErrorMessage>
    </FormControl>
  );
};

export default Select;
