import { Box } from '@chakra-ui/react';
import React from 'react';
import { IconDropList } from '../../../base/icons';

export const DropdownIndicator: React.FC<any> = ({ innerProps }) => {
  return (
    <Box p={3} {...innerProps} cursor='pointer'>
      <IconDropList />
    </Box>
  );
};
