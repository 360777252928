import React from 'react';

const IconLinkUser = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.65485 15.0048L7.65494 15.0047L8.2643 14.3953L9.48302 15.614L8.87359 16.2234L8.87356 16.2235C8.40789 16.6899 7.85461 17.0596 7.24554 17.3115C6.63647 17.5633 5.98316 17.6922 5.32407 17.6909H5.32384C4.66493 17.6921 4.01229 17.563 3.4034 17.3112C2.79452 17.0594 2.24141 16.6897 1.77583 16.2235C0.836549 15.2812 0.309122 14.0049 0.309122 12.6744C0.309122 11.3439 0.836647 10.0676 1.7759 9.12522L3.9811 6.92106L3.98113 6.92103C4.92791 5.97325 6.18811 5.45049 7.52953 5.45049C8.87094 5.45049 10.1312 5.97329 11.078 6.92107L11.078 6.9211L11.6873 7.53046L10.4686 8.74918L9.85927 8.13982C8.6125 6.89305 6.44658 6.89306 5.1998 8.13981C5.19979 8.13981 5.19979 8.13981 5.19979 8.13981L2.9943 10.3443L2.99422 10.3443C2.37753 10.963 2.03123 11.8009 2.03123 12.6744C2.03123 13.5479 2.37785 14.3861 2.99454 15.0048L2.9947 15.0049C3.61361 15.6211 4.45137 15.967 5.32468 15.967C6.198 15.967 7.03594 15.6209 7.65485 15.0048ZM10.3435 2.995L10.3434 2.99509L9.73382 3.60464L8.51511 2.38592L9.12431 1.77671C9.12433 1.77669 9.12435 1.77668 9.12436 1.77666C10.0664 0.836887 11.3428 0.309122 12.6734 0.309122C14.0041 0.309122 15.2804 0.836869 16.2225 1.77661C17.1616 2.71893 17.689 3.99511 17.689 5.32554C17.689 6.65609 17.1616 7.93237 16.2222 8.87472L14.0171 11.0788L14.0171 11.0788C13.0703 12.0266 11.81 12.5494 10.4686 12.5494C9.12719 12.5494 7.86699 12.0267 6.92021 11.0789L6.92018 11.0789L6.31078 10.4695L7.5295 9.25077L8.13886 9.86012C9.38563 11.1069 11.5515 11.1069 12.7983 9.86015C12.7983 9.86015 12.7983 9.86015 12.7983 9.86014L15.0037 7.65583L15.0038 7.65575C15.6204 7.03711 15.9669 6.19906 15.9669 5.32554C15.9669 4.45202 15.6206 3.61412 15.0039 2.99547L15.0037 2.99532C14.3848 2.37918 13.5468 2.03294 12.6734 2.03294C11.8001 2.03294 10.9624 2.37886 10.3435 2.995Z'
        fill='#222222'
        stroke='#222222'
        strokeWidth='0.118243'
      />
    </svg>
  );
};

export default IconLinkUser;
