import { Box } from '@chakra-ui/react';
import React from 'react';
import { theme } from '../../../base/themes/theme';

import { CheckIcon } from '@chakra-ui/icons';
import { OptionProps } from 'react-select';

export const Option: React.FC<OptionProps> = ({
  innerProps,
  isFocused,
  isSelected,
  label,
}) => {
  return (
    <Box
      p={3}
      {...innerProps}
      display='flex'
      alignItems='center'
      justifyContent='flex-start'
      color='black.1'
      bg='white.1'
      cursor='pointer'
      fontSize='14px'
      sx={{
        '&:not(:last-child)': {
          borderBottom: '1px solid',
          borderBottomColor: theme.colors.black[5],
        },
        '&:hover': {
          backgroundColor: theme.colors.white[2],
        },
        ...(isFocused && {
          backgroundColor: theme.colors.white[2],
        }),
      }}
    >
      <Box w={2} h={2} mr={3} display='flex' alignItems='center'>
        {isSelected && <CheckIcon />}
      </Box>
      {label}
    </Box>
  );
};
