import React from 'react';

interface ownProps {
  isHilighted?: boolean;
}

const IconErrorExclamation = ({ isHilighted }: ownProps) => {
  return (
    <svg
      width='80'
      height='80'
      viewBox='0 0 80 80'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='40' cy='40' r='40' fill='#FFEFEA' />
      <path
        d='M39.9969 49.5998C40.5409 49.5998 40.9972 49.4155 41.3658 49.0468C41.7345 48.6782 41.9182 48.2225 41.9169 47.6798C41.9156 47.1371 41.7313 46.6814 41.3639 46.3128C40.9966 45.9441 40.5409 45.7598 39.9969 45.7598C39.4529 45.7598 38.9972 45.9441 38.6298 46.3128C38.2625 46.6814 38.0782 47.1371 38.0769 47.6798C38.0756 48.2225 38.2599 48.6788 38.6298 49.0488C38.9998 49.4187 39.4554 49.6024 39.9969 49.5998ZM38.0769 41.9198H41.9169V30.3998H38.0769V41.9198ZM39.9969 59.1998C37.3409 59.1998 34.8449 58.6955 32.5089 57.6868C30.1729 56.6782 28.1409 55.3105 26.4129 53.5838C24.6849 51.8571 23.3172 49.8251 22.3098 47.4878C21.3025 45.1505 20.7982 42.6545 20.7969 39.9998C20.7956 37.3451 21.2999 34.8491 22.3098 32.5118C23.3198 30.1745 24.6874 28.1425 26.4129 26.4158C28.1383 24.6891 30.1703 23.3214 32.5089 22.3128C34.8474 21.3041 37.3434 20.7998 39.9969 20.7998C42.6503 20.7998 45.1463 21.3041 47.4849 22.3128C49.8234 23.3214 51.8554 24.6891 53.5809 26.4158C55.3063 28.1425 56.6746 30.1745 57.6858 32.5118C58.697 34.8491 59.2007 37.3451 59.1969 39.9998C59.193 42.6545 58.6887 45.1505 57.6839 47.4878C56.6791 49.8251 55.3114 51.8571 53.5809 53.5838C51.8503 55.3105 49.8183 56.6788 47.4849 57.6888C45.1514 58.6987 42.6554 59.2024 39.9969 59.1998ZM39.9969 55.3598C44.2849 55.3598 47.9169 53.8718 50.8929 50.8958C53.8689 47.9198 55.3569 44.2878 55.3569 39.9998C55.3569 35.7118 53.8689 32.0798 50.8929 29.1038C47.9169 26.1278 44.2849 24.6398 39.9969 24.6398C35.7089 24.6398 32.0769 26.1278 29.1009 29.1038C26.1249 32.0798 24.6369 35.7118 24.6369 39.9998C24.6369 44.2878 26.1249 47.9198 29.1009 50.8958C32.0769 53.8718 35.7089 55.3598 39.9969 55.3598Z'
        fill='#C23422'
      />
    </svg>
  );
};

export default IconErrorExclamation;
