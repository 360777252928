import * as yup from 'yup';

export const schemaSetPassword = {
  initial: {
    password: '',
    confirm_password: '',
  },
  validators: yup.object({
    password: yup.string().required('Campo obrigatório'),
    confirm_password: yup
      .string()
      .oneOf([yup.ref('password'), null], 'As senhas não coincidem')
      .required('Confirmação de senha é obrigatória'),
  }),
};
