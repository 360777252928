import React, { useCallback, useEffect } from 'react';
import { Box, HStack, Stack, useBreakpointValue } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import cepPromise from 'cep-promise';
import { CepResponse } from '../RegisterStartup/FormRegisterStartup';
import FormItem from '../../UI/FormFormik/formItem';
import { Input } from '../../UI/NewInput';
import { Select } from '../../UI/NewSelect';
import DatePickerField from '../../UI/NewDateInput';
interface iUserConfirmation {
  name: string;
  datepicker: string;
  rg: string;
  address: string;
  email: string;
  role: string;
  gender: string;
  cpf: string;
  cep: string;
  phone: string;
  permission: 'ADMIN' | 'USER' | 'MODERATOR';
  job: string;
  workload: string;
}

const FormUserSignUpFinalization = (): JSX.Element => {
  const [, setCepResponse] = React.useState<CepResponse | undefined>(undefined);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { values, setFieldValue, errors, touched } =
    useFormikContext<iUserConfirmation>();
  const workloadOptions = [
    { value: '20h/semana', label: '20h/semana' },
    { value: '40h/semana', label: '40h/semana' },
  ];
  const genderOptions = [
    { value: 'MALE', label: 'Masculino' },
    { value: 'FEMALE', label: 'Feminino' },
    { value: 'OTHER', label: 'Outro' },
  ];

  function isValidCEP(cepInput: string) {
    return /^[0-9][0-9][0-9][0-9][0-9]-[0-9][0-9][0-9]$/.test(cepInput);
  }

  const handleOnBlur = useCallback(() => {
    async function fetchCEP() {
      try {
        const cepResponseData = await cepPromise(values?.cep);
        setCepResponse(cepResponseData);
        setFieldValue(
          'address',
          `${cepResponseData.street}, ${cepResponseData.neighborhood}, ${cepResponseData.city}`
        );
      } catch {
        setCepResponse(undefined);
      }
    }

    if (isValidCEP(values?.cep)) {
      fetchCEP();
    } else {
      setCepResponse(undefined);
    }
  }, [values?.cep, setFieldValue]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('touched seEffect', touched);
    // eslint-disable-next-line no-console
    console.log('errors seEffect', errors);
  }, [touched, errors]);

  return (
    <>
      {isMobile ? (
        <Stack direction='column' w={'100%'} gap={'20px'}>
          <FormItem name='name'>
            <Field
              id='name'
              name='name'
              as={Input}
              isRequired
              label='Nome completo'
            />
          </FormItem>
          <FormItem name='gender'>
            <Field
              id='gender'
              name='gender'
              as={Select}
              label='Gênero'
              isRequired
              isInvalid={touched.gender && errors.gender}
              options={genderOptions}
              placeholder='Escolha uma opção'
            />
          </FormItem>
          <HStack
            alignItems={'flex-start'}
            gap='12px'
            justify={'space-between'}
          >
            <FormItem name='date'>
              <DatePickerField
                id='date'
                label='Data de nascimento'
                minDate={new Date()}
              />
            </FormItem>
            <Box w={'100%'} />
          </HStack>
          <HStack gap={'12px'} alignItems='flex-start'>
            <FormItem name='rg'>
              <Field
                id='rg'
                name='rg'
                as={Input}
                label='RG'
                isRequired
                mask={'rg'}
                placeholder='__.___.___-_'
              />
            </FormItem>
            <FormItem name='cpf'>
              <Field
                id='cpf'
                name='cpf'
                as={Input}
                label='CPF'
                isRequired
                mask={'cpf'}
                placeholder='___.___.___-__'
              />
            </FormItem>
          </HStack>
          <FormItem name='address'>
            <Field
              id='address'
              name='address'
              as={Input}
              isRequired
              label='Endereço'
            />
          </FormItem>
          <FormItem name='cep'>
            <Field
              id='cep'
              name='cep'
              as={Input}
              label='CEP'
              isRequired
              mask={'cep'}
              placeholder='_____-___'
            />
          </FormItem>
          <FormItem name='email'>
            <Field
              id='email'
              name='email'
              as={Input}
              label='Email'
              isRequired
            />
          </FormItem>
          <FormItem name='phone'>
            <Field
              id='phone'
              name='phone'
              as={Input}
              label='Telefone'
              isOptional
              mask={'phone'}
              placeholder='(__) _____-____'
            />
          </FormItem>
          <FormItem name='job'>
            <Field
              id='job'
              name='job'
              as={Input}
              label='Cargo'
              isOptional
              placeholder='ex: Gerente Administrativo'
            />
          </FormItem>
          <FormItem name='workload'>
            <Field
              id='workload'
              name='workload'
              as={Select}
              label='Dedicação'
              isOptional
              options={workloadOptions}
              placeholder='Escolha uma opção'
            />
          </FormItem>
        </Stack>
      ) : (
        <Stack>
          <HStack gap={'20px'} alignItems='flex-start'>
            <Box w={'100%'}>
              <FormItem name='name'>
                <Field
                  id='name'
                  name='name'
                  as={Input}
                  isRequired
                  isInvalid={touched.name && errors.name}
                  required
                  label='Nome completo'
                />
              </FormItem>
            </Box>
            <Box minW={'180px'}>
              <FormItem name='gender'>
                <Field
                  id='gender'
                  name='gender'
                  as={Select}
                  label='Gênero'
                  isRequired
                  isInvalid={touched.gender && errors.gender}
                  options={genderOptions}
                  placeholder='Escolha uma opção'
                />
              </FormItem>
            </Box>
          </HStack>
          <HStack
            gap={'20px'}
            alignItems='flex-start'
            justifyContent={'space-between'}
          >
            <HStack minW={'400px'} gap={'20px'} alignItems='flex-start'>
              <FormItem name='date'>
                <DatePickerField
                  id='date'
                  label='Data de nascimento'
                  maxDate={new Date()}
                />
              </FormItem>
              <FormItem name='rg'>
                <Field
                  id='rg'
                  name='rg'
                  as={Input}
                  isRequired
                  label='RG'
                  isInvalid={touched.rg && errors.rg}
                  mask={'rg'}
                  placeholder='__.___.___-_'
                />
              </FormItem>
            </HStack>
            <FormItem name='cpf'>
              <Field
                id='cpf'
                name='cpf'
                as={Input}
                isRequired
                label='CPF'
                isInvalid={touched.cpf && errors.cpf}
                mask={'cpf'}
                placeholder='___.___.___-__'
              />
            </FormItem>
          </HStack>
          <HStack alignItems='flex-start' gap={'20px'}>
            <Box w={'100%'}>
              <FormItem name='address'>
                <Field
                  id='address'
                  name='address'
                  as={Input}
                  isRequired
                  isInvalid={touched.address && errors.address}
                  label='Endereço'
                />
              </FormItem>
            </Box>
            <Box minW={'180px'}>
              <FormItem name='cep'>
                <Field
                  id='cep'
                  name='cep'
                  as={Input}
                  isRequired
                  label='CEP'
                  mask={'cep'}
                  placeholder='_____-___'
                  isInvalid={touched.cep && errors.cep}
                  onBlurCapture={handleOnBlur}
                />
              </FormItem>
            </Box>
          </HStack>
          <HStack gap={'20px'} alignItems='flex-start'>
            <Box w={'100%'}>
              <FormItem name='email'>
                <Field
                  id='email'
                  name='email'
                  as={Input}
                  isRequired
                  label='Email'
                  isInvalid={touched.email && errors.email}
                />
              </FormItem>
            </Box>
            <Box minW={'180px'}>
              <FormItem name='phone'>
                <Field
                  id='phone'
                  name='phone'
                  as={Input}
                  label='Telefone'
                  isOptional
                  maxWidth={'180px'}
                  mask={'phone'}
                  placeholder='(__) _____-____'
                />
              </FormItem>
            </Box>
          </HStack>
          <HStack gap={'20px'} alignItems='flex-start'>
            <Box w={'100%'}>
              <FormItem name='job'>
                <Field
                  id='job'
                  name='job'
                  as={Input}
                  label='Cargo'
                  isOptional
                  placeholder='ex: Gerente Administrativo'
                />
              </FormItem>
            </Box>
            <Box minW={'180px'}>
              <FormItem name='workload'>
                <Field
                  id='workload'
                  name='workload'
                  as={Select}
                  label='Dedicação'
                  isOptional
                  options={workloadOptions}
                  placeholder='Escolha uma opção'
                />
              </FormItem>
            </Box>
          </HStack>
        </Stack>
      )}
    </>
  );
};

export default FormUserSignUpFinalization;
