import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { useLocation } from 'react-router';
import Header from '../Header';
import Menu from '../Menu';

interface ownProps {
  children: React.ReactNode;
}

const Layout = ({ children }: ownProps): JSX.Element => {
  const location = useLocation();
  return (
    <Flex w='100%' h='100%' bg='#F8F8F8'>
      {location.pathname !== '/' &&
        location.pathname !== '/finalizar-cadastro' &&
        location.pathname !== '/dashboard' ? (
        <>
          <Menu />
          <Box
            w={{ base: '100%', breakpoint1024: 'calc(100% - 270px)' }}
            bg='#F8F8F8'
          >
            <Header />
            {children}
          </Box>
        </>
      ) : (
        <Box flex='1'>{children}</Box>
      )}
    </Flex>
  );
};

export default Layout;
