import { Box, Link as ChakraLink } from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IconLink } from '../../../base/icons';
import { Typography } from '../Typography';
import { LinkProps } from './types';

export const Link: React.FC<LinkProps> = ({
  label,
  disabled = false,
  ...props
}) => {
  return (
    <ChakraLink
      as={RouterLink}
      aria-disabled={disabled}
      color='blue.2'
      _hover={{
        color: 'blue.4',
        textDecoration: 'underline',
      }}
      _disabled={{
        color: 'black.5',
        cursor: 'default',
        pointerEvents: 'none',
      }}
      gap={2}
      display='flex'
      sx={{
        '&>#link-icon>svg path': {
          fill: 'blue.2',
          color: 'blue.2',
          stroke: 'blue.2',
        },
        '&:hover>#link-icon>svg path': {
          fill: 'blue.4',
          color: 'blue.4',
          stroke: 'blue.4',
        },
        '&[aria-disabled=true]>#link-icon>svg path': {
          fill: 'black.5',
          color: 'black.5',
          stroke: 'black.5',
        },
      }}
      {...props}
    >
      <Box
        id='link-icon'
        display='flex'
        alignItems='center'
        justifyContent='center'
        color='black.1'
        fill='blue.4'
      >
        <IconLink />
      </Box>
      <Typography variant='h7_Inter_Medium_14px'>{label}</Typography>
    </ChakraLink>
  );
};
