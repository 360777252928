import React from 'react';
import WarningModal from '~/presentation/components/Modals/WarningModal';
import { iStartup } from '~/domain/interfaces/models';
import { Text } from '@chakra-ui/react';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  onCloseDiscard: () => void;
  startup?: iStartup['records'][0];
  id?: number;
}

const DiscardStartupEdition = ({
  isOpen,
  onClose,
  onCloseDiscard,
  startup,
}: ownProps): JSX.Element => {
  return (
    <WarningModal
      isOpen={isOpen}
      onClose={onCloseDiscard}
      title='Descartar alterações?'
      question={
        <Text>
          Há alteraçoes não salvas na startup{' '}
          <Text as='span' fontWeight={'medium'}>{startup?.name}</Text>.
          <br />Tem certeza de que quer fechar esta janela e descartá-las?
        </Text>
      }
      action={() => {
        onClose();
        onCloseDiscard();
      }}
      actionText='Fechar e descartar'
      cancelActionText='Continuar editando'
    />
  );
};

export default DiscardStartupEdition;
