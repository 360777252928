/* eslint-disable no-console */
import { Button, Box, Avatar } from '@chakra-ui/react';
import { useState } from 'react';
import * as React from 'react';
import IconCamera from '~/presentation/base/icons/iconCamera';

interface ownProps {
  onChange?: (files: File) => void;
  oldData?: { name?: string; url?: string };
}

const UploadImage = ({ onChange, oldData }: ownProps): JSX.Element => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const handleUpload = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.addEventListener('change', (event: Event) => {
      handleFileSelect(event as unknown as React.ChangeEvent<HTMLInputElement>);
    });
    fileInput.click();
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (!file || !file.type.startsWith('image/')) {
      alert('Please select an image file');
      return;
    }

    onChange && onChange(file);

    const reader = new FileReader();
    reader.onload = handleFileLoad;
    reader.readAsDataURL(file);
    console.log('....reader', reader);
    console.log('....file', file);
  };

  const handleFileLoad = (event: ProgressEvent<FileReader>) => {
    const imageSrc = event.target?.result as string;
    setImageSrc(imageSrc);
  };

  return (
    <Box display='flex' float='left' position='relative'>
      <Box
        borderRadius='full'
        borderWidth='1px'
        borderColor='#CECECE'
        bg='#EFEFEF'
        w='96px'
        h='96px'
        p={5}
        textAlign='center'
        display='flex'
        alignItems='center'
        justifyContent='center'
        padding='0px'
      >
        {!imageSrc && (
          <Avatar size='xl' name={oldData?.name} src={oldData?.url} />
        )}
        {imageSrc && (
          <img
            src={imageSrc}
            alt='Uploaded image'
            style={{ borderRadius: '50%', width: '100%', height: '100%' }}
          />
        )}
        <Box position='absolute'>
          <Button
            width='36px'
            height='36px'
            bg='white'
            borderRadius='full'
            borderColor='#9C9C9C'
            borderWidth='1px'
            padding='0px'
            size='sm'
            position='absolute'
            bottom='-55px'
            right='-50px'
            zIndex='1'
            onClick={handleUpload}
          >
            <IconCamera />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default UploadImage;
