import React from 'react';
import { Stack, HStack } from '@chakra-ui/react';
import { PasswordStrengthStatus } from '~/presentation/components/Modals/user/SetPassword/SetPassword';
import { Typography } from '~/presentation/components/UI/Typography';

interface ownProps {
  strength: PasswordStrengthStatus;
}

interface ColoredBarProps {
  color: string;
  strength: 'veryWeak' | 'weak' | 'medium' | 'strong';
}

type StrengthMap = {
  [key in PasswordStrengthStatus]: {
    text: string;
    color: string;
  };
};

const PasswordStatus = ({ strength }: ownProps): JSX.Element => {
  const strengthMap: StrengthMap = {
    veryWeak: {
      text: 'Muito fraca',
      color: '#D53520',
    },
    weak: {
      text: 'Fraca',
      color: '#BD5114',
    },
    medium: {
      text: 'Moderada',
      color: '#F49404',
    },
    strong: {
      text: 'Forte',
      color: '#8BC314',
    },
    default: {
      text: '',
      color: '',
    },
  };

  const strengthVerifier = (strength: PasswordStrengthStatus): JSX.Element => {
    switch (strength) {
      case 'veryWeak':
        return (
          <Stack>
            <ColoredBar color={'#D53520'} strength={'veryWeak'} />
            <StrengthDisplayer
              color={strengthMap['veryWeak'].color}
              text={strengthMap['veryWeak'].text}
            />
          </Stack>
        );
      case 'weak':
        return (
          <Stack>
            <ColoredBar color={'#BD5114'} strength={'weak'} />
            <StrengthDisplayer
              color={strengthMap['weak'].color}
              text={strengthMap['weak'].text}
            />
          </Stack>
        );
      case 'medium':
        return (
          <Stack>
            <ColoredBar color={'#F49404'} strength={'medium'} />
            <StrengthDisplayer
              color={strengthMap['medium'].color}
              text={strengthMap['medium'].text}
            />
          </Stack>
        );
      case 'strong':
        return (
          <Stack>
            <ColoredBar color={'#8BC314'} strength={'strong'} />
            <StrengthDisplayer
              color={strengthMap['strong'].color}
              text={strengthMap['strong'].text}
            />
          </Stack>
        );
      default:
        return <></>;
    }
  };

  return <Stack mt={'0px'}>{strengthVerifier(strength)}</Stack>;
};

export default PasswordStatus;

const ColoredBar: React.FC<ColoredBarProps> = ({ color, strength }) => {
  const strengthMap: { [key: string]: number } = {
    veryWeak: 1,
    weak: 2,
    medium: 3,
    strong: 4,
  };

  const filledBars = strengthMap[strength];

  return (
    <HStack justifyContent='space-between' spacing={2}>
      {Array.from({ length: 4 }).map((_, index) => (
        <div
          key={index}
          style={{
            width: '100%',
            height: '4px',
            backgroundColor: index < filledBars ? color : 'lightgray',
          }}
        />
      ))}
    </HStack>
  );
};

export const StrengthDisplayer = ({
  color,
  text,
}: {
  color: string;
  text: string;
}): JSX.Element => {
  return (
    <HStack>
      <Typography color='#484848' variant='b2_Inter_Regular_14px'>
        Força da senha:
      </Typography>
      <Typography color={color} variant='h7_Inter_Medium_14px'>
        {text}
      </Typography>
    </HStack>
  );
};
