import React from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Spacer,
  filter,
  useBreakpointValue,
} from '@chakra-ui/react';
import Input from '../UI/Input';
import { IconFilter, IconSearch } from '~/presentation/base/icons';
import { SearchIcon } from '@chakra-ui/icons';
import { useAccess, withAccess } from '~/presentation/hooks/acess';
import ButtonRegister from '../ButtonRegister';
import ButtonDownloadReport from '../ButtonDownloadReport';
import ButtonFilter from '../ButtonFilter';
import ReportGenerator from '../ReportGenerator';
import FilterStartups from '../Modals/Filter/FilterStartups';
import { useLocation } from 'react-router';

interface ownProps {
  active?: string;
  buttonText?: string;
  setActive?: (
    value: 'STARTUPS' | 'INCUBADORA' | 'MENTORIA' | 'ENTREGAS'
  ) => void;
  setOpen?: (value: string) => void;
  tabs?: { title: string; resources?: string[] }[];
  selectFilter?: React.ReactNode;
  filterByName?: string;
  setFilterByName?: (value: string) => void;
  changePath?: (name: string, id: number) => void;
  resourcers?: string[];
  actions?: string[];
  action: () => void;
  children?: React.ReactNode;
  canRegister?: boolean;
  searchPlaceholder?: string;
  showDownloadReportButton?: boolean;
  setFilter?: (value: string[]) => void;
}

const TabItem = withAccess(
  ({
    tab,
    active,
    setActive,
    changePath,
  }: {
    tab: string;
    active?: string;
    setActive?: (
      value: 'STARTUPS' | 'INCUBADORA' | 'MENTORIA' | 'ENTREGAS'
    ) => void;
    changePath?: (name: string, id: number) => void;
  }) => {
    const capitalizedTab =
      tab.charAt(0).toUpperCase() + tab.slice(1).toLowerCase();
    return (
      <Flex
        h='60px'
        key={tab}
        align='center'
        color={active === tab ? '2px solid  #222222' : 'none'}
        textTransform='capitalize'
        fontWeight='bold'
        borderBottom={active === tab ? '3px solid  #222222' : 'none'}
        opacity={active === tab ? '1' : '0.5'}
        onClick={() => {
          // eslint-disable-next-line no-console
          console.log('...setActive', active === tab);
          if (setActive) {
            if (active === tab) changePath && changePath(tab, 0);
            else
              setActive(
                tab as 'STARTUPS' | 'INCUBADORA' | 'MENTORIA' | 'ENTREGAS'
              );
          }
        }}
        cursor='pointer'
      >
        <Box>{capitalizedTab}</Box>
      </Flex>
    );
  }
);

const Tab = ({
  active,
  buttonText,
  setActive,
  setOpen,
  tabs,
  selectFilter,
  filterByName,
  setFilterByName,
  changePath,
  resourcers,
  actions,
  action,
  canRegister = true,
  searchPlaceholder,
  showDownloadReportButton = false,
  setFilter,
}: ownProps): JSX.Element => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const handleOnClose = () => {
    return setIsOpen(false);
  };

  return (
    <Flex
      h={{ base: 'auto', md: '60px' }}
      px='3'
      border='1px solid #cfcfcf'
      borderTopRadius={{ base: '0', md: '10px' }}
      align='center'
      justify='space-between'
      direction={{ base: 'column', md: 'row' }}
      gap={{ base: '10px', md: '0' }}
      p={{ base: '15px' }}
    >
      {!tabs && (
        <>
          <Input
            id='search'
            name='search'
            placeholder={searchPlaceholder}
            w={{ base: '100%', md: '280px' }} // Adjust width based on screen size
            borderColor={'#9C9C9C'}
            leftIcon={
              <SearchIcon color='#60656D' w={'18px'} h={'18px'} mt={'-4px'} />
            }
            onChange={(e) => {
              setFilterByName && setFilterByName(e.target.value);
            }}
          />
          {isMobile && <Divider w={'120%'} />}
          <HStack spacing='3' w={{ base: '100%', md: 'auto' }}>
            {useLocation().pathname === '/startups' ? (
              <FilterStartups
                setFilter={setFilter}
                showDownloadReportButton={showDownloadReportButton}
              />
            ) : (
              <ButtonFilter
                showDownloadReportButton={showDownloadReportButton}
                action={() => {
                  throw new Error('Function not implemented.');
                }}
              />
            )}
            {showDownloadReportButton && (
              <ButtonDownloadReport
                action={() => {
                  setIsOpen(true);
                }}
              />
            )}
            <ButtonRegister
              showDownloadReportButton={showDownloadReportButton}
              actions={actions}
              resourcers={resourcers}
              action={action}
              text={buttonText}
              disabled={!canRegister}
            />
          </HStack>
        </>
      )}
      {tabs && (
        <HStack spacing='8'>
          {tabs.map((tab) => (
            <TabItem
              key={tab.title}
              tab={tab.title}
              active={active}
              setActive={setActive}
              changePath={changePath}
              actions={tab.resources}
              resourcers={tab.resources}
            />
          ))}
        </HStack>
      )}

      {tabs && (
        <HStack spacing='1'>
          <Input
            id='search'
            name='search'
            placeholder={searchPlaceholder}
            w={'280px'}
            h={'36px'}
            borderColor={'#9C9C9C'}
            leftIcon={
              <SearchIcon color='#60656D' w={'18px'} h={'18px'} mt={'-4px'} />
            }
            onChange={(e) => {
              setFilterByName && setFilterByName(e.target.value);
            }}
          />
          {tabs && <Spacer />}
          <HStack spacing='2'>
            <ButtonFilter
              showDownloadReportButton={showDownloadReportButton}
              action={() => {
                throw new Error('Function not implemented.');
              }}
            />
            {showDownloadReportButton && (
              <ButtonDownloadReport
                action={() => {
                  setIsOpen(true);
                }}
              />
            )}
            <ButtonRegister
              showDownloadReportButton={showDownloadReportButton}
              actions={actions}
              resourcers={resourcers}
              action={action}
              text={buttonText}
              disabled={!canRegister}
            />
          </HStack>
        </HStack>
      )}
      <ReportGenerator isOpen={isOpen} onClose={handleOnClose} />
    </Flex>
  );
};

export default Tab;
