import React, { useEffect, useState } from 'react';
import {
  Text,
  Box,
  Flex,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import * as datefns from 'date-fns';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { withAccess } from '~/presentation/hooks/acess';
import { Field } from 'formik';
import { Select } from '~/presentation/components/UI/NewSelect';
import GuestSelect from './GuestSelect';
import FormItem from '~/presentation/components/UI/FormFormik/formItem';

interface ownProps {
  formik: any;
  actions: any;
  resourcers: any;
  setSelectedTab: (index: number) => void;
  setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
  selectedTab: number;
  selectedOptions: string[];
  disableTabs: boolean;
}

const TypeEvent = ({
  formik,
  selectedTab,
  setSelectedTab,
  disableTabs,
}: ownProps): JSX.Element => {
  const { user } = useSelector((store: iStore) => store.auth);
  const mentors = useSelector((store: iStore) =>
    store.user.records.filter((user) => user.role_.name === 'MENTOR')
  );
  const notices = useSelector((store: iStore) => store.notice.records);
  const userNotices = notices.filter(
    (notice) =>
      Array.isArray(notice.mentors) &&
      notice.mentors.some((mentor) => mentor.user.id === user?.id)
  );

  const [disableMentoring, setDisableMentoring] = useState(false);
  const [disableResponsable, setDisableResponsable] = useState(false);
  const [noticeStepOptions, setNoticeStepOptions] = useState<
    { value: number | undefined; label: string }[]
  >([]);

  const handleResponsableChange = (val: { value: any }) => {
    const selectedValue = val ? val.value : '';
    formik.setFieldValue('responsable', Number(selectedValue));
  };

  useEffect(() => {
    if (user?.role_.name === 'MANAGER' || user?.role_.name === 'MEMBER') {
      setDisableMentoring(true);
    } else {
      setDisableMentoring(false);
    }
    if (user?.role_.name === 'MENTOR') {
      setDisableResponsable(true);
      formik.setFieldValue('responsable', {
        value: user?.id,
        label: user?.fullname,
      });
    }
  }, [user?.role_.name]);

  useEffect(() => {
    const selectedNoticeId = formik.values.notice?.value;
    const selectedNotice = notices.find((el) => el.id === selectedNoticeId);
    if (selectedNotice) {
      const options = selectedNotice.steps.map((notice) => ({
        value: notice.id,
        label: notice.name,
      }));
      setNoticeStepOptions(options);
    } else {
      setNoticeStepOptions([]);
    }
  }, [formik.values.notice, notices]);

  const mentorOptions = mentors.map((mentor) => ({
    value: mentor.id,
    label: mentor.fullname,
  }));

  const noticeOptions = userNotices
    .sort((a, b) => b.id - a.id)
    .map((notice) => ({
      value: notice.id,
      label: notice.title,
    }));


  return (
    <Box w='50%'>
      <Text fontWeight='bold' fontSize='sm' mb='2'>
        Tipo de evento<span style={{ color: 'red' }}>*</span>
      </Text>
      <Tabs
        variant='unstyled'
        onChange={(index) => {
          setSelectedTab(index);
          formik.resetForm({
            values: {
              ...formik.values,
              notice: '',
              noticeStep: '',
              guests: [],
              responsable: formik.values.responsable,
            },
            errors: {},
            touched: {},
          });
        }}
        index={selectedTab}
        defaultIndex={user?.role_.name === 'MENTOR' ? 2 : 0}
      >
        <Flex direction='column' rowGap='12px'>
          <TabList gap={3} h='40px' color='#303950'>
            <Tab
              w='94px'
              id='meeting'
              bg='#F9F8FA'
              border='1px solid rgba(91, 100, 107, 0.5)'
              borderRadius='5px'
              fontWeight='500'
              isDisabled={disableTabs}
              _selected={{
                bg: '#E4F0F6',
                border: '1px solid #1997F3',
                color: '#1863A8',
              }}
            >
              Reunião
            </Tab>
            <Tab
              w='100px'
              id='mentoring'
              bg='#F9F8FA'
              border='1px solid rgba(91, 100, 107, 0.5)'
              borderRadius='5px'
              fontWeight='500'
              isDisabled={disableTabs || disableMentoring}
              _selected={{
                bg: '#F6E1FA',
                border: '1px solid #AC38A8',
                color: '#7D2B7B',
              }}
            >
              Mentoria
            </Tab>
            {user?.role_.name === 'MENTOR' && (
              <Tab
                w='92px'
                id='delivery'
                bg='#F9F8FA'
                border='1px solid rgba(91, 100, 107, 0.5)'
                borderRadius='5px'
                fontWeight='500'
                isDisabled={disableTabs}
                _selected={{
                  bg: '#FFF5E0',
                  border: '1px solid #FF9F2F',
                  color: '#BD5114',
                }}
              >
                Entrega
              </Tab>
            )}
          </TabList>
        </Flex>
        <TabPanels>
          <TabPanel px={0}>
            <GuestSelect
              mode='users'
              onChange={(guests) => {
                formik.setFieldValue('guests', guests, true);
              }}
              formik={formik}
            />
          </TabPanel>
          <TabPanel px={0} pt='20px'>
            <Stack spacing='4'>
              <FormItem name='responsable'>
                <Field
                  as={Select}
                  id='responsable'
                  name='responsable'
                  label='Mentor Responsável'
                  placeholder='Selecione'
                  options={mentorOptions}
                  isDisabled={disableResponsable}
                  isRequired
                  onChange={handleResponsableChange}
                />
              </FormItem>
              <GuestSelect
                mode='startups'
                onChange={(guests) => {
                  formik.setFieldValue('guests', guests, true);
                }}
                formik={formik}
              />
            </Stack>
          </TabPanel>
          {user?.role_.name === 'MENTOR' && (
            <TabPanel px={0} pt='20px'>
              <Stack spacing='4'>
                <FormItem name='notice'>
                  <Field
                    as={Select}
                    id='notice'
                    name='notice'
                    label='Edital Associado'
                    placeholder='Selecione'
                    options={noticeOptions}
                    isRequired
                  />
                </FormItem>
                <FormItem name='noticeStep'>
                  <Field
                    as={Select}
                    id='noticeStep'
                    name='noticeStep'
                    label='Etapa Associada'
                    placeholder='Selecione'
                    options={noticeStepOptions}
                    isDisabled={!formik.values.notice}
                    isRequired
                  />
                </FormItem>
              </Stack>
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default withAccess(TypeEvent);
