import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { UpdateApplication as UsecaseRemoteUpdateApplication } from '~/domain/usecases/application/remote';
// import { UpdateApplication as UsecaseReduxUpdateApplication } from '~/domain/usecases/application/redux';

import { RemoteUpdateApplication } from '~/data/repository/application';
// import { ReduxUpdateApplication } from '~/data/store/reducer/application/usecases';

/**
 * send request via API.
 */
export const makeRemoteUpdateApplication = (): UsecaseRemoteUpdateApplication =>
  new RemoteUpdateApplication(
    makeApiUrl('/applications/{application}/'),
    makeHttpClient()
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxUpdateApplication =
  (): UsecaseReduxUpdateApplication =>
    new ReduxUpdateApplication(); */
