import { Stack } from '@chakra-ui/react';
import { Input } from '../../UI/NewInput';
import FormItem from '../../UI/FormFormik/formItem';
import { Field } from 'formik';
import {
  PasswordStatus,
  PasswordStrength,
} from '../../Modals/user/SetPassword';
import { PasswordStrengthStatus } from '../../Modals/user/SetPassword/SetPassword';

interface ownProps {
  formik: any;
  passwordStrength: PasswordStrengthStatus;
  setPasswordStrength: (value: PasswordStrengthStatus) => void;
  confirmPasswordStrength: PasswordStrengthStatus;
  setConfirmPasswordStrength: (value: PasswordStrengthStatus) => void;
}

const FormConfirmPassword = ({
  formik,
  passwordStrength,
  setPasswordStrength,
  confirmPasswordStrength,
  setConfirmPasswordStrength,
}: ownProps): JSX.Element => {
  return (
    <Stack gap={'20px'}>
      <Stack gap={'12px'}>
        <FormItem name='password'>
          <Field
            id='password'
            as={Input}
            isRequired
            label='Senha'
            placeholder='Digite sua senha'
            type='password'
          />
        </FormItem>
        {formik.values.password !== '' ? (
          <PasswordStatus strength={passwordStrength} />
        ) : null}
      </Stack>
      <Stack gap={'12px'} style={{ marginTop: '0px' }}>
        <FormItem name='confirm_password'>
          <Field
            id='confirm_password'
            as={Input}
            isRequired
            label='Confirmar senha'
            placeholder='Digite sua senha'
            type='password'
          />
        </FormItem>
        {formik.values.confirm_password !== '' ? (
          <PasswordStatus strength={confirmPasswordStrength} />
        ) : null}
      </Stack>
      <PasswordStrength
        password={formik.values.password}
        setPasswordStatus={setPasswordStrength}
        confirmPassword={formik.values.confirm_password}
        setConfirmPasswordStatus={setConfirmPasswordStrength}
      />
    </Stack>
  );
};

export default FormConfirmPassword;
