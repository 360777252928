import * as yup from 'yup';
import { validateCPF } from 'validations-br';
import { Gender } from '~/domain/usecases/user/remote';

export const schemaConfirmationUser = {
  initial: {
    name: '',
    datepicker: new Date(),
    rg: '',
    address: '',
    email: '',
    role: '',
    gender: {
      value: '',
      label: '',
    },
    cpf: '',
    cep: '',
    phone: '',
    permission: 'ADMIN',
    job: '',
    workload: {
      value: '',
      label: '',
    },
  },
  validators: yup.object({
    name: yup.string().required('Campo obrigatório'),
    datepicker: yup.date().required('Campo obrigatório'),
    rg: yup.string().required('Campo obrigatório'),
    address: yup.string().required('Campo obrigatório'),
    email: yup.string().required('Campo obrigatório').email('Email inválido'),
    role: yup.string(),
    gender: yup
      .object()
      .shape({
        value: yup.string().required('Campo obrigatório'),
        label: yup.string().optional(),
      })
      .required('Campo obrigatório'),
    cpf: yup
      .string()
      .required('Campo obrigatório')
      .test('is-cpf', 'CPF inválido', (cpf) => validateCPF(cpf!)),
    cep: yup
      .string()
      .required('Campo obrigatório')
      .matches(/(?!^0{5}-0{3}$)(^[0-9]{5}-[0-9]{3}$)/, 'CEP inválido'),
    phone: yup.string(),
    workload: yup.object().shape({
      value: yup.string().optional(),
      label: yup.string(),
    }).nullable(),
    permission: yup.string().required('Campo obrigatório'),
  }),
};
