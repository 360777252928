import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
// import { SetStartupAuth as UsecaseRemoteSetStartupAuth } from '~/domain/usecases/auth/remote';
 import { SetStartupAuth as UsecaseReduxSetStartupAuth } from '~/domain/usecases/auth/redux';

// import { RemoteSetStartupAuth } from '~/data/repository/auth';
 import { ReduxSetStartupAuth } from '~/data/store/reducer/auth/usecases';

/**
 * send request via API.
 */
/* export const makeRemoteSetStartupAuth =
  (): UsecaseRemoteSetStartupAuth =>
    new RemoteSetStartupAuth(
      makeApiUrl(''),
      makeHttpClient()
    ); */

/**
 * send request via REDUX.
 */
export const makeReduxSetStartupAuth =
  (): UsecaseReduxSetStartupAuth =>
    new ReduxSetStartupAuth();
