export const getRoleByName = (roleName: string) => {
  switch (roleName) {
    case 'ADMIN':
      return 'Admin';
    case 'SUPERVISOR':
      return 'Supervisor';
    case 'MENTOR':
      return 'Mentor';
    case 'LEADER':
      return 'Gestor';
    case 'MEMBER':
      return 'Membro';
    default:
      return 'N/A';
  }
};
