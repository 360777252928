import React, { useContext, useEffect } from 'react';
import * as datefns from 'date-fns';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
  useBreakpointValue,
  Stack,
} from '@chakra-ui/react';
import { Button } from '~/presentation/components/UI';
import { Typography } from '~/presentation/components/UI/Typography';
import { useFormik } from 'formik';
import Form from '~/presentation/components/UI/FormFormik';
import { History } from '~/main/routes';
import CameraButton from '../../startup/RegisterStartup/StartupPicture';
import FormUserSignUpFinalization from '~/presentation/components/Form/FormUserSignUpFinalization/FormUserSignUpFinalization';
import { schemaConfirmationUser } from '~/validators/ConfirmationUser/ConfirmationUser';
import { makeRemoteCompleteUserInfo } from '~/main/factories/usecases/user/CompleteUserInfoFactory';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { makeApiUrl } from '~/main/factories/http';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import { Gender, Workload } from '~/domain/usecases/user/remote';
import { makeRemoteConfirmUser } from '~/main/factories/usecases/user/ConfirmUserFactory';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  setConfirmUserStep: (step: number) => void;
  token: string;
  finalPassword: string;
}

const SignUpFinalization = ({
  isOpen,
  onClose,
  setConfirmUserStep,
  token,
  finalPassword,
}: ownProps): JSX.Element => {
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [userID, setUserID] = React.useState(0);
  const [image, setImage] = React.useState<File | null>(null);
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    const user: any = jwtDecode(token);

    if (user) {
      setUserID(user?.id);
      // eslint-disable-next-line no-console
      console.log('...user', user);

      formik.setValues({
        ...formik.values,
        name: user?.fullname,
        email: user?.email,
        workload: user?.workload ?? '20h/semana',
        role: user?.roleId?.toString(),
        cpf: user?.cpf || '',
        phone: user?.phone || '',
      });
    }
  }, []);

  const { initial, validators } = schemaConfirmationUser;

  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,
    isInitialValid: false,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        await makeRemoteConfirmUser().confirm({
          body: {
            password: finalPassword,
            token: token,
          },
        });
      } catch (error) {
        showConfirmModal('APPLICATION_ERROR');
        // eslint-disable-next-line no-console
        console.log('makeRemoteConfirmUser', error);
        onClose();
        setIsLoading(false);
      }
      makeRemoteCompleteUserInfo(userID)
        .complete({
          token: token,
          body: {
            cpf: values.cpf.replace(/[./-]/g, ''),
            phone: values.phone.replace(/\D/g, ''),
            avatar: '',
            rg: values.rg,
            gender: values.gender.value as Gender,
            birthdate: datefns.format(
              new Date(values.datepicker),
              'yyyy-MM-dd'
            ),
            workload: values.workload.value as Workload ?? '20h/semana',
            job: values.job ?? '',
            address: {
              type: 'OFFICE',
              zipcode: values.cep,
              province: '--',
              city: '',
              country: '--',
              neighborhood: 'string',
              street: values.address,
              number: 'string',
              complement: 'string',
            },
          },
        })
        .then(async () => {
          if (image !== null) {
            const formData = new FormData();
            formData.append('file', image);

            await axios.post(makeApiUrl(`/users/${userID}/avatar`), formData, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
          }
          showConfirmModal('SIGN_UP_USER_CONFIRMATION', {
            fullname: values.name,
          });
        })
        .catch((error) => {
          showConfirmModal('APPLICATION_ERROR');
          // eslint-disable-next-line no-console
          console.log('Catch setUSerImage', error)
          onCloseModal();
        })
        .finally(() => {
          onCloseModal();
          setIsLoading(false);
        });
    },
  });

  const onCloseModal = () => {
    formik.resetForm({ values: initial });
    onClose();
    History.push('/');
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onCloseModal} size={'3xl'} isCentered>
        <ModalOverlay />
        <ModalContent
          w={{ base: '100%', md: '3xl' }}
          minHeight={{ base: 'fit-content', md: '290px' }}
          top={{ base: '5px', md: 'auto' }}
          bg={'#FDFDFD'}
          position={{ base: 'absolute', md: 'inherit' }}
          bottom={{ base: '0', md: 'inherit' }}
          margin={{ base: '100px 0px 0px 0px ', md: 'auto' }}
          borderTopRadius={'10px'}
          borderBottomRadius={{ base: '0px', md: '10px' }}
        >
          <ModalHeader
            paddingTop={{ base: '27px', md: '20px' }}
            paddingX={{ base: '20px', md: '20px' }}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography variant='h3_Inter_Bold_18px'>
              Confirmar dados pessoais
            </Typography>
            <ModalCloseButton
              style={{ position: 'static' }}
              onClick={() => {
                onClose();
              }}
            />
          </ModalHeader>
          <ModalBody
            minH={{ base: 'fit-content', md: '416px' }}
            padding={'6.5px 20px 20px 20px'}
            display={'flex'}
            flexDirection={'column'}
            color={'#222222'}
          >
            {isMobile ? (
              <Stack>
                <Form value={formik}>
                  <FormUserSignUpFinalization />
                </Form>
              </Stack>
            ) : (
              <Flex flexDir={'row'} gap={'20px'} marginBottom={'32px'}>
                <CameraButton onChange={setImage}></CameraButton>
                <div
                  style={{
                    border: '1px solid #CECECE',
                    borderRight: '#FDFDFD',
                  }}
                />
                <Form value={formik}>
                  <FormUserSignUpFinalization />
                </Form>
              </Flex>
            )}
          </ModalBody>
          <ModalFooter padding='20px' borderTop={'1px solid #CECECE'}>
            <Flex justify={{ base: 'center', md: 'space-between' }} w='100%'>
              {isMobile ? (
                <>
                  <Button
                    id='submit'
                    w={{ base: '100%', md: 'auto' }}
                    variant='primary'
                    isLoading={isLoading}
                    loadingText='Concluindo cadastro'
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                  >
                    Concluir cadastro
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant='secondary'
                    onClick={() => {
                      onClose();
                      setConfirmUserStep(0);
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    id='submit'
                    variant='primary'
                    w={{ base: '100%', md: 'auto' }}
                    isLoading={isLoading}
                    disabled={!formik.isValid}
                    loadingText='Concluindo cadastro'
                    onClick={() => {
                      formik.handleSubmit();
                      // eslint-disable-next-line no-console
                      console.log('values', formik.values);
                    }}
                  >
                    Concluir cadastro
                  </Button>
                </>
              )}
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SignUpFinalization;
