import { Box, Input as ChakraInput } from '@chakra-ui/react';
import { forwardRef, useState } from 'react';
import MaskedInput from 'react-text-mask';
import getMask from '../../../../utils/getMask';
import { IconView } from '../../../base/icons';
import { IconButton } from '../IconButton';
import { Typography } from '../Typography';
import { InputProps } from './types';

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ label, type, mask, isRequired, isOptional, ...props }, ref) => {
    const isPassword = type === 'password';
    const [inputType, setInputType] = useState(type);

    const handleShowPassword = () => {
      setInputType((prevType) =>
        prevType === 'password' ? 'text' : 'password'
      );
    };

    return (
      <Box gap={3} display='flex' flexDirection='column' width='auto'>
        {label && (
          <Box display='flex' alignItems='center'>
            <Typography variant='h6_Inter_SemiBold_14px'>{label}</Typography>
            {isRequired && (
              <Typography variant='h6_Inter_SemiBold_14px' color='#C23422'>
                *
              </Typography>
            )}
            {isOptional && (
              <Typography variant='b2_Inter_Regular_14px'>
                &nbsp;
                {'(opcional)'}
              </Typography>
            )}
          </Box>
        )}
        <Box display='flex' alignItems='center' justifyContent='center'>
          <ChakraInput
            borderColor='black.5'
            borderRadius='5px'
            color='black.1'
            transition='all 0.2s'
            roundedRight={isPassword ? 0 : 'default'}
            bg='white.1'
            _focus={{
              outlineWidth: '2px',
              outlineColor: 'blue.1',
              outlineOffset: 0,
              boxShadow: 'focus',
            }}
            _hover={{
              borderColor: 'black.4',
              bg: 'white.2',
            }}
            _placeholder={{
              color: 'black.4',
              fontSize: '14px',
            }}
            _invalid={{
              borderColor: 'red.3',
              borderWidth: '2px',
              _hover: {
                borderColor: 'red.3',
              },
              _focus: {
                borderColor: 'red.3',
              },
            }}
            _disabled={{
              pointerEvents: 'none',
              bg: 'black.6',
              borderColor: 'black.4',
              _invalid: {
                borderColor: 'black.2',
                borderWidth: '1px',
              },
            }}
            isInvalid={props.error}
            ref={ref}
            type={inputType}
            flex={1}
            {...props}
            {...(mask && {
              as: MaskedInput,
              mask: getMask(mask),
            })}
          />
          {isPassword && (
            <IconButton
              roundedLeft={0}
              size='lg'
              borderLeftColor='transparent'
              disabled={props.isDisabled}
              _focusVisible={{
                zIndex: 2,
                outline: '2px solid',
                outlineColor: 'blue.1',
                outlineOffset: 0,
                boxShadow: '0 0 15px 0px rgba(25, 151, 243, 0.6)',
              }}
              onClick={(event) => {
                event.preventDefault();
                handleShowPassword();
              }}
            >
              <IconView />
            </IconButton>
          )}
        </Box>
      </Box>
    );
  }
);
Input.displayName = 'Input';
