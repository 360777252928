import React from 'react';

const IconEnter = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.2778 15.1111V10.3889H14.1667V16.0556C14.1667 16.306 14.0672 16.5463 13.89 16.7234C13.7129 16.9005 13.4727 17 13.2222 17H0.944444C0.693962 17 0.453739 16.9005 0.276621 16.7234C0.0995034 16.5463 0 16.306 0 16.0556V3.77778C0 3.5273 0.0995034 3.28707 0.276621 3.10995C0.453739 2.93284 0.693962 2.83333 0.944444 2.83333H6.61111V4.72222H1.88889V15.1111H12.2778Z" fill="#222222" />
      <path d="M15.1111 3.22339L7.75106 10.5844L6.41561 9.24894L13.7747 1.88889H9.44444V0H17V7.55555H15.1111V3.22339Z" fill="#222222" />
    </svg>
  );
};

export default IconEnter;
