/* eslint-disable react/react-in-jsx-scope */
import {
  Box,
  Flex,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import {
  IconHomeButtonAccountability,
  IconHomeButtonDocs,
  IconHomeButtonEvents,
  IconHomeButtonNotice,
  IconHomeButtonRegistrations,
  IconHomeButtonStartups,
  IconHomeButtonUsers,
  IconInstagram,
  IconYoutube,
} from '~/presentation/base/icons';
import IconGov from '~/presentation/base/icons/iconLogoGovPBWhite';
import IconLogoWhite from '~/presentation/base/icons/iconLogoWhite';
import Banner from '~/presentation/base/images/bannerBW.png';
import '~/presentation/base/styles/calendar.css';

import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import IconSite from '~/presentation/base/icons/iconSite';
import HomeButton from '~/presentation/components/HomeButton';
// import { Button } from '~/presentation/components/UI/Button';

// const ButtonEdit = withAccess(() => {
//   return (
//     <Button
//       width='40px'
//       bg='#F9F8FA'
//       color='#1C294B'
//       _hover={{ background: '#1C294B', color: 'white' }}
//       boxShadow='0px 2px 6px rgba(0, 0, 0, 0.1)'
//       padding='11px 11px 10px 11px'
//     >
//       <IconEdit />
//     </Button>
//   );
// });

// const ButtonDelete = withAccess(() => {
//   return (
//     <Button
//       width='40px'
//       bg='#F9F8FA'
//       color='#1C294B'
//       _hover={{ background: '#1C294B', color: 'white' }}
//       boxShadow='0px 2px 6px rgba(0, 0, 0, 0.1)'
//       padding='11px 11px 10px 11px'
//     >
//       <IconDelete />
//     </Button>
//   );
// });

const Home = (): JSX.Element => {
  const { user } = useSelector((store: iStore) => store.auth);

  return (
    <Box h={{ base: '100%', breakpoint768: 'calc(100% - 240px)' }}>
      <Flex
        h='100%'
        pb='137px'
        mx={{ base: '20px', breakpoint768: '42px' }}
        flexDirection='column'
        overflow='scroll'
        css={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Stack spacing='20px'>
          <Stack
            maxW={{ base: '320px', breakpoint768: '532px' }}
            spacing='12px'
          >
            <Text fontWeight={700} fontSize='18px'>
              Olá, {user?.fullname}
            </Text>
            <Text fontSize='14px' color='#484848'>
              {useMediaQuery('(min-width: 768px)')[0]
                ? 'Seja bem-vindo(a) à Incubadora do Parque Tecnológico Horizontes de Inovação. '
                : ''}
              Use os atalhos abaixo para realizar as operações disponíveis para
              o seu perfil.
            </Text>
          </Stack>

          <SimpleGrid
            spacing='12px'
            columns={{
              base: 1,
              breakpoint768: 2,
              breakpoint1366: 3,
              breakpoint1920: 4,
            }}
          >
            <HomeButton
              actions={['SUPERVISOR', 'ADMIN', 'MENTOR']}
              resourcers={['SUPERVISOR', 'ADMIN', 'MENTOR']}
              title={
                user?.role_.name === 'MENTOR'
                  ? 'Painel de startups'
                  : 'Gerenciar Startups'
              }
              description={
                user?.role_.name === 'MENTOR'
                  ? 'Consulte informações das startups cadastradas no sistema.'
                  : 'Cadastre, edite ou remova startups e gerencie etapas de um edital.'
              }
              icon={<IconHomeButtonStartups />}
              to='/startups'
            />
            <HomeButton
              actions={['SUPERVISOR', 'ADMIN']}
              resourcers={['SUPERVISOR', 'ADMIN']}
              title='Gerenciar usuários'
              description='Pré-cadastre, edite, suspenda ou remova usuários.'
              icon={<IconHomeButtonUsers />}
              to='/usuarios'
            />
            <HomeButton
              title='Editais de financiamento'
              description={
                user?.role_.name === 'MENTOR' || user?.role_.name === 'MEMBER'
                  ? 'Consulte os editais que já foram cadastrados no sistema.'
                  : user?.role_.name === 'LEADER'
                  ? 'Consulte editais de financiamento e inscreva sua startup.'
                  : 'Cadastre, edite ou remova etapas e editais de financiamento.'
              }
              icon={
                <IconHomeButtonNotice
                  bgColor={
                    user?.role_.name === 'MENTOR' ? '#EBF3D9' : '#F6E1FA'
                  }
                  color={user?.role_.name === 'MENTOR' ? '#5B7A17' : '#AC38A8'}
                />
              }
              to='/editais'
            />
            <HomeButton
              actions={['LEADER', 'MEMBER']}
              resourcers={['LEADER', 'MEMBER']}
              title='Minha equipe'
              description={
                user?.role_.name === 'LEADER'
                  ? 'Cadastre, edite ou remova usuários associados à sua startup.'
                  : 'Veja quem são os usuários associados à sua startup.'
              }
              icon={<IconHomeButtonUsers />}
              to='/usuarios'
            />
            <HomeButton
              actions={['ADMIN', 'SUPERVISOR', 'LEADER']}
              resourcers={['ADMIN', 'SUPERVISOR', 'LEADER']}
              title={
                user?.role_.name === 'LEADER'
                  ? 'Minhas inscrições'
                  : 'Painel de inscrições'
              }
              description={
                user?.role_.name === 'LEADER'
                  ? 'Consulte o status das suas inscrições em nossos editais.'
                  : 'Avalie inscrições e informe motivos para deferimento ou indeferimento.'
              }
              icon={<IconHomeButtonRegistrations />}
              to='/inscricoes'
            />
            <HomeButton
              title={
                user?.role_.name === 'LEADER' || user?.role_.name === 'MEMBER'
                  ? 'Meus eventos'
                  : 'Painel de eventos'
              }
              description={
                user?.role_.name === 'MENTOR'
                  ? 'Cadastre, edite ou remova eventos como mentorias e entregas.'
                  : user?.role_.name === 'LEADER' ||
                    user?.role_.name === 'MEMBER'
                  ? 'Agende reuniões internas e consulte mentorias e entregas.'
                  : 'Cadastre, edite ou remova reuniões, mentorias e entregas.'
              }
              icon={
                <IconHomeButtonEvents
                  bgColor={
                    user?.role_.name === 'LEADER' ||
                    user?.role_.name === 'MEMBER'
                      ? '#E4F6FF'
                      : '#F6E1FA'
                  }
                  color={
                    user?.role_.name === 'LEADER' ||
                    user?.role_.name === 'MEMBER'
                      ? '#0873F1'
                      : '#AC38A8'
                  }
                />
              }
              to='/eventos'
            />
            <HomeButton
              title={
                user?.role_.name === 'MEMBER'
                  ? 'Meus documentos'
                  : 'Consultar documentos'
              }
              description='Consulte, cadastre, edite ou remova documentos.'
              icon={
                <IconHomeButtonDocs
                  bgColor={
                    user?.role_.name === 'MENTOR' ||
                    user?.role_.name === 'MEMBER'
                      ? '#FFF5E0'
                      : user?.role_.name === 'LEADER'
                      ? '#F6E1FA'
                      : '#E4F6FF'
                  }
                  color={
                    user?.role_.name === 'MENTOR' ||
                    user?.role_.name === 'MEMBER'
                      ? '#DE6D04'
                      : user?.role_.name === 'LEADER'
                      ? '#AC38A8'
                      : '#0873F1'
                  }
                />
              }
              to='/documentos'
            />
            <HomeButton
              actions={['ADMIN', 'SUPERVISOR', 'LEADER']}
              resourcers={['ADMIN', 'SUPERVISOR', 'LEADER']}
              title={
                user?.role_.name === 'LEADER'
                  ? 'Minhas finanças'
                  : 'Painel de finanças'
              }
              description={
                user?.role_.name === 'LEADER'
                  ? 'Cadastre informes de prestação de contas para a sua startup.'
                  : 'Consulte e avalie os informes de prestação de contas das startups.'
              }
              icon={<IconHomeButtonAccountability />}
              to='/orcamento'
            />
          </SimpleGrid>
        </Stack>
      </Flex>
      <Flex
        h='128px'
        backgroundImage={Banner}
        backgroundPosition='center'
        backgroundSize='cover'
        justify='space-between'
        align='flex-start'
        py='22.5px'
        px='48px'
        display={{ base: 'none', breakpoint768: 'flex' }}
      >
        <Stack spacing='20px' minW='fit-content'>
          <HStack>
            <IconLogoWhite small />
            <IconGov />
          </HStack>
          <Text color='#E2E2E2' fontSize='12px' whiteSpace='nowrap'>
            © Parque Tecnológico Horizontes de Inovação. Todos os direitos
            reservados.
          </Text>
        </Stack>
        <HStack spacing='15'>
          <IconSite />
          <IconInstagram />
          <IconYoutube />
        </HStack>
      </Flex>
    </Box>
  );
};

export default Home;

const data = [
  {
    code: '25/2022',
    title: 'SEECT-FAPESQ-PB',
    vacancies: '12',
    value: '1.000,00',
    start: '2022-10-18',
    end: '2022-10-18',
  },
  {
    code: '25/2022',
    title: 'SEECT-FAPESQ-PB',
    vacancies: '12',
    value: '1.000,00',
    start: '2022-10-18',
    end: '2022-10-18',
  },
  {
    code: '25/2022',
    title: 'SEECT-FAPESQ-PB',
    vacancies: '12',
    value: '1.000,00',
    start: '2022-10-18',
    end: '2022-10-18',
  },
  {
    code: '25/2022',
    title: 'SEECT-FAPESQ-PB',
    vacancies: '12',
    value: '1.000,00',
    start: '2022-10-18',
    end: '2022-10-18',
  },
  {
    code: '25/2022',
    title: 'SEECT-FAPESQ-PB',
    vacancies: '12',
    value: '1.000,00',
    start: '2022-10-18',
    end: '2022-10-18',
  },
  {
    code: '25/2022',
    title: 'SEECT-FAPESQ-PB',
    vacancies: '12',
    value: '1.000,00',
    start: '2022-10-18',
    end: '2022-10-18',
  },
  {
    code: '25/2022',
    title: 'SEECT-FAPESQ-PB',
    vacancies: '12',
    value: '1.000,00',
    start: '2022-10-18',
    end: '2022-10-18',
  },
  {
    code: '25/2022',
    title: 'SEECT-FAPESQ-PB',
    vacancies: '12',
    value: '1.000,00',
    start: '2022-10-18',
    end: '2022-10-18',
  },
];
