import { useState, useEffect } from 'react';
import React from 'react';
import { Box, Flex, FormLabel, TabPanel, Text } from '@chakra-ui/react';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import Select from '~/presentation/components/UI/select';

interface Option {
  value: string;
  label: string;
}

const Tags = ({ label, onDelete }: { label: string; onDelete: () => void }) => (
  <Flex
    width='177px'
    display='flex'
    flexDirection='row'
    alignItems='center'
    padding='6px 14px'
    gap='10px'
    background='#E9ECEE'
    border='1px solid rgba(91, 100, 107, 0.5)'
    borderRadius='5px'
    position='relative'
  >
    <Text>{label}</Text>
    <Box
      as='button'
      onClick={onDelete}
      position='absolute'
      right='4px'
      top='4px'
      bg='transparent'
      border='none'
      cursor='pointer'
    >
      <SmallCloseIcon />
    </Box>
  </Flex>
);

interface ownProps {
  onChange: (tags: number[]) => void;
  formik: any;
}

const TagSelect = ({ onChange, formik }: ownProps) => {
  const [selectedOptions, setSelectedOptions] = useState<number[]>([]);

  useEffect(() => {
    onChange(selectedOptions);
  }, [selectedOptions]);

  const { records } = useSelector((store: iStore) => store.notice);
  const options = records.map((record) => ({
    value: record.id,
    label: record.title,
  }));

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.value;
    // eslint-disable-next-line no-console
    console.log(event.target.value);
    setSelectedOptions([...selectedOptions, Number(selectedOption)]);
  };

  const handleDelete = (index: number) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions.splice(index, 1);
    setSelectedOptions(newSelectedOptions);
  };

  const isOdd = selectedOptions.length % 2 !== 0;
  const adjustedOptions = isOdd ? [...selectedOptions] : selectedOptions;

  return (
    <TabPanel px={0}>
      <Box w='360px'>
        <FormLabel fontWeight={'700'} fontSize='14px' mb='0px'>
          Editais associados<span style={{ color: 'red' }}>*</span>
        </FormLabel>
        <Select
          marginBottom={'12px'}
          id='notice'
          name='notice'
          w='360px'
          placeholder='Selecione'
          bg='#F9F8FA'
          onChange={(event) => {
            handleSelect(event);
          }}
          value={formik.values.notice}
          label={''}
          formik={formik}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
        <Flex rowGap={'8px'} flexDirection='column' mt='4px' w={'360px'}>
          {options
            .filter((el, index) =>
              selectedOptions.includes(el.value)
            )
            .map((el, index) => {
              return (
                <Tags
                  key={index}
                  label={el.label}
                  onDelete={() => handleDelete(index)}
                />
              );
            })}
        </Flex>
      </Box>
    </TabPanel>
  );
};

export default TagSelect;
