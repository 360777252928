import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Stack,
  Text,
  ModalFooter,
  HStack,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import FormRegisterEvent from '../../../Form/RegisterEvent/FormRegisterEvent';
import {
  mainSchema,
  schemaRegisterEvent,
} from '~/validators/RegisterEvent/RegisterEventValidator';
import { makeRemoteCreateEvent } from '~/main/factories/usecases/event/CreateEventFactory';
import getTypeEvent from '~/utils/getTypeEvent';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxListEvent } from '~/main/factories/usecases/event/ListEventFactory';
import TypeEvent from './TypeEvent';
import { withAccess } from '~/presentation/hooks/acess';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import { CreateEvent } from '~/domain/usecases/event/remote/CreateEvent';
import Form from '~/presentation/components/UI/FormFormik';
import { Button } from '~/presentation/components/UI/Button';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
}

const RegisterEvent = ({ isOpen, onClose }: ownProps): JSX.Element => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const [disableTabs, setDisableTabs] = useState(false);
  const { user, startups } = useSelector((store: iStore) => store.auth);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('...user', user?.role_.name);
    if (user?.role_.name === 'MENTOR') {
      setSelectedTab(1);
    }
  }, []);

  const setSchema = useCallback(
    (tab: number) => {
      if (user?.role_.name === 'LEADER' || user?.role_.name === 'MEMBER') {
        return mainSchema.validators;
      } else return schemaRegisterEvent[tab];
    },
    [user]
  );

  const formik = useFormik({
    initialValues: mainSchema.initial,
    validationSchema: setSchema(selectedTab),
    validateOnMount: true,
    onSubmit: async (values, { resetForm }) => {
      const isFormValid = await formik.validateForm();
      if (isFormValid) {
        setIsLoading(true);
        const date = new Date(values.date || new Date());
        const startTime = new Date(values.startTime || new Date());
        const endTime = new Date(values.endTime || new Date());
        if (selectedTab === 2) {
          const time = values.time ? new Date(values.time) : new Date();
          date.setHours(time.getHours());
          date.setMinutes(time.getMinutes());
        }
        startTime.setSeconds(0, 0);
        endTime.setSeconds(0, 0);
        let duration = Math.round(
          (endTime.getTime() - startTime.getTime()) / 1000
        );

        if (duration <= 0) {
          duration = 1;
        }

        const requestBody: CreateEvent.Params = {
          body: {
            date: date.toISOString(),
            name: values.name,
            type: getTypeEvent(selectedTab),
            ...(selectedTab === 0 && {
              users: values.guests?.map(Number),
            }),
            ...(selectedTab === 1 && {
              startups: values.guests?.map(Number),
            }),
            ...(selectedTab === 2 && {
              startups: values.guests?.map(Number),
              notice: values.notice ? Number(values.notice.value) : undefined,
              noticeStep: values.noticeStep
                ? Number(values.noticeStep.value)
                : undefined,
            }),
            responsable:
              selectedTab === 1 ? Number(values.responsable) : user?.id,
            duration: selectedTab !== 2 ? duration : undefined,
            link: values.link || undefined,
            description: values.description || undefined,
          },
        };

        user &&
          makeRemoteCreateEvent()
            .create({
              body: requestBody.body,
            })
            .then(() => {
              makeReduxListEvent().list({});

              if (selectedTab === 0) {
                showConfirmModal('SUBMMIT_MEETING_EVENT');
              } else if (selectedTab === 1) {
                showConfirmModal('SUBMMIT_MENTORING_EVENT');
              } else if (selectedTab === 2) {
                showConfirmModal('SUBMMIT_DELIVERY_EVENT');
              }
            })
            .catch(() => {
              showConfirmModal('APPLICATION_ERROR');
            })
            .finally(() => {
              resetForm({ values: mainSchema.initial });
              setSelectedOptions([]);
              setIsLoading(false);
              onClose();
            });
      }
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        formik.resetForm();
      }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent maxW='800px' bg='#F8F8F8'>
        <ModalHeader fontSize='18px' py='3'>
          Novo Evento
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Form value={formik}>
            <Flex justify='space-between' gap='32px'>
              <FormRegisterEvent selectedTab={selectedTab} />
              <TypeEvent
                formik={formik}
                setSelectedTab={setSelectedTab}
                setSelectedOptions={setSelectedOptions}
                selectedTab={selectedTab}
                selectedOptions={selectedOptions}
                disableTabs={disableTabs}
                actions={['ADMIN', 'SUPERVISOR', 'MENTOR']}
                resourcers={['ADMIN', 'SUPERVISOR', 'MENTOR']}
              />
            </Flex>
          </Form>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent='space-between' w='100%' spacing='4'>
            <Button
              variant='secondary'
              onClick={() => {
                formik.resetForm();
                setIsLoading(false);
                onClose();
              }}
            >
              Cancelar
            </Button>
            <Button
              id='submit'
              w={{ base: '100%', md: 'auto' }}
              isLoading={isLoading}
              loadingText='Concluindo cadastro'
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              Agendar evento
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RegisterEvent;
