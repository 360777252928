import { Box, Stack } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import Confirmation from '..';
import { IconMessage } from '~/presentation/base/icons';
import { Typography } from '~/presentation/components/UI/Typography';

const CompleteUserInfoResendEmail = () => {
  const { active } = useContext(ConfirmModalContext);

  return (
    <Confirmation
      isOpen={active === 'RESEND_LINK_MAIL'}
      showTopButton={false}
      onClose={() => {
        // eslint-disable-next-line no-console
        console.log('close');
      }}
      icon={<IconMessage />}
      title='Link de confirmação reenviado!'
      body={
        <Box>
          <Stack spacing='5'>
            <Typography textAlign={'center'} variant='b1_Inter_Regular_14px'>
              O link de confirmação foi reenviado para o seu email. Verifique
              sua caixa de entrada e clique no link para confirmar seu cadastro.
            </Typography>
          </Stack>
        </Box>
      }
      returnPage='/inicio'
    />
  );
};

export default CompleteUserInfoResendEmail;
