import { Dispatch } from 'redux';
import store from '~/data/store';
import { setStartupRequest } from '~/data/store/reducer/auth/actions/setStartup';

import { iActionSetStartup } from '~/domain/interfaces/redux/auth/setStartup';

import { SetStartupAuth } from '~/domain/usecases/auth/redux';

export class ReduxSetStartupAuth implements SetStartupAuth {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  setStartup = (params: SetStartupAuth.Params): iActionSetStartup =>
    this.send(setStartupRequest(params));
}
