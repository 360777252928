import { GetUserConfirmation } from '~/domain/usecases/user/remote';
import {
  NotFound,
  BadRequestError,
  Forbidden,
  UnexpectedError,
  BadGateway,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';

export class RemoteGetUserConfirmation implements GetUserConfirmation {
  private readonly url: string;
  private readonly httpClient: HttpClient<GetUserConfirmation.Model>;

  constructor(url: string, httpClient: HttpClient<GetUserConfirmation.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  get = async (
    params: GetUserConfirmation.Params
  ): Promise<GetUserConfirmation.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}?token=${params.token}`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${params.token}`,
      },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      default:
        throw new UnexpectedError();
    }
  };
}
